<template>
  <div class="inner-body inner">
    <OkModal
      v-if="showError"
      v-bind:message="errorMessage"
      v-bind:header="header"
      v-on:confirm="hideErrorMessage"
    />
    <Spinner v-if="showSpinner" />
    <nav class="inner-nav">
      <ul class="inner-nav-tab">
        <li class="inner-nav-item">
          <a
            class="inner-nav-link"
            v-bind:class="selected_H03_inner1 ? 'active' : ''"
            @click="selectTab('inner-H03-1')"
            href="#"
            style="display: none"
            >#</a
          >
        </li>
      </ul>
    </nav>
    <div class="caption-table">
      <table>
        <tr class="tab-data">
          <th>Объект (название):</th>
          <td>
            <input
              type="text"
              class="form-control form-control-md"
              v-model="component"
              placeholder="Месторождение"
              disabled
            />
          </td>
        </tr>
        <tr class="tab-data">
          <th>Полезное ископаемое:</th>
          <td>
            <input
              type="text"
              class="form-control form-control-md"
              v-model="description"
              placeholder="Полезное ископаемое"
              disabled
            />
          </td>
        </tr>
      </table>
    </div>
    <div class="title">5. ХАРАКТЕРИСТИКА ГЕОЛОГОРАЗВЕДОЧНЫХ РАБОТ</div>
    <form class="form-inline" style="width: calc(100%)">
      <table style="display: table">
        <thead>
          <tr class="tab-data">
            <th style="width: 55%">Наименование показателей</th>
            <th style="width: 35%">Значение показателей</th>
            <th style="width: 10%"></th>
          </tr>
        </thead>
        <tbody>
          <tr class="tab-data">
            <th style="text-align: left; padding: 0 10px">
              01. Система геологоразведочных работ (горная, буровая или
              комбинированная)
            </th>
            <td>
              <div class="drpdwn-container">
                <select
                  class="form-select form-control-md"
                  v-model="selecteGeoWorkTypeId"
                  aria-label="Система геологоразведочных работ"
                >
                  <option
                    v-for="o in geoWorkTypes"
                    v-bind:value="o.id"
                    v-bind:key="o.id"
                  >
                    {{ o.description }}
                  </option>
                </select>
              </div>
            </td>
            <td></td>
          </tr>
          <tr class="tab-data">
            <th style="text-align: left; padding: 0 10px">
              02. Густота разведочной сети, м: <br />
              <span style="padding: 0 10px 0 40px">- по категории А</span>
            </th>
            <td>
              <input
                type="text"
                maxlength="20"
                class="form-control form-control-md"
                v-model="densityKatA"
              />
            </td>
            <td></td>
          </tr>
          <tr class="tab-data">
            <th style="text-align: left; padding: 0 10px 0 50px">
              - по категории В
            </th>
            <td>
              <input
                type="text"
                maxlength="20"
                class="form-control form-control-md"
                v-model="densityKatB"
              />
            </td>
            <td></td>
          </tr>
          <tr class="tab-data">
            <th style="text-align: left; padding: 0 10px 0 50px">
              - по категории С <sub>1</sub>
            </th>
            <td>
              <input
                type="text"
                maxlength="20"
                class="form-control form-control-md"
                v-model="densityKatC1"
              />
            </td>
            <td></td>
          </tr>
          <tr class="tab-data">
            <th style="text-align: left; padding: 0 10px 0 50px">
              - по категории С <sub>2</sub>
            </th>
            <td>
              <input
                type="text"
                maxlength="20"
                class="form-control form-control-md"
                v-model="densityKatC2"
              />
            </td>
            <td></td>
          </tr>
          <tr class="tab-data">
            <th style="text-align: left; padding: 0 10px">
              03. Глубина разведки от поверхности, м:
            </th>
            <td>
              <input
                type="number"
                class="form-control form-control-md"
                v-model="depth"
              />
            </td>
            <td></td>
          </tr>
          <tr class="tab-data">
            <th style="text-align: left; padding: 0 10px">
              04. Основные виды и объемы работ:
            </th>
            <td>
              <!-- input
                type="number"
                class="form-control form-control-md"
                v-model="lat_ang"
              / -->
            </td>
            <td></td>
          </tr>
          <TypeOfWorksControlRecordRow
            v-for="type in type_of_works"
            v-bind:key="type.id"
            v-bind:deposit_id="type.deposit_id"
            v-bind:mine_area_id="type.mine_area_id"
            v-bind:component_id="type.component_id"
            v-bind:type_of_work_id="type.id"
            v-on:type-of-work-updated="onTypeOfWorkUpdated"
            v-on:type-of-work-removed="onTypeOfWorkDeleted"
          />
          <tr class="tab-data">
            <td style="text-align: left; padding: 0 10px">
              <button
                @click="addTypeOfWork"
                class="btn btn-dark btn-md btn-block btn-add"
              >
                <BootstrapIcon icon="plus-square-fill" />
                Добавить
              </button>
            </td>
            <td></td>
            <td></td>
          </tr>
          <!-- tr class="tab-data">
            <th style="text-align: left; padding: 0 10px 0 50px">
              - шурфы, п.м.
            </th>
            <td>
              <input
                type="number"
                class="form-control form-control-md"
                v-model="schurf"
              />
            </td>
          </tr>
          <tr class="tab-data">
            <th style="text-align: left; padding: 0 10px 0 50px">
              - бороздовых, секционных
            </th>
            <td>
              <input
                type="number"
                class="form-control form-control-md"
                v-model="furrows"
              />
            </td>
          </tr -->
          <tr class="tab-data">
            <th style="text-align: left; padding: 0 10px">
              05. Выход керна по полезному ископаемому, %
            </th>
            <td>
              <input
                type="number"
                class="form-control form-control-md"
                v-model="coreOut"
              />
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <button class="btn btn-dark btn-save" v-on:click="saveGeoWorks($event)">
        Сохранить
      </button>
    </form>
  </div>
</template>
<script>
import axios from "axios";

import OkModal from "../../components/OkModal.vue";
//import BootstrapIcon from "@dvuckovic/vue3-bootstrap-icons";
import Spinner from "../../components/Spinner.vue";
import TypeOfWorksControlRecordRow from "../form_components/TypeOfWorksControlRecordRow.vue";

export default {
  name: "FormH05",
  components: {
    //BootstrapIcon,
    OkModal,
    Spinner,
    TypeOfWorksControlRecordRow,
  },
  props: [
    "object_name",
    "mineral_resource",
    "deposit_id",
    "mine_area_id",
    "component_id",
  ],
  data() {
    return {
      type_of_works: [],
      description: "",
      component: "",
      geoWorkTypes: [],
      errorMessage: "",
      header: "",
      showError: false,
      selecteGeoWorkTypeId: null,
      densityKatA: 0,
      densityKatB: 0,
      densityKatC1: 0,
      densityKatC2: 0,
      depth: 0,
      schurf: 0,
      furrows: 0,
      coreOut: 0,
    };
  },
  methods: {
    hideErrorMessage() {
      this.showError = false;
    },
    onTypeOfWorkDeleted() {
      this.getTypesOfWork();
    },
    getTypesOfWork() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const data = {
        deposit_id: this.deposit_id,
        mine_area_id: this.mine_area_id,
        component_id: this.component_id,
      };
      const url = this.$BASE_URL + "/api/get_type_of_works/";
      axios.post(url, data, { headers }).then((response) => {
        if (!response.data[0].auth_fail) {
          this.type_of_works = response.data[0].result;
          this.showSpinner = false;
        }
      });
    },
    addTypeOfWork(e) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const data = {
        deposit_id: this.deposit_id,
        mine_area_id: this.mine_area_id,
        component_id: this.component_id,
      };
      const url = this.$BASE_URL + "/api/add_type_of_work/";
      axios.post(url, data, { headers }).then((response) => {
        if (!response.data[0].auth_fail) {
          this.getTypesOfWork();
        } else {
          this.header = "Ошибка безопасности";
          this.errorMessage = "Доступ запрещен";
          this.showError = true;
        }
      });
      e.preventDefault();
    },
    getGeoWorks() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_geo_works/";
      axios
        .post(
          url,
          {
            deposit_id: this.deposit_id,
            mine_area_id: this.mine_area_id,
            component_id: this.component_id,
          },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.selecteGeoWorkTypeId = response.data[0].result.work_id;
            this.densityKatA = response.data[0].result.density_A;
            this.densityKatB = response.data[0].result.density_B;
            this.densityKatC1 = response.data[0].result.density_C1;
            this.densityKatC2 = response.data[0].result.density_C2;
            this.depth = response.data[0].result.depth;
            this.schurf = response.data[0].result.schurf;
            this.furrows = response.data[0].result.furrows;
            this.coreOut = response.data[0].result.core_out;
            this.getTypesOfWork();
          }
        });
    },
    saveGeoWorks(e) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/add_or_update_geo_works/";
      axios
        .post(
          url,
          {
            deposit_id: this.deposit_id,
            mine_area_id: this.mine_area_id,
            component_id: this.component_id,
            work_id: this.selecteGeoWorkTypeId,
            density_A: this.densityKatA,
            density_B: this.densityKatB,
            density_C1: this.densityKatC1,
            density_C2: this.densityKatC2,
            depth: this.depth,
            schurf: this.schurf,
            furrows: this.furrows,
            core_out: this.coreOut,
          },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.showError = true;
            this.header = "Сохранение данных";
            this.errorMessage = "Данные были сохранены";
          } else {
            this.header = "Ошибка безопасности";
            this.errorMessage = "Доступ запрещен";
            this.showError = true;
          }
        });
      e.preventDefault();
    },
    getGeoWorkType() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_dictionary_contents/";
      axios
        .post(
          url,
          { category: "SPR_SISTEM_GEORAZ", limit: 10000, offset: 0 },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.geoWorkTypes = response.data[0].result;
          }
        });
    },
  },
  mounted() {
    this.description = this.object_name;
    this.component = this.mineral_resource;
    this.showSpinner = true;
    this.getGeoWorkType();
    this.getGeoWorks();
    this.getTypesOfWork();
  },
};
</script>

<style scoped></style>
