<template>
  <div class="body">
    <OkModal
      v-if="showError"
      v-bind:message="errorMessage"
      v-bind:header="header"
      v-on:confirm="hideErrorMessage"
    />
    <Spinner v-if="showSpinner" />
    <!-- inner menu for H02 -->
    <nav class="inner-nav">
      <ul class="inner-nav-tab">
        <li class="inner-nav-item">
          <a
            class="inner-nav-link"
            v-bind:class="selected_H02_inner1 ? 'active' : ''"
            @click="selectTab(1)"
            href="#"
            >Лист 1</a
          >
        </li>
        <li class="inner-nav-item">
          <a
            class="inner-nav-link"
            v-bind:class="selected_H02_inner2 ? 'active' : ''"
            @click="selectTab(2)"
            href="#"
            >Лист 2</a
          >
        </li>
      </ul>
      <!-- inner tabs to show -->
      <div class="tab-H02-inner1 inner" v-if="selected_H02_inner1">
        <div class="inner-body">
          <div class="caption-table">
            <table>
              <tr class="tab-data">
                <th>Объект (название):</th>
                <td>
                  <input
                    type="text"
                    class="form-control form-control-md"
                    v-model="component"
                    placeholder="Месторождение"
                    disabled
                  />
                </td>
              </tr>
              <tr class="tab-data">
                <th>Полезное ископаемое:</th>
                <td>
                  <input
                    type="text"
                    class="form-control form-control-md"
                    v-model="description"
                    placeholder="Полезное ископаемое"
                    disabled
                  />
                </td>
              </tr>
            </table>
          </div>
          <div class="title" style="margin-bottom: 14px">
            2. ГЕОЛОГИЧЕСКАЯ ХАРАКТЕРИСТИКА МЕСТОРОЖДЕНИЯ
          </div>
          <form class="form-inline" style="width: calc(100%)">
            <div
              style="
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                gap: 10px;
              "
            >
              <div style="width: 40%">
                <div
                  style="
                    display: flex;
                    align-items: center;
                    margin-bottom: 8px;
                    width: 100%;
                  "
                >
                  <label
                    style="
                      width: 80%;
                      justify-content: flex-start;
                      margin-bottom: 0;
                      font-weight: bold;
                    "
                    >01. Группа сложности геологического строения м-ния:</label
                  >
                  <div class="drpdwn-container" style="width: 20%;">
                    <select
                      class="form-select form-control-md"
                      v-model="group_id"
                      aria-label=""
                    >
                      <option
                        v-for="o in groups"
                        v-bind:value="o.id"
                        v-bind:key="o.id"
                      >
                        {{ o.description }}
                      </option>
                    </select>
                  </div>
                </div>
                <div
                  style="
                    display: flex;
                    align-items: center;
                    margin-bottom: 8px;
                    width: 100%;
                    gap: 8px;
                  "
                >
                  <label
                    style="
                      width: 10%;
                      justify-content: flex-start;
                      margin-bottom: 0;
                      margin-top: 0;
                    "
                    >Тип</label
                  >
                  <div class="drpdwn-container" style="width: 90%">
                    <select
                      class="form-select form-control-md"
                      v-model="complexity_type_id"
                      aria-label="Тип сложности"
                    >
                      <option
                        v-for="o in complexities"
                        v-bind:value="o.id"
                        v-bind:key="o.id"
                      >
                        {{ o.description }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  margin-bottom: 8px;
                  width: 60%;
                "
              >
                <label
                  style="
                    width: 100%;
                    justify-content: flex-start;
                    margin-bottom: 0;
                    font-weight: bold;
                  "
                  >010. Геологический возраст полез. ископаемого</label
                >
                <div class="drpdwn-container" style="width: 100%">
                  <input
                    type="text"
                    style="width: 100%; font-weight: bold;"
                    class="form-control form-control-md"
                    v-model="geological_age"
                  />
                </div>
              </div>
            </div>

            <div
              style="
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-bottom: 8px;
                width: 100%;
              "
            >
              <label
                style="
                  width: 100%;
                  justify-content: flex-start;
                  margin-bottom: 0;
                  font-weight: bold;
                "
                >02. Породы, слагающие полезное ископаемое</label
              >
              <div class="drpdwn-container" style="width: 100%;">
                <input
                  type="text"
                  style="width: 100%"
                  class="form-control form-control-md"
                  v-model="rocks_mineral"
                />
              </div>
            </div>

            <div
              style="
                display: flex;
                align-items: center;
                margin-bottom: 8px;
                width: 100%;
              "
            >
              <label
                style="
                  width: 20%;
                  justify-content: flex-start;
                  margin-bottom: 0;
                  margin-top: 0;
                  font-weight: bold;
                "
                >03. Форма тел</label
              >
              <div class="drpdwn-container" style="width: 80%">
                <select
                  class="form-select form-control-md"
                  v-model="body_form_id"
                  aria-label="Форма тел"
                >
                  <option
                    v-for="o in bodies"
                    v-bind:value="o.id"
                    v-bind:key="o.id"
                  >
                    {{ o.description }}
                  </option>
                </select>
              </div>
            </div>

            <div
              style="
                display: flex;
                align-items: center;
                margin-bottom: 8px;
                width: 100%;
              "
            >
              <label
                style="
                  width: 20%;
                  justify-content: flex-start;
                  margin-bottom: 0;
                  margin-top: 0;
                  font-weight: bold;
                "
                >04. Кол-во тел</label
              >
              <input
                type="number"
                style="width: 80%"
                class="form-control form-control-md"
                v-model="number_of_bodies"
              />
            </div>

            <div
              style="
                display: flex;
                align-items: flex-start;
                gap: 10px;
                width: 100%;
              "
            >
              <div style="width: 35%;">
                <label style="justify-content: flex-start; font-weight: bold;"
                  >05. Размеры тел:</label
                >
                <label style="justify-content: flex-end; padding: 0 10px; font-style: italic;"
                  >- длина по простиранию, м</label
                >
                <label style="justify-content: flex-end; padding: 0 10px; font-style: italic;"
                  >- длина по падению, м</label
                >
                <label style="justify-content: flex-start">Мощность, м:</label>
                <label style="justify-content: flex-end; padding: 0 10px; font-style: italic;"
                  >- общая</label
                >
                <label style="justify-content: flex-end; padding: 0 10px; font-style: italic;"
                  >- вскрытая</label
                >
                <label style="justify-content: flex-end; padding: 0 10px; font-style: italic;"
                  >- вошедшая в подсчет запасов</label
                >
                <label style="justify-content: flex-end; padding: 0 10px; font-style: italic;"
                  >- отдельных слоев</label
                >
                <label style="justify-content: flex-start; font-weight: bold;"
                  >06. Условия залегания:</label
                >
                <label style="justify-content: flex-end; padding: 0 10px; font-style: italic;"
                  >- направление простирания, румб</label
                >
                <label style="justify-content: flex-end; padding: 0 10px; font-style: italic;"
                  >- азимут падения, град.</label
                >
                <label style="justify-content: flex-end; padding: 0 10px; font-style: italic;"
                  >- угол падения, град.</label
                >
                <label style="justify-content: flex-end; padding: 0 10px; font-style: italic;"
                  >- глубина залегания, м</label
                >
                <label style="justify-content: flex-start; font-weight: bold;">07. Мощность:</label>
                <label style="justify-content: flex-end; padding: 0 10px; font-style: italic;"
                  >- зоны выветривания, м</label
                >
                <label style="justify-content: flex-end; padding: 0 10px; font-style: italic;"
                  >- зоны частичного выветривания, м</label
                >
                <label style="justify-content: flex-end; padding: 0 10px; font-style: italic;"
                  >- вскрыши, м</label
                >
                <label
                  style="
                    justify-content: flex-end;
                    padding: 0 10px 0 25px;
                    margin-bottom: 15px;
                    font-style: italic;
                  "
                  >в т.ч. четвертичных отложений, м</label
                >
                <label
                  style="
                    justify-content: flex-start;
                    margin-bottom: 12px;
                    height: 25px;
                    font-weight: bold;
                  "
                  >08. Линейная плотность трещин, к-во тр/п.м</label
                >
                <label
                  style="
                    justify-content: flex-start;
                    margin-bottom: 12px;
                    height: 25px;
                    font-weight: bold;
                  "
                  >09. Закарстованность, %</label
                >
                <label
                  style="
                    justify-content: flex-end;
                    margin-bottom: 6px;
                    height: 25px;
                    font-style: italic;
                  "
                  >- заполнитель карста</label
                >
              </div>
              <div style="width: 50%">
                <table style="display: table; width: 100%; margin: 8px 0 32px">
                  <thead>
                    <tr class="tab-data">
                      <th style="width: 33.3%">от</th>
                      <th style="width: 33.3%">до</th>
                      <th style="width: 33.3%">среднее</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="tab-data">
                      <td>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="body_size_from"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="body_size_to"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="body_size_avg"
                        />
                      </td>
                    </tr>
                    <tr class="tab-data">
                      <td>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="body_size_drop_from"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="body_size_drop_to"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="body_size_drop_avg"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table style="display: table; width: 100%; margin-bottom: 35px">
                  <tbody>
                    <tr class="tab-data">
                      <td>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="capacity_from"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="capacity_to"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="capacity_avg"
                        />
                      </td>
                    </tr>
                    <tr class="tab-data">
                      <td>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="open_capacity_from"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="open_capacity_to"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="open_capacity_avg"
                        />
                      </td>
                    </tr>
                    <tr class="tab-data">
                      <td>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="calc_reserves_capacity_from"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="calc_reserves_capacity_to"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="calc_reserves_capacity_avg"
                        />
                      </td>
                    </tr>
                    <tr class="tab-data">
                      <td>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="capcity_sep_layer_from"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="capcity_sep_layer_to"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="capcity_sep_layer_avg"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table style="display: table; width: 100%; margin-bottom: 35px">
                  <tbody>
                    <tr class="tab-data">
                      <td>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="strech_conditions_occurance_direction_from"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="stretch_conditions_occurance_direction_to"
                        />
                      </td>
                    </tr>
                    <tr class="tab-data">
                      <td>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="stretch_conditions_azimuth_from"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="stretch_conditions_azimuth_to"
                        />
                      </td>
                    </tr>
                    <tr class="tab-data">
                      <td>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="stretch_conditions_angle_from"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="stretch_conditions_angle_to"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="stretch_conditions_angle_avg"
                        />
                      </td>
                    </tr>
                    <tr class="tab-data">
                      <td>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="stretch_conditions_depth_from"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="stretch_conditions_depth_to"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="stretch_conditions_depth_avg"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table style="display: table; width: 100%; margin-bottom: 6px">
                  <tbody>
                    <tr class="tab-data">
                      <td>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="erosion_capacity_from"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="erosion_capacity_to"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="erosion_capacity_avg"
                        />
                      </td>
                    </tr>
                    <tr class="tab-data">
                      <td>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="partial_erosion_capacity_from"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="partial_erosion_capacity_to"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="partial_erosion_capacity_avg"
                        />
                      </td>
                    </tr>
                    <tr class="tab-data">
                      <td>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="overburden_capcity_from"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="overburden_capcity_to"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="overburden_capcity_avg"
                        />
                      </td>
                    </tr>
                    <tr class="tab-data">
                      <td>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="deposition_capacity_from"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="deposition_capacity_to"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="deposition_capacity_avg"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>

                <input
                  type="number"
                  style="width: 100%; margin-bottom: 6px"
                  class="form-control form-control-sm"
                  v-model="crack_linear_density"
                />
                <input
                  type="number"
                  style="width: 100%; margin-bottom: 6px"
                  class="form-control form-control-sm"
                  v-model="karstness"
                />
                <input
                  type="number"
                  style="width: 100%"
                  class="form-control form-control-sm"
                  v-model="karst_filler"
                />
              </div>
            </div>
            <button class="btn btn-dark btn-save" v-on:click="saveCharacteristic">
              Сохранить
            </button>
          </form>
        </div>
      </div>
      <div class="tab-H02-inner2 inner" v-if="selected_H02_inner2">
        <div class="inner-body">
          <div class="caption-table">
            <table>
              <tr class="tab-data">
                <th>Объект (название):</th>
                <td>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    v-model="description"
                    placeholder="Месторождение"
                    disabled
                  />
                </td>
              </tr>
              <tr class="tab-data">
                <th>Полезное ископаемое:</th>
                <td>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    v-model="component"
                    placeholder="Полезное ископаемое"
                    disabled
                  />
                </td>
              </tr>
            </table>
          </div>
          <form class="form-inline" style="width: 100%">
            <div>
              <label style="margin-bottom: 16px; font-weight: bold;"
                >011. Прослои пустых пород</label
              >
              <table style="display: table; width: 100%; margin-bottom: 14px">
                <thead>
                  <tr class="tab-data">
                    <th rowspan="2" style="width: 60%">Название пород</th>
                    <th colspan="3" style="width: 40%">Мощность, м</th>
                  </tr>
                  <tr class="tab-data">
                    <th>от</th>
                    <th>до</th>
                    <th>ср.</th>
                  </tr>
                </thead>
                <tbody>
                  <!-- tr class="tab-data">
                    <td>
                      <div class="drpdwn-container" style="width: 100%">
                        <select
                          class="form-select form-control-sm"
                          v-model="selecteOreTypeId"
                          aria-label="Название пород"
                        >
                          <option
                            v-for="o in oreTypes"
                            v-bind:value="o.id"
                            v-bind:key="o.id"
                          >
                            {{ o.description }}
                          </option>
                        </select>
                      </div>
                    </td>
                    <td>
                      <input
                        type="number"
                        class="form-control form-control-sm"
                        v-model="lat_ang"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        class="form-control form-control-sm"
                        v-model="lat_ang"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        class="form-control form-control-sm"
                        v-model="lat_ang"
                      />
                    </td>
                  </tr -->
                
                  <EmptyBodyFillControlRecordRow
                    v-for="body in empty_bodies"
                    v-bind:key="body.id"
                    v-bind:empty_body_id="body.id"
                    v-on:body-removed="onEmptyRockRemoved"
                    v-on:body-updated="onEmptyRockUpdated"
                  />
                  <tr>
                  <td>
                    <button
                      @click="addBody"
                      class="btn btn-dark btn-sm btn-block btn-add add-field"
                    >
                      <BootstrapIcon icon="plus-square-fill" />
                      Добавить
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

            <div>
              <label style="margin-bottom: 16px; text-align: center; font-weight: bold;"
                >012. Вмещающие породы <br />
                (в контуре подсчета запасов)</label
              >
              <table style="display: table; width: 100%; margin-bottom: 14px">
                <thead>
                  <tr class="tab-data">
                    <th style="width: 28%">Положение</th>
                    <th style="width: 32%">Разности пород</th>
                    <th style="width: 40%">Геологический возраст</th>
                  </tr>
                </thead>
                <tbody>
                  <EnbodyRockControlRecordRow
                    v-for="rock in enbody_rocks"
                    v-bind:key="rock.id"
                    v-bind:enbody_rock_id="rock.id"
                    v-on:rock-removed="onEnbodyRockRemoved"
                    v-on:rock-updated="onEnbodyRockUpdated"
                  />
                  <tr>
                  <td>
                    <button
                      @click="addEnbodyRock"
                      class="btn btn-dark btn-sm btn-block btn-add add-field"
                    >
                      <BootstrapIcon icon="plus-square-fill" />
                      Добавить
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

            <div class="q-ed-container">
              <label for="q-editor" style="font-weight: bold;"
                >013. Краткое описание геологического строения
                месторождения</label
              >
              <QuillEditor :toolbar="[['bold', 'italic', 'underline', 'strike'], 
                                      ['blockquote', 'code-block'], 
                                      [{'list':'ordered'}, { 'list': 'bullet' }], 
                                      [{ 'script': 'sub'}, { 'script': 'super' }], 
                                      [{ 'indent': '-1'}, { 'indent': '+1' }], 
                                      [{ 'direction': 'rtl' }], 
                                      [{ 'color': [] }, { 'background': [] }],
                                      [{ 'align': [] }],
                                      ['link', 'video', 'image'],
                                      ['clean'], 
                            ]"
                theme="snow"
                style="height: 600px"
                id="q-editor"
                content-type="html"
                v-model:content="characteristic"
              />
            </div>
            <button class="btn btn-dark btn-save" v-on:click="saveCharacteristic">
              Сохранить
            </button>
          </form>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import BootstrapIcon from "@dvuckovic/vue3-bootstrap-icons";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import axios from "axios";
import Spinner from "../../components/Spinner.vue";
import OkModal from "../../components/OkModal.vue";
import EmptyBodyFillControlRecordRow from "../form_components/EmptyBodyFillControlRecordRow.vue";
import EnbodyRockControlRecordRow from "../form_components/EnbodyRockControlRecordRow.vue";

export default {
  name: "FormH02",
  components: {
    BootstrapIcon,
    QuillEditor,
    Spinner,
    OkModal,
    EmptyBodyFillControlRecordRow,
    EnbodyRockControlRecordRow
  },
  props: [
    "object_name", 
    "mineral_resource", 
    "deposit_id",
    "mine_area_id",
    "component_id"
  ],
  data() {
    return {
      errorMessage: "",
      header: "",
      showError: false,
      component: "",
      selected_H02_inner1: true,
      selected_H02_inner2: false,
      bodies: [],
      complexities: [],
      groups: [],
      group_id: null,
      complexity_type_id: null,
      geological_age: "",
      rocks_mineral: "",
      body_form_id: null,
      number_of_bodies: "",
      body_size_from: "",
      body_size_to: "",
      body_size_avg: "",
      body_size_drop_from: "",
      body_size_drop_to: "",
      body_size_drop_avg: "",
      capacity_from: "",
      capacity_to: "",
      capacity_avg: "",
      open_capacity_from: "",
      open_capacity_to: "",
      open_capacity_avg: "",
      calc_reserves_capacity_from: "",
      calc_reserves_capacity_to: "",
      calc_reserves_capacity_avg: "",
      capcity_sep_layer_from: "",
      capcity_sep_layer_to: "",
      capcity_sep_layer_avg: "",
      strech_conditions_occurance_direction_from: "",
      stretch_conditions_occurance_direction_to: "",
      stretch_conditions_azimuth_from: "",
      stretch_conditions_azimuth_to: "",
      stretch_conditions_angle_from: "",
      stretch_conditions_angle_to: "",
      stretch_conditions_angle_avg: "",
      stretch_conditions_depth_from: "",
      stretch_conditions_depth_to: "",
      stretch_conditions_depth_avg: "",
      erosion_capacity_from: "",
      erosion_capacity_to: "",
      erosion_capacity_avg: "",
      partial_erosion_capacity_from: "",
      partial_erosion_capacity_to: "",
      partial_erosion_capacity_avg: "",
      overburden_capcity_from: "",
      overburden_capcity_to: "",
      overburden_capcity_avg: "",
      deposition_capacity_from: "",
      deposition_capacity_to: "",
      deposition_capacity_avg: "",
      crack_linear_density: "",
      karstness: "",
      karst_filler: "",
      description: "",
      characteristic: "characteristic",
      empty_bodies: [],
      enbody_rocks: []
    };
  },
  methods: {
    hideErrorMessage() {
      this.showError = false;
    },
    onEmptyRockRemoved() {
      this.getBodies();
    },
    onEmptyRockUpdated() {
      this.getBodies();
    },
    addBody(e) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/add_empty_rock/";
      axios
        .post(
          url,
          {
            deposit_id: this.deposit_id,
            mine_area_id: this.mine_area_id,
            component_id: this.component_id,
          },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.getBodies();
          } else {
            this.header = "Ошибка безопасности"
            this.errorMessage = "Доступ запрещен"
            this.showError = true;
          }
        });
      e.preventDefault();
    },
    getBodies() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_empty_rocks/";
      axios
        .post(
          url,
          {
            deposit_id: this.deposit_id,
            mine_area_id: this.mine_area_id,
            component_id: this.component_id,
          },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.empty_bodies = response.data[0].result;
            this.showSpinner = false;
          }
        });
    },
    onEnbodyRockRemoved() {
      this.getEnbodyRocks();
    },
    onEnbodyRockUpdated() {
      this.getEnbodyRocks();
    },
    addEnbodyRock(e) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/add_enbody_rock/";
      axios
        .post(
          url,
          {
            deposit_id: this.deposit_id,
            mine_area_id: this.mine_area_id,
            component_id: this.component_id,
          },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.getEnbodyRocks();
          } else {
            this.header = "Ошибка безопасности"
            this.errorMessage = "Доступ запрещен"
            this.showError = true;
          }
        });
      e.preventDefault();
    },
    getEnbodyRocks() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_enbody_rocks/";
      axios
        .post(
          url,
          {
            deposit_id: this.deposit_id,
            mine_area_id: this.mine_area_id,
            component_id: this.component_id,
          },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.enbody_rocks = response.data[0].result;
            this.showSpinner = false;
          }
        });
    },
    getBodyTypes() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_dictionary_contents/";
      axios
        .post(
          url,
          { category: "SPR_FORMA_TEL", limit: 10000, offset: 0 },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.bodies = response.data[0].result;
          }
        });
    },
    getComplexityTypes() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_dictionary_contents/";
      axios
        .post(
          url,
          { category: "SPR_TIP_SLOJNOSTI", limit: 10000, offset: 0 },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.complexities = response.data[0].result;
          }
        });
    },
    getComplexityGroups() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_dictionary_contents/";
      axios
        .post(
          url,
          { category: "SPR_GRUPPI_SLOJNOSTI", limit: 10000, offset: 0 },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.groups = response.data[0].result;
          }
        });
    },
    getCharacteristics() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_geo_characteristics/";
      axios
        .post(
          url,
          {
            deposit_id: this.deposit_id,
            mine_area_id: this.mine_area_id,
            component_id: this.component_id,
          },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.group_id = response.data[0].result.group_id;
            this.complexity_type_id = response.data[0].result.complexity_type_id;
            this.rocks_mineral = response.data[0].result.rocks_mineral;
            this.geological_age = response.data[0].result.geological_age;
            this.body_form_id = response.data[0].result.body_form_id;
            this.number_of_bodies = response.data[0].result.number_of_bodies;
            this.body_size_from = response.data[0].result.body_size_from;
            this.body_size_to = response.data[0].result.body_size_to;
            this.body_size_avg = response.data[0].result.body_size_avg;
            this.body_size_drop_from = response.data[0].result.body_size_drop_from;
            this.body_size_drop_to = response.data[0].result.body_size_drop_to;
            this.body_size_drop_avg = response.data[0].result.body_size_drop_avg;
            this.capacity_from = response.data[0].result.capacity_from;
            this.capacity_to = response.data[0].result.capacity_to;
            this.capacity_avg = response.data[0].result.capacity_avg;
            this.open_capacity_from = response.data[0].result.open_capacity_from;
            this.open_capacity_to = response.data[0].result.open_capacity_to;
            this.open_capacity_avg = response.data[0].result.open_capacity_avg;
            this.calc_reserves_capacity_from = response.data[0].result.calc_reserves_capacity_from;
            this.calc_reserves_capacity_to = response.data[0].result.calc_reserves_capacity_to;
            this.calc_reserves_capacity_avg = response.data[0].result.calc_reserves_capacity_avg;
            this.capcity_sep_layer_from = response.data[0].result.capcity_sep_layer_from;
            this.capcity_sep_layer_to = response.data[0].result.capcity_sep_layer_to;
            this.capcity_sep_layer_avg = response.data[0].result.capcity_sep_layer_avg;
            this.strech_conditions_occurance_direction_from = response.data[0].result.strech_conditions_occurance_direction_from;
            this.stretch_conditions_occurance_direction_to = response.data[0].result.stretch_conditions_occurance_direction_to;
            this.stretch_conditions_azimuth_from = response.data[0].result.stretch_conditions_azimuth_from;
            this.stretch_conditions_azimuth_to = response.data[0].result.stretch_conditions_azimuth_to;
            this.stretch_conditions_angle_from = response.data[0].result.stretch_conditions_angle_from;
            this.stretch_conditions_angle_to = response.data[0].result.stretch_conditions_angle_to;
            this.stretch_conditions_angle_avg = response.data[0].result.stretch_conditions_angle_avg;
            this.stretch_conditions_depth_from = response.data[0].result.stretch_conditions_depth_from;
            this.stretch_conditions_depth_to = response.data[0].result.stretch_conditions_depth_to;
            this.stretch_conditions_depth_avg = response.data[0].result.stretch_conditions_depth_avg;
            this.erosion_capacity_from = response.data[0].result.erosion_capacity_from;
            this.erosion_capacity_to = response.data[0].result.erosion_capacity_to;
            this.erosion_capacity_avg = response.data[0].result.erosion_capacity_avg;
            this.partial_erosion_capacity_from = response.data[0].result.partial_erosion_capacity_from;
            this.partial_erosion_capacity_to = response.data[0].result.partial_erosion_capacity_to;
            this.partial_erosion_capacity_avg = response.data[0].result.partial_erosion_capacity_avg;
            this.overburden_capcity_from = response.data[0].result.overburden_capcity_from;
            this.overburden_capcity_to = response.data[0].result.overburden_capcity_to;
            this.overburden_capcity_avg = response.data[0].result.overburden_capcity_avg;
            this.deposition_capacity_from = response.data[0].result.deposition_capacity_from;
            this.deposition_capacity_to = response.data[0].result.deposition_capacity_to;
            this.deposition_capacity_avg = response.data[0].result.deposition_capacity_avg;
            this.crack_linear_density = response.data[0].result.crack_linear_density;
            this.karstness = response.data[0].result.karstness;
            this.karst_filler = response.data[0].result.karst_filler;
            this.characteristic = response.data[0].result.description;
            this.showSpinner = false;
          } else {
            this.header = "Ошибка безопасности"
            this.errorMessage = "Доступ запрещен"
            this.showError = true;
          }
        });
    },
    saveCharacteristic(e) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/add_geo_characteristics/";
      axios
        .post(
          url,
          {
            deposit_id: this.deposit_id,
            mine_area_id: this.mine_area_id,
            component_id: this.component_id,
            group_id: this.group_id,
            complexity_type_id: this.complexity_type_id,
            rocks_mineral: this.rocks_mineral,
            geological_age: this.geological_age,
            body_form_id: this.body_form_id,
            number_of_bodies: this.number_of_bodies,
            body_size_from: this.body_size_from,
            body_size_to: this.body_size_to,
            body_size_avg: this.body_size_avg,
            body_size_drop_from: this.body_size_drop_from,
            body_size_drop_to: this.body_size_drop_to,
            body_size_drop_avg: this.body_size_drop_avg,
            capacity_from: this.capacity_from,
            capacity_to: this.capacity_to,
            capacity_avg: this.capacity_avg,
            open_capacity_from: this.open_capacity_from,
            open_capacity_to: this.open_capacity_to,
            open_capacity_avg: this.open_capacity_avg,
            calc_reserves_capacity_from: this.calc_reserves_capacity_from,
            calc_reserves_capacity_to: this.calc_reserves_capacity_to,
            calc_reserves_capacity_avg: this.calc_reserves_capacity_avg,
            capcity_sep_layer_from: this.capcity_sep_layer_from,
            capcity_sep_layer_to: this.capcity_sep_layer_to,
            capcity_sep_layer_avg: this.capcity_sep_layer_avg,
            strech_conditions_occurance_direction_from: this.strech_conditions_occurance_direction_from,
            stretch_conditions_occurance_direction_to: this.stretch_conditions_occurance_direction_to,
            stretch_conditions_azimuth_from: this.stretch_conditions_azimuth_from,
            stretch_conditions_azimuth_to: this.stretch_conditions_azimuth_to,
            stretch_conditions_angle_from: this.stretch_conditions_angle_from,
            stretch_conditions_angle_to: this.stretch_conditions_angle_to,
            stretch_conditions_angle_avg: this.stretch_conditions_angle_avg,
            stretch_conditions_depth_from: this.stretch_conditions_depth_from,
            stretch_conditions_depth_to: this.stretch_conditions_depth_to,
            stretch_conditions_depth_avg: this.stretch_conditions_depth_avg,
            erosion_capacity_from: this.erosion_capacity_from,
            erosion_capacity_to: this.erosion_capacity_to,
            erosion_capacity_avg: this.erosion_capacity_avg,
            partial_erosion_capacity_from: this.partial_erosion_capacity_from,
            partial_erosion_capacity_to: this.partial_erosion_capacity_to,
            partial_erosion_capacity_avg: this.partial_erosion_capacity_avg,
            overburden_capcity_from: this.overburden_capcity_from,
            overburden_capcity_to: this.overburden_capcity_to,
            overburden_capcity_avg: this.overburden_capcity_avg,
            deposition_capacity_from: this.deposition_capacity_from,
            deposition_capacity_to: this.deposition_capacity_to,
            deposition_capacity_avg: this.deposition_capacity_avg,
            crack_linear_density: this.crack_linear_density,
            karstness: this.karstness,
            karst_filler: this.karst_filler,
            description: this.characteristic,
          },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.showError = true;
            this.header = "Сохранение данных"
            this.errorMessage = "Данные были сохранены" 
          } else {
            this.header = "Ошибка безопасности"
            this.errorMessage = "Доступ запрещен"
            this.showError = true;
          }
        });
        e.preventDefault();
    },
    selectTab(id) {
      this.selected_H02_inner1 = false;
      this.selected_H02_inner2 = false;
      switch (id) {
        case 1:
          this.selected_H02_inner1 = true;
          break;
        case 2:
          this.selected_H02_inner2 = true;
          break;
      }
    },
  },
  mounted() {
    this.description = this.object_name;
    this.component = this.mineral_resource;
    this.getBodyTypes();
    this.getComplexityGroups();
    this.getComplexityTypes();
    this.getCharacteristics();
    this.getBodies();
    this.getEnbodyRocks();
    this.showSpinner = true;
  },
};
</script>

<style scoped></style>
