<template>
  <div class="inner-body inner">
    <OkModal
      v-if="showError"
      v-bind:message="errorMessage"
      v-bind:header="header"
      v-on:confirm="hideErrorMessage"
    />
    <Spinner v-if="showSpinner" />
    <nav class="inner-nav">
      <ul class="inner-nav-tab">
        <li class="inner-nav-item">
          <a
            class="inner-nav-link"
            v-bind:class="selected_H03_inner1 ? 'active' : ''"
            @click="selectTab('inner-H03-1')"
            href="#"
            style="display: none"
            >#</a
          >
        </li>
      </ul>
    </nav>
    <div class="caption-table">
      <table>
        <tr class="tab-data">
          <th>Объект (название):</th>
          <td>
            <input
              type="text"
              class="form-control form-control-md"
              v-model="component"
              placeholder="Месторождение"
              disabled
            />
          </td>
        </tr>
        <tr class="tab-data">
          <th>Полезное ископаемое:</th>
          <td>
            <input
              type="text"
              class="form-control form-control-md"
              v-model="description"
              placeholder="Полезное ископаемое"
              disabled
            />
          </td>
        </tr>
      </table>
    </div>
    <div class="title" style="margin-bottom: 16px">
      6. КОНДИЦИИ ДЛЯ ПОДСЧЕТА ЗАПАСОВ
    </div>
    <form class="form-inline" style="width: 100%">
      <div
        style="
          display: flex;
          align-items: center;
          gap: 4px;
          margin-bottom: 10px;
        "
      >
        <label
          for="type"
          style="margin-bottom: 0; width: 20%; justify-content: flex-start"
          >01. Вид кондиций:</label
        >
        <div class="drpdwn-container" style="width: 80%">
          <select
            id="type"
            class="form-select form-control-md"
            v-model="selecteConditionTypeId"
            aria-label="Вид кондиций"
          >
            <option
              v-for="o in conditions"
              v-bind:value="o.id"
              v-bind:key="o.id"
            >
              {{ o.description }}
            </option>
          </select>
        </div>
      </div>
      <div style="margin-bottom: 10px">
        <label for="who" style="justify-content: flex-start"
          >02. Когда и кем разработаны:</label
        >
        <input
          type="text"
          id="whoDeveloped"
          class="form-control form-control-md"
          v-model="whoDeveloped"
        />
      </div>
      <div style="margin-bottom: 10px">
        <label for="who" style="justify-content: flex-start"
          >03. Когда и кем утверждены:</label
        >
        <input
          type="text"
          id="whoApproved"
          class="form-control form-control-md"
          v-model="whoApproved"
        />
      </div>
      <div
        style="
          display: flex;
          align-items: center;
          gap: 4px;
          margin-bottom: 10px;
        "
      >
        <label
          for="protocolNumber"
          style="margin-bottom: 0; width: 20%; justify-content: flex-start"
          >04. № протокола:</label
        >
        <input
          type="text"
          id="who"
          class="form-control form-control-md"
          style="width: 80%"
          v-model="protocolNumber"
        />
      </div>

      <div class="q-ed-container">
        <label for="q-editor">05. Состав кондиций:</label>
        <QuillEditor
          :toolbar="[
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ direction: 'rtl' }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ['link', 'video', 'image'],
            ['clean'],
          ]"
          ref="H06Editor1"
          theme="snow"
          style="height: 600px"
          id="q-editor"
          content-type="html"
          v-model:content="conditionsInfo"
        />
      </div>

      <button class="btn btn-dark btn-save" v-on:click="saveConditions">
        Сохранить
      </button>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import Spinner from "../../components/Spinner.vue";
import OkModal from "../../components/OkModal.vue";
// import BootstrapIcon from "@dvuckovic/vue3-bootstrap-icons";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

export default {
  name: "FormH06",
  components: {
    // BootstrapIcon,
    QuillEditor,
    Spinner,
    OkModal,
  },
  props: [
    "object_name",
    "mineral_resource",
    "deposit_id",
    "mine_area_id",
    "component_id",
  ],
  data() {
    return {
      description: "",
      component: "",
      conditions: [],
      errorMessage: "",
      header: "",
      showError: false,
      showSpinner: false,
      whoDeveloped: "",
      whoApproved: "",
      protocolNumber: "",
      conditionsInfo: "",
    };
  },
  methods: {
    hideErrorMessage() {
      this.showError = false;
    },
    getConditionsTypes() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_dictionary_contents/";
      axios
        .post(
          url,
          { category: "SPR_TIP_KONDICIY", limit: 10000, offset: 0 },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.conditions = response.data[0].result;
            this.getConditions();
          }
        });
    },
    saveConditions(e) {
      this.showSpinner = true;
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/add_or_update_conditions/";
      axios
        .post(
          url,
          {
            deposit_id: this.deposit_id,
            mine_area_id: this.mine_area_id,
            component_id: this.component_id,
            type_id: this.selecteConditionTypeId,
            who_developed: this.whoDeveloped,
            who_approved: this.whoApproved,
            protocol: this.protocolNumber,
            condition: this.conditionsInfo,
          },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.showSpinner = false;
            this.showError = true;
            this.header = "Сохранение данных";
            this.errorMessage = "Данные были сохранены";
          } else {
            this.header = "Ошибка безопасности";
            this.errorMessage = "Доступ запрещен";
            this.showError = true;
          }
        });
      e.preventDefault();
    },
    getConditions() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_conditions/";
      axios
        .post(
          url,
          {
            deposit_id: this.deposit_id,
            mine_area_id: this.mine_area_id,
            component_id: this.component_id,
          },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.showSpinner = false;
            this.selecteConditionTypeId = response.data[0].result.type_id;
            this.whoDeveloped = response.data[0].result.who_developed;
            this.whoApproved = response.data[0].result.who_approved;
            this.protocolNumber = response.data[0].result.protocol;
            this.conditionsInfo = response.data[0].result.condition;
          }
        });
    },
  },
  mounted() {
    this.description = this.object_name;
    this.component = this.mineral_resource;
    this.getConditionsTypes();
    this.showSpinner = true;
    this.showError = false;
  },
};
</script>

<style scoped></style>
