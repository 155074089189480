<template>
  <div class="inner-body inner">
    <OkModal
      v-if="showError"
      v-bind:message="errorMessage"
      v-bind:header="header"
      v-on:confirm="hideErrorMessage"
    />
    <Spinner v-if="showSpinner" />
    <nav class="inner-nav">
      <ul class="inner-nav-tab">
        <li class="inner-nav-item">
          <a
            class="inner-nav-link"
            v-bind:class="selected_H03_inner1 ? 'active' : ''"
            @click="selectTab('inner-H03-1')"
            href="#"
            style="display: none"
            >#</a
          >
        </li>
      </ul>
    </nav>
    <div class="caption-table">
      <table>
        <tr class="tab-data">
          <th>Объект (название):</th>
          <td>
            <input
              type="text"
              class="form-control form-control-md"
              v-model="component"
              placeholder="Месторождение"
              disabled
            />
          </td>
        </tr>
        <tr class="tab-data">
          <th>Полезное ископаемое:</th>
          <td>
            <input
              type="text"
              class="form-control form-control-md"
              v-model="description"
              placeholder="Полезное ископаемое"
              disabled
            />
          </td>
        </tr>
      </table>
    </div>
    <div class="title" style="margin-bottom: 4px">
      9. ГОРНОТЕХНИЧЕСКИЕ РЕШЕНИЯ ПО РАЗРАБОТКЕ РАЗВЕДАННЫХ ЗАПАСОВ
    </div>
    <label>
      (заполняется по данным ГЭО для месторождений, не находящихся в разработке)
    </label>
    <form class="form-inline" style="width: 100%; margin-bottom: 16px">
      <div class="q-ed-container">
        <QuillEditor
          :toolbar="[
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ direction: 'rtl' }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ['link', 'video', 'image'],
            ['clean'],
          ]"
          theme="snow"
          ref="H0910Editor1"
          style="height: 600px"
          id="q-editor"
          content-type="html"
          v-model:content="mineTechnicalInfo"
        />
      </div>
    </form>

    <div class="title" style="margin-bottom: 16px">
      10. МЕРОПРИЯТИЯ ПО ЗАЩИТЕ И ВОССТАНОВЛЕНИЮ ОКРУЖАЮЩЕЙ СРЕДЫ
    </div>
    <form class="form-inline" style="width: 100%">
      <div class="q-ed-container">
        <QuillEditor
          :toolbar="[
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ direction: 'rtl' }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ['link', 'video', 'image'],
            ['clean'],
          ]"
          theme="snow"
          ref="H0910Editor2"
          style="height: 600px"
          id="q-editor"
          content-type="html"
          v-model:content="environmentInfo"
        />
      </div>

      <button class="btn btn-dark btn-save" v-on:click="saveForms">
        Сохранить
      </button>
    </form>
  </div>
</template>

<script>
// import BootstrapIcon from "@dvuckovic/vue3-bootstrap-icons";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import Spinner from "../../components/Spinner.vue";
import axios from "axios";

import OkModal from "../../components/OkModal.vue";

export default {
  name: "FormH09H010",
  components: {
    // BootstrapIcon,
    QuillEditor,
    OkModal,
    Spinner,
  },
  props: [
    "object_name",
    "mineral_resource",
    "deposit_id",
    "mine_area_id",
    "component_id",
  ],
  data() {
    return {
      description: "",
      component: "",
      environmentInfo: "",
      mineTechnicalInfo: "",
      errorMessage: "",
      header: "",
      showError: false,
      committed: 0,
      expected: 2,
      showSpinner: false,
    };
  },
  methods: {
    saveForms(e) {
      this.committed = 0;
      this.saveEnvironmentInfo();
      this.saveMineTechInfo();
      e.preventDefault();
    },
    hideErrorMessage() {
      this.showError = false;
    },
    getEnvironmentInfo() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };

      const data = {
        deposit_id: this.deposit_id,
        mine_area_id: this.mine_area_id,
        component_id: this.component_id,
      };
      const url = this.$BASE_URL + "/api/get_environment_info/";
      axios.post(url, data, { headers }).then((response) => {
        if (!response.data[0].auth_fail) {
          this.environmentInfo = response.data[0].result.description;
          if (!this.environmentInfo) {
            this.environmentInfo = "";
          }
          this.committed++;
          if (this.committed == this.expected) {
            this.showSpinner = false;
            this.committed = 0;
          }
          this.$refs.H0910Editor2.setContents(this.environmentInfo);
        }
      });
    },
    saveEnvironmentInfo() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const data = {
        deposit_id: this.deposit_id,
        mine_area_id: this.mine_area_id,
        component_id: this.component_id,
        description: this.environmentInfo,
      };
      const url = this.$BASE_URL + "/api/add_or_update_environment_info/";
      axios.post(url, data, { headers }).then((response) => {
        if (!response.data[0].auth_fail) {
          this.committed++;
          if (this.committed == this.expected) {
            this.showError = true;
            this.header = "Сохранение данных";
            this.errorMessage = "Данные были сохранены";
          }
        } else {
          this.header = "Ошибка безопасности";
          this.errorMessage = "Доступ запрещен";
          this.showError = true;
        }
      });
    },
    getMineTechInfo() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };

      const data = {
        deposit_id: this.deposit_id,
        mine_area_id: this.mine_area_id,
        component_id: this.component_id,
      };
      const url = this.$BASE_URL + "/api/get_mine_tech_info/";
      axios.post(url, data, { headers }).then((response) => {
        if (!response.data[0].auth_fail) {
          this.mineTechnicalInfo = response.data[0].result.description;
          if (!this.mineTechnicalInfo) {
            this.mineTechnicalInfo = "";
          }
          this.committed++;
          if (this.committed == this.expected) {
            this.showSpinner = false;
            this.committed = 0;
          }
          this.$refs.H0910Editor1.setContents(this.mineTechnicalInfo);
        }
      });
    },
    saveMineTechInfo() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const data = {
        deposit_id: this.deposit_id,
        mine_area_id: this.mine_area_id,
        component_id: this.component_id,
        description: this.mineTechnicalInfo,
      };
      const url = this.$BASE_URL + "/api/add_or_update_mine_tech_info/";
      axios.post(url, data, { headers }).then((response) => {
        if (!response.data[0].auth_fail) {
          this.committed++;
          if (this.committed == this.expected) {
            this.showError = true;
            this.header = "Сохранение данных";
            this.errorMessage = "Данные были сохранены";
          }
        } else {
          this.header = "Ошибка безопасности";
          this.errorMessage = "Доступ запрещен";
          this.showError = true;
        }
      });
    },
  },
  mounted() {
    this.description = this.object_name;
    this.component = this.mineral_resource;
    this.getMineTechInfo();
    this.getEnvironmentInfo();
    this.showSpinner = true;
  },
};
</script>

<style scoped></style>
