<template>
  <tr class="tab-data">
    <td style="width: 368px">
      <input
        type="text"
        class="form-control form-control-md"
        v-model="typeOfWork"
        v-bind:disabled="!enableEditing"
      />
    </td>
    <td style="width: 68px">
      <input
        type="number"
        class="form-control form-control-md"
        v-model="value"
        v-bind:disabled="!enableEditing"
      />
    </td>
    <td style="width: 113px; border: none !important">
      <button
        class="btn btn-dark btn-md btn-block btn-edit"
        v-on:click="updateRecord"
      >
        <span v-if="!enableEditing"><BootstrapIcon icon="pencil" /></span>
        <span v-if="enableEditing"><BootstrapIcon icon="check2" /></span>
      </button>
      <button
        class="btn btn-dark btn-md btn-block btn-delete"
        v-on:click="removeRecord"
      >
        <BootstrapIcon icon="trash" />
      </button>
      <OkModal
        v-if="showError"
        v-bind:message="errorMessage"
        v-bind:header="header"
        v-on:confirm="hideErrorMessage"
      />
    </td>
  </tr>
</template>

<script>
import axios from "axios";

import BootstrapIcon from "@dvuckovic/vue3-bootstrap-icons";
import OkModal from "../../components/OkModal.vue";

export default {
  name: "TypeOfWorksControlRecordRow",
  components: {
    BootstrapIcon,
    OkModal
  },
  props: ["deposit_id", "mine_area_id", "component_id", "type_of_work_id"],
  data() {
    return {
      value: 0,
      typeOfWork: "",
      enableEditing: false,
      errorMessage: "",
      header: "",
      showError: false,
    };
  },
  methods: {
    updateRecord(e) {
      if (this.enableEditing) {
        this.enableEditing = false;
        const token = sessionStorage.getItem("token");
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        };
        const data = {
          type_of_work_id: this.type_of_work_id,
          type_of_work: this.typeOfWork,
          value: this.value,
        };
        const url = this.$BASE_URL + "/api/update_type_of_work/";
        axios.post(url, data, { headers }).then((response) => {
          if (!response.data[0].auth_fail) {
            this.$emit("type-of-work-updated");
          } else {
            this.header = "Ошибка безопасности"
            this.errorMessage = "Доступ запрещен"
            this.showError = true;
          }
        });
      } else {
        this.enableEditing = true;
      }
      e.preventDefault();
    },
    getTypeOfWork() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const data = {
        type_of_work_id: this.type_of_work_id,
      };
      const url = this.$BASE_URL + "/api/get_type_of_work/";
      axios.post(url, data, { headers }).then((response) => {
        if (!response.data[0].auth_fail) {
          this.typeOfWork = response.data[0].result.type_of_work;
          this.value = response.data[0].result.value;
        }
      });
    },
    removeRecord(e) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const data = {
        type_of_work_id: this.type_of_work_id,
      };
      const url = this.$BASE_URL + "/api/delete_type_of_work/";
      axios.post(url, data, { headers }).then((response) => {
        if (!response.data[0].auth_fail) {
          this.$emit("type-of-work-removed");
        } else {
          this.header = "Ошибка безопасности"
          this.errorMessage = "Доступ запрещен"
          this.showError = true;
        }
      });
      e.preventDefault();
    },
  },
  mounted() {
    this.getTypeOfWork();
  },
};
</script>

<style></style>
