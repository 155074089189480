<template>
  <tr class="tab-data">
    <td style="width: 368px">
      <input
        type="text"
        class="form-control form-control-md"
        v-model="year"
        v-bind:disabled="!enableEditing"
      />
    </td>
    <td style="width: 313px">
      <select
        class="form-select form-control-md"
        v-model="selectedStageId"
        :disabled="!enableEditing"
      >
        <option
          v-for="stage in stages"
          v-bind:key="stage.id"
          v-bind:value="stage.id"
        >
          {{ stage.description }}
        </option>
      </select>
    </td>
    <td style="width: 113px; border: none !important">
      <button
        class="btn btn-dark btn-md btn-block btn-edit"
        v-on:click="updateRecord"
      >
        <span v-if="!enableEditing"><BootstrapIcon icon="pencil" /></span>
        <span v-if="enableEditing"><BootstrapIcon icon="check2" /></span>
      </button>
      <button
        class="btn btn-dark btn-md btn-block btn-delete"
        v-on:click="removeRecord"
      >
        <BootstrapIcon icon="trash" />
      </button>
      <OkModal
        v-if="showError"
        v-bind:message="errorMessage"
        v-bind:header="header"
        v-on:confirm="hideErrorMessage"
      />
    </td>
  </tr>
</template>

<script>
import axios from "axios";

import BootstrapIcon from "@dvuckovic/vue3-bootstrap-icons";
import OkModal from "../../components/OkModal.vue";

export default {
  name: "ObjectStudyControlRecordRow",
  components: {
    BootstrapIcon,
    OkModal
  },
  props: ["deposit_id", "mine_area_id", "component_id", "study_id"],
  data() {
    return {
      year: "",
      selectedStageId: null,
      stages: [],
      enableEditing: false,
      errorMessage: "",
      header: "",
      showError: false,
    };
  },
  methods: {
    hideErrorMessage() {
      this.showError = false;
    },
    updateRecord(e) {
      if (this.enableEditing) {
        this.enableEditing = false;
        const token = sessionStorage.getItem("token");
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        };
        const data = {
          study_id: this.study_id,
          year: this.year,
          stage_id: this.selectedStageId,
        };
        const url = this.$BASE_URL + "/api/update_study/";
        axios.post(url, data, { headers }).then((response) => {
          if (!response.data[0].auth_fail) {
            this.$emit("study-updated");
          } else {
            this.header = "Ошибка безопасности"
            this.errorMessage = "Доступ запрещен"
            this.showError = true;
          }
        });
      } else {
        this.enableEditing = true;
      }
      e.preventDefault();
    },
    getStudy() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const data = {
        study_id: this.study_id,
      };
      const url = this.$BASE_URL + "/api/get_study/";
      axios.post(url, data, { headers }).then((response) => {
        if (!response.data[0].auth_fail) {
          this.year = response.data[0].result.year;
          this.selectedStageId = response.data[0].result.stage_id;
        }
      });
    },
    removeRecord(e) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const data = {
        study_id: this.study_id,
      };
      const url = this.$BASE_URL + "/api/delete_study/";
      axios.post(url, data, { headers }).then((response) => {
        if (!response.data[0].auth_fail) {
          this.$emit("study-removed");
        } else {
          this.header = "Ошибка безопасности"
          this.errorMessage = "Доступ запрещен"
          this.showError = true;
        }
      });
      e.preventDefault();
    },
    getStages() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const data = {
        category: "SPR_STADII_IZUCHENNOSTI",
        offset: 0,
        limit: 100000,
      };
      const url = this.$BASE_URL + "/api/get_dictionary_contents/";
      axios.post(url, data, { headers }).then((response) => {
        if (!response.data[0].auth_fail) {
          this.stages = response.data[0].result;
          this.getStudy();
        }
      });
    },
  },
  mounted() {
    this.getStages();
  },
};
</script>

<style></style>
