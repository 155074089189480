<template>
  <div class="body">
    <OkModal
      v-if="showError"
      v-bind:message="errorMessage"
      v-bind:header="header"
      v-on:confirm="hideErrorMessage"
    />
    <Spinner v-if="showSpinner" />
    <!-- inner menu for H01 -->
    <nav class="inner-nav">
      <ul class="inner-nav-tab">
        <li class="inner-nav-item">
          <a
            class="inner-nav-link"
            v-bind:class="selected_H01_inner1 ? 'active' : ''"
            @click="selectTab(1)"
            href="#"
            >Общие сведения</a
          >
        </li>
        <li class="inner-nav-item">
          <a
            class="inner-nav-link"
            v-bind:class="selected_H01_inner2 ? 'active' : ''"
            @click="selectTab(2)"
            href="#"
            >Координаты</a
          >
        </li>
        <li class="inner-nav-item">
          <a
            class="inner-nav-link"
            v-bind:class="selected_H01_inner3 ? 'active' : ''"
            @click="selectTab(3)"
            href="#"
            >Населенные пункты</a
          >
        </li>
        <li class="inner-nav-item">
          <a
            class="inner-nav-link"
            v-bind:class="selected_H01_inner4 ? 'active' : ''"
            @click="selectTab(4)"
            href="#"
            >Источники</a
          >
        </li>
        <li class="inner-nav-item">
          <a
            class="inner-nav-link"
            v-bind:class="selected_H01_inner5 ? 'active' : ''"
            @click="selectTab(5)"
            href="#"
            >Доп. сведения</a
          >
        </li>
        <li class="inner-nav-item">
          <a
            class="inner-nav-link"
            v-bind:class="selected_H01_inner6 ? 'active' : ''"
            @click="selectTab(6)"
            href="#"
            >Изученность</a
          >
        </li>
      </ul>
      <!-- inner tabs to show -->

      <div class="tab-H01-inner1 inner" v-if="selected_H01_inner1">
        <div class="inner-body">
          <div class="title">1. ОБЩИЕ СВЕДЕНИЯ О МЕСТОРОЖДЕНИИ</div>
          <form class="form-inline" style="width: 100%">
            <table
              id="title-table"
              style="border: none !important; width: 100%"
            >
              <tr>
                <td style="margin: 10px">
                  <div class="form-group 100%">
                    <label style="float: left">01. Название объекта</label>
                    <input
                      type="text"
                      class="form-control form-control-md"
                      v-model="description"
                      placeholder="Месторождение"
                      disabled
                    />
                  </div>
                </td>
                <td style="margin: 10px">
                  <div class="form-group 100%">
                    <label style="float: left">06. Полезное ископаемое</label>
                    <input
                      type="text"
                      class="form-control form-control-md"
                      v-model="component"
                      placeholder="Полезное ископаемое"
                      disabled
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td style="margin: 10px">
                  <div class="form-group 100%">
                    <label style="float: left">02. Участок</label>
                    <input
                      type="text"
                      class="form-control form-control-md"
                      v-model="mineArea"
                      placeholder="Участок"
                      disabled
                    />
                  </div>
                </td>
                <td rowspan="2">
                  <label style="justify-content: flex-start"
                    >07. Применение</label
                  >
                  <div id="scrollable">
                    <table
                      class="form-group"
                      v-for="usage in usages"
                      v-bind:key="usage.id"
                      style="width: 100%"
                    >
                      <UsageControlRecordRow
                        v-bind:deposit_id="deposit_id"
                        v-bind:mine_area_id="mine_area_id"
                        v-bind:component_id="component_id"
                        v-bind:usage_id="usage.id"
                        v-on:usage-removed="onUsageRemoved"
                        v-on:usage-updated="onUsageUpdated"
                      />
                    </table>
                  </div>
                  <button
                    @click="addUsage"
                    class="btn btn-dark btn-sm btn-block btn-add"
                    style="margin-top: 7px"
                  >
                    <BootstrapIcon icon="plus-square-fill" />
                    Добавить
                  </button>
                </td>
              </tr>
              <tr>
                <td style="margin: 10px">
                  <div class="form-group 100%">
                    <label style="float: left">03. Область</label>
                    <input
                      type="text"
                      class="form-control form-control-md"
                      v-model="area_name_c"
                      placeholder="Область"
                      disabled
                    />
                  </div>
                </td>
                <td></td>
              </tr>
              <tr>
                <td style="margin: 10px">
                  <div class="form-group 100%">
                    <label style="float: left">04. Район</label>
                    <input
                      type="text"
                      class="form-control form-control-md"
                      v-model="district"
                      placeholder="Район"
                      disabled
                    />
                  </div>
                </td>
                <td rowspan="2">
                  <label style="justify-content: flex-start"
                    >08. Ведомственная принадлежность</label
                  >
                  <div class="form-group 100%">
                   
                    <!-- select
                      class="form-select form-control-md"
                      v-model="selectedOrganizationId"
                      aria-label="Ведомственная принадлежность"
                    >
                      <option
                        v-for="o in organizations"
                        v-bind:value="o.id"
                        v-bind:key="o.id"
                      >
                        {{ o.description }}
                      </option>
                    </select -->
                    <div id="scrollable">
                      <table
                        class="form-group"
                        v-for="org in orgs"
                        v-bind:key="org.id"
                        style="width: 100%"
                      >
                        <OrganizationsControlRecordRow
                          v-bind:deposit_id="deposit_id"
                          v-bind:mine_area_id="mine_area_id"
                          v-bind:component_id="component_id"
                          v-bind:org_id="org.id"
                          v-on:org-removed="onOrgRemoved"
                          v-on:org-updated="onOrgUpdated"
                        />
                      </table>
                    </div>
                  </div>
                  <button
                    @click="addOrg"
                    class="btn btn-dark btn-sm btn-block btn-add"
                    style="margin-top: 7px"
                  >
                    <BootstrapIcon icon="plus-square-fill" />
                    Добавить
                  </button>
                </td>
              </tr>
              <tr style="margin: 10px">
                <td>
                  <div class="form-group 100%">
                    <label style="float: left"
                      >05. Номенклатура листа м-ба 1:200000</label
                    >
                    <!-- input
                      type="text"
                      class="form-control form-control-md"
                      v-model="number"
                      placeholder="Номенклатура"
                    / -->
                    <select
                      class="form-select form-control-md"
                      v-model="selectedNumberId"
                      aria-label="Номенклатура листа м-ба 1:200000"
                    >
                      <option
                        v-for="o in numbers"
                        v-bind:value="o.id"
                        v-bind:key="o.id"
                      >
                        {{ o.description }}
                      </option>
                    </select>
                  </div>
                </td>
              </tr>
            </table>
            <button
              class="btn btn-dark btn-save"
              style="margin-top: 30px"
              v-on:click="saveGeneralInfo"
            >
              Сохранить
            </button>
          </form>
        </div>
      </div>
      <div class="tab-H01-inner2 inner" v-if="selected_H01_inner2">
        <div class="inner-body">
          <form class="form-inline" style="width: 100%">
            <table style="border: none !important; width: 100%">
              <tr style="margin: 10px">
                <td style="margin: 10px">
                  <div class="form-group 100%">
                    <table>
                      <caption>
                        09. Географические координаты центра объекта
                      </caption>
                      <thead>
                        <tr class="tab-data">
                          <th colspan="3">Долгота</th>
                          <th colspan="3">Широта</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="tab-data">
                          <th>град.</th>
                          <th>мин.</th>
                          <th>сек.</th>
                          <th>град.</th>
                          <th>мин.</th>
                          <th>сек.</th>
                        </tr>
                        <tr class="tab-data">
                          <td>
                            <input
                              type="number"
                              class="form-control form-control-md"
                              placeholder="град."
                              v-model="long_degree"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              class="form-control form-control-md"
                              placeholder="мин."
                              v-model="long_min"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              class="form-control form-control-md"
                              placeholder="сек."
                              v-model="long_sec"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              class="form-control form-control-md"
                              placeholder="град."
                              v-model="lat_degree"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              class="form-control form-control-md"
                              placeholder="мин."
                              v-model="lat_min"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              class="form-control form-control-md"
                              placeholder="сек."
                              v-model="lat_sec"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <button
                      class="btn btn-dark btn-save"
                      style="margin-top: 30px"
                      v-on:click="saveGeneralInfo"
                    >
                      Сохранить
                    </button>
                  </div>
                </td>
              </tr>
            </table>
          </form>
        </div>
      </div>
      <div class="tab-H01-inner3 inner" v-if="selected_H01_inner3" style="display: block; width: 90%;">
        <div class="inner-body">
          <form class="form-inline" style="width: 100%">
            <div class="table-container">
              <caption>
                010. Ближайшие пункты
              </caption>
              <table style="display: table; width: 100%" id="table-H01-010">
                <thead>
                  <tr class="tab-data">
                    <th>Название</th>
                    <th style="width: 100px">Расст. км.</th>
                    <th style="width: 140px">Направление, румб</th>
                    <th>Путь сообщения</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="tab-data">
                    <td>
                      <label for="locality" style="display: block"
                        >Населенный пункт</label
                      >
                      <input
                        type="text"
                        class="form-control form-control-md"
                        v-model="inh_area_name"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        class="form-control form-control-md"
                        v-model="inh_area_dist"
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        class="form-control form-control-md"
                        v-model="inh_area_dir"
                      />
                    </td>
                    <td>
                      <!-- input
                        type="text"
                        class="form-control form-control-md"
                        v-model="inh_area_path"
                      / -->
                      <select
                        class="form-select form-control-md"
                        v-model="selectedInhabPathId"
                      >
                        <option
                          v-for="o in inh_paths"
                          v-bind:value="o.id"
                          v-bind:key="o.id"
                        >
                          {{ o.description }}
                        </option>
                      </select>
                    </td>
                  </tr>
                  <tr class="tab-data">
                    <td>
                      <label for="locality" style="display: block"
                        >ЖД станция</label
                      >
                      <input
                        type="text"
                        class="form-control form-control-md"
                        v-model="station_name"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        class="form-control form-control-md"
                        v-model="station_dist"
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        class="form-control form-control-md"
                        v-model="station_dir"
                      />
                    </td>
                    <td>
                      <!-- input
                        type="text"
                        class="form-control form-control-md"
                        v-model="station_path"
                      /-->
                      <select
                        class="form-select form-control-md"
                        v-model="selectedStationPathId"
                      >
                        <option
                          v-for="o in train_paths"
                          v-bind:value="o.id"
                          v-bind:key="o.id"
                        >
                          {{ o.description }}
                        </option>
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>
              <button
                class="btn btn-dark btn-save"
                style="margin-top: 30px"
                v-on:click="saveGeneralInfo"
              >
                Сохранить
              </button>
            </div>
          </form>
        </div>
      </div>
      <div class="tab-H01-inner4 inner" v-if="selected_H01_inner4" style="display: block; width: 70%;">
        <div class="inner-body" style="height: auto">
          <form class="form-inline" style="width: 100%">
            <div class="table-container">
              <table style="display: table; width: 100%">
                <thead>
                  <tr class="tab-data">
                    <th style="width: 150px">Источники</th>
                    <th>Название</th>
                    <th style="width: 150px">Расстояние, км.</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="tab-data">
                    <td>Питьевой воды</td>
                    <td>
                      <input
                        type="text"
                        class="form-control form-control-md"
                        v-model="water_pure_name"
                        placeholder="Название"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        class="form-control form-control-md"
                        v-model="water_pure_dist"
                        placeholder="Расстояние, км."
                      />
                    </td>
                  </tr>
                  <tr class="tab-data">
                    <td>Технической воды</td>
                    <td>
                      <input
                        type="text"
                        class="form-control form-control-md"
                        v-model="water_tech_name"
                        placeholder="Название"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        class="form-control form-control-md"
                        v-model="water_tech_dist"
                        placeholder="Расстояние, км."
                      />
                    </td>
                  </tr>
                  <tr class="tab-data">
                    <td>Электроэнергии</td>
                    <td>
                      <input
                        type="text"
                        class="form-control form-control-md"
                        v-model="electricity_name"
                        placeholder="Название"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        class="form-control form-control-md"
                        v-model="electricity_dist"
                        placeholder="Расстояние, км."
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <button
                class="btn btn-dark btn-save"
                style="margin-top: 30px"
                v-on:click="saveGeneralInfo"
              >
                Сохранить
              </button>
            </div>
          </form>
        </div>
      </div>
      <div class="tab-H01-inner5 inner" v-if="selected_H01_inner5" style="width: 60%;">
        <div class="inner-body" style="height: auto">
          <form class="form-inline" style="width: 100%">
            <div style="display: flex; align-items: center; gap: 10px">
              <p style="width: 60%">011. Абсолют. отметки рельефа м-я, м.</p>
              <p>Мин.</p>
              <input
                style="width: 80px"
                type="text"
                class="form-control form-control-md"
                v-model="landscape_mark_min"
                placeholder="Мин."
              />
              <p>Макс.</p>
              <input
                style="width: 80px"
                type="text"
                class="form-control form-control-md"
                v-model="landscape_mark_max"
                placeholder="Макс."
              />
            </div>

            <div>
              <p>012. Тип рельефа:</p>
              <select
                style="width: 50%"
                class="form-select form-control-md"
                v-model="selectedLandascapeId"
                aria-label="Тип рельефа"
              >
                <option
                  v-for="l in relief"
                  v-bind:value="l.id"
                  v-bind:key="l.id"
                >
                  {{ l.description }}
                </option>
              </select>
            </div>

            <div style="display: flex; align-items: center; gap: 10px">
              <p style="width: 30%">013. Сейсмичность:</p>
              <input
                type="number"
                style="width: 20%"
                class="form-control form-control-md"
                v-model="seismic_score"
                placeholder="Сейсмичность"
              />
            </div>

            <div style="display: flex; align-items: center; gap: 10px">
              <p style="width: 30%">014. Селеопасность</p>
              <select
                style="width: 20%"
                class="form-select form-control-md"
                v-model="selectedDroughtScoreId"
                aria-label="Селеопасность"
              >
                <option
                  v-for="s in drought"
                  v-bind:value="s.id"
                  v-bind:key="s.id"
                >
                  {{ s.description }}
                </option>
              </select>
            </div>

            <div style="display: flex; align-items: center; gap: 10px">
              <p style="width: 30%">015. Оползнеопасность:</p>
              <select
                style="width: 20%"
                class="form-select form-control-md"
                v-model="selectedAvalanchScoreId"
                aria-label="Оползнеопасность"
              >
                <option
                  v-for="s in avalanch"
                  v-bind:value="s.id"
                  v-bind:key="s.id"
                >
                  {{ s.description }}
                </option>
              </select>
            </div>

            <div>
              <p style="width: 100%">
                016. Принадлежность площади объекта к занятым жилой и
                промышленной застройкой, горным отводам, охранным зонам и
                гидротехническим сооружениям, заповедникам, национальным паркам:
              </p>
              <input
                type="text"
                style="width: 50%"
                class="form-control form-control-md"
                v-model="attachment"
                placeholder="Принадлежность"
              />
            </div>

            <div style="display: flex; align-items: center; gap: 10px">
              <p style="width: 30%">017. Тип земель:</p>
              <select
                style="width: 55%"
                class="form-select form-control-md"
                v-model="selectedLandTypeId"
                aria-label="Тип земель"
              >
                <option
                  v-for="s in land_type"
                  v-bind:value="s.id"
                  v-bind:key="s.id"
                >
                  {{ s.description }}
                </option>
              </select>
            </div>

            <div>
              <p>018. Размеры объекта</p>

              <table style="display: table; width: 100%;">
                <tbody>
                  <tr class="tab-data">
                    <th
                      style="width: 70%; text-align: left; padding-left: 10px"
                    >
                      Длина, м
                    </th>
                    <td style="width: 30%">
                      <input
                        type="number"
                        class="form-control form-control-md"
                        v-model="length"
                      />
                    </td>
                  </tr>
                  <tr class="tab-data">
                    <th
                      style="width: 70%; text-align: left; padding-left: 10px"
                    >
                      Ширина максимальная, м
                    </th>
                    <td style="width: 30%">
                      <input
                        type="number"
                        class="form-control form-control-md"
                        v-model="width"
                      />
                    </td>
                  </tr>
                  <tr class="tab-data">
                    <th
                      style="width: 70%; text-align: left; padding-left: 10px"
                    >
                      Площадь, тыс.м<sup>2</sup>
                    </th>
                    <td style="width: 30%">
                      <input
                        type="number"
                        class="form-control form-control-md"
                        v-model="area"
                      />
                    </td>
                  </tr>
                  <tr class="tab-data">
                    <th
                      style="width: 70%; text-align: left; padding-left: 10px"
                    >
                      Площадь утвержденных запасов, тыс.м<sup>2</sup>
                    </th>
                    <td style="width: 30%">
                      <input
                        type="number"
                        class="form-control form-control-md"
                        v-model="reserves_area"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <button
                class="btn btn-dark btn-save"
                style="margin-top: 30px"
                v-on:click="saveGeneralInfo"
              >
                Сохранить
              </button>
            </div>
          </form>
        </div>
      </div>
      <div class="tab-H01-inner6 inner" v-if="selected_H01_inner6" style="display: block; width: 70%;">
        <div class="inner-body" style="height: auto">
          <form class="form-inline" style="width: 100%">
            <caption>
              019. Изученность объекта
            </caption>
            <div class="table-container">
              <table style="display: table; width: 100%">
                <thead>
                  <tr class="tab-data">
                    <th style="width: 20%">Годы</th>
                    <th style="width: 70%">Стадии изученности</th>
                    <th style="width: 10%; border: none !important"></th>
                  </tr>
                </thead>
                <tbody>
                  <ObjectStudyControlRecordRow
                    v-for="study in studies"
                    v-bind:key="study.id"
                    v-bind:study_id="study.id"
                    v-bind:deposit_id="deposit_id"
                    v-bind:mine_area_id="mine_area_id"
                    v-bind:component_id="component_id"
                    v-on:study-updated="onStudyUpdated"
                    v-on:study-removed="onStudyRemoved"
                  />
                </tbody>
              </table>
            </div>
            <button
              @click="addStudy"
              class="btn btn-dark btn-md btn-block btn-add add-field"
            >
              <BootstrapIcon icon="plus-square-fill" />
              Добавить
            </button>
          </form>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import axios from "axios";
import Spinner from "../../components/Spinner.vue";
import OkModal from "../../components/OkModal.vue";
import BootstrapIcon from "@dvuckovic/vue3-bootstrap-icons";
import UsageControlRecordRow from "../form_components/UsageControlRecordRow.vue";
import ObjectStudyControlRecordRow from "../form_components/ObjectStudyControlRecordRow.vue";
import OrganizationsControlRecordRow from "../form_components/OrganizationsControlRecordRow.vue";

export default {
  name: "FormH01",
  components: {
    BootstrapIcon,
    UsageControlRecordRow,
    ObjectStudyControlRecordRow,
    OrganizationsControlRecordRow,
    Spinner,
    OkModal,
  },
  props: [
    "object_name",
    "mineral_resource",
    "mine_area",
    "diffs_list",
    "area_name",
    "district_name",
    "deposit_id",
    "mine_area_id",
    "component_id",
  ],
  data() {
    return {
      errorMessage: "",
      header: "",
      showError: false,
      showSpinner: false,

      // inner 1
      description: "",
      component: "",
      mineArea: "",
      selectedDiffId: "",
      diffs: [],
      district: "",
      selectedOrganizationId: "",
      selectedNumberId: null,
      organizations: [],
      usages: [],
      studies: [],
      orgs: [],
      numbers: [],

      area_name_c: "",

      expected: 8,
      committed: 0,

      // inner 2

      // inner 3
      inh_area_name: "",
      inh_area_dist: "",
      inh_area_dir: "",
      inh_area_path: "",

      station_name: "",
      station_dist: "",
      station_dir: "",
      station_path: "",

      // inner 4
      water_pure_name: "",
      water_pure_dist: "",
      water_tech_name: "",
      water_tech_dist: "",
      electricity_name: "",
      electricity_dist: "",
      train_paths: [],
      inh_paths: [],
      selectedStationPathId: null,
      selectedInhabPathId: null,

      number: "",
      lat_degree: 0.0,
      lat_min: 0,
      lat_sec: 0,
      long_degree: 0,
      long_min: 0,
      long_sec: 0,

      // inner 5
      landscape_mark_min: 0,
      landscape_mark_max: 0,
      seismic_score: "",
      selectedLandascapeId: "",
      relief: [],
      selectedDroughtScoreId: "",
      drought: [],
      selectedAvalanchScoreId: "",
      avalanch: [],
      attachment: "",
      selectedLandTypeId: "",
      land_type: [],
      width: 0,
      length: 0,
      area: 0,
      reserves_area: 0,

      // inner statuses
      selected_H01_inner1: true,
      selected_H01_inner2: false,
      selected_H01_inner3: false,
      selected_H01_inner4: false,
      selected_H01_inner5: false,
      selected_H01_inner6: false,
    };
  },
  methods: {
    hideErrorMessage() {
      this.showError = false;
    },
    getGeneralInfo() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const data = {
        deposit_id: this.deposit_id,
        mine_area_id: this.mine_area_id,
        component_id: this.component_id,
      };
      const url = this.$BASE_URL + "/api/get_general_info/";
      axios.post(url, data, { headers }).then((response) => {
        if (!response.data[0].auth_fail) {
          this.selectedNumberId = response.data[0].result.number_id;
          this.selectedOrganizationId = response.data[0].result.org_id;
          this.water_pure_name = response.data[0].result.water_pure_name;
          this.water_pure_dist = response.data[0].result.water_pure_dist;
          this.water_tech_name = response.data[0].result.water_tech_name;
          this.water_tech_dist = response.data[0].result.water_tech_dist;
          this.electricity_name = response.data[0].result.electricity_name;
          this.electricity_dist = response.data[0].result.electricity_dist;
          this.landscape_mark_min = response.data[0].result.landscape_mark_min;
          this.landscape_mark_max = response.data[0].result.landscape_mark_max;
          this.selectedLandascapeId = response.data[0].result.landscape_id;
          this.seismic_score = response.data[0].result.seismic_score;
          this.selectedDroughtScoreId = response.data[0].result.drought_id;
          this.selectedAvalanchScoreId = response.data[0].result.avalanch_id;
          this.attachment = response.data[0].result.attachment;
          this.selectedLandTypeId = response.data[0].result.land_id;
          this.length = response.data[0].result.length;
          this.width = response.data[0].result.width;
          this.area = response.data[0].result.area;
          this.reserves_area = response.data[0].result.reserves_area;
          this.lat_degree = response.data[0].result.lat_degree;
          this.lat_min = response.data[0].result.lat_min;
          this.lat_sec = response.data[0].result.lat_sec;
          this.long_degree = response.data[0].result.long_degree;
          this.long_min = response.data[0].result.long_min;
          this.long_sec = response.data[0].result.long_sec;

          this.inh_area_name = response.data[0].result.inh_area_name;
          this.inh_area_dir = response.data[0].result.inh_area_dir;
          //this.inh_area_path = response.data[0].result.inh_area_path;
          
          this.selectedInhabPathId = response.data[0].result.inh_area_path_id;
          this.inh_area_dist = response.data[0].result.inh_area_dist;
          this.station_name = response.data[0].result.station_name;
          this.station_dir = response.data[0].result.station_dir;
          this.station_path = response.data[0].result.station_path;
          this.selectedStationPathId = response.data[0].result.station_path_id;

          this.station_dist = response.data[0].result.station_dist;

          this.getUsages();
          this.getStudies();
          this.getOrgs();
          this.getReliefs();
          this.getDroughts();
          this.getAvalanch();
          this.getLandTypes();
          this.getNumbers();
          this.getPaths();
        } else {
          this.header = "Ошибка безопасности"
          this.errorMessage = "Доступ запрещен"
          this.showError = true;
        }
      });
    },
    saveGeneralInfo(e) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const data = {
        deposit_id: this.deposit_id,
        mine_area_id: this.mine_area_id,
        component_id: this.component_id,
        number_id: this.selectedNumberId,
        water_pure_name: this.water_pure_name,
        water_pure_dist: this.water_pure_dist,
        water_tech_name: this.water_tech_name,
        water_tech_dist: this.water_tech_dist,
        electricity_name: this.electricity_name,
        electricity_dist: this.electricity_dist,
        inh_area_name: this.inh_area_name,
        inh_area_dir: this.inh_area_dir,
        inh_area_path: this.selectedInhabPathId,
        inh_area_dist: this.inh_area_dist,
        station_name: this.station_name,
        station_dir: this.station_dir,
        station_path: this.selectedStationPathId,
        station_dist: this.station_dist,
        landscape_mark_min: this.landscape_mark_min,
        landscape_mark_max: this.landscape_mark_max,
        landscape_id: this.selectedLandascapeId,
        seismic_score: this.seismic_score,
        drought_id: this.selectedDroughtScoreId,
        avalanch_id: this.selectedAvalanchScoreId,
        attachment: this.attachment,
        land_id: this.selectedLandTypeId,
        length: this.length,
        width: this.width,
        area: this.area,
        reserves_area: this.reserves_area,
        lat_degree: this.lat_degree,
        lat_min: this.lat_min,
        lat_sec: this.lat_sec,
        long_degree: this.long_degree,
        long_min: this.long_min,
        long_sec: this.long_sec,
      };
      const url = this.$BASE_URL + "/api/add_general_info/";
      axios.post(url, data, { headers }).then((response) => {
        if (!response.data[0].auth_fail) {
          this.showError = true;
          this.header = "Сохранение данных";
          this.errorMessage = "Данные были сохранены";
        } else {
          this.header = "Ошибка безопасности"
          this.errorMessage = "Доступ запрещен"
          this.showError = true;
        }
      });
      e.preventDefault();
    },
    onUsageRemoved() {
      this.getUsages();
    },
    addUsage(e) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const data = {
        deposit_id: this.deposit_id,
        mine_area_id: this.mine_area_id,
        component_id: this.component_id,
      };
      const url = this.$BASE_URL + "/api/add_usage/";
      axios.post(url, data, { headers }).then((response) => {
        if (!response.data[0].auth_fail) {
          this.getUsages();
        } else {
          this.header = "Ошибка безопасности"
          this.errorMessage = "Доступ запрещен"
          this.showError = true;
        }
      });
      e.preventDefault();
    },
    getUsages() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const data = {
        deposit_id: this.deposit_id,
        mine_area_id: this.mine_area_id,
        component_id: this.component_id,
      };
      const url = this.$BASE_URL + "/api/get_usages/";
      axios.post(url, data, { headers }).then((response) => {
        if (!response.data[0].auth_fail) {
          this.usages = response.data[0].result;
          this.committed++;
          if (this.committed == this.expected) {
            this.showSpinner = false;
          }
        } else {
          this.header = "Ошибка безопасности"
          this.errorMessage = "Доступ запрещен"
          this.showError = true;
        }
      });
    },
    addOrg(e) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const data = {
        deposit_id: this.deposit_id,
        mine_area_id: this.mine_area_id,
        component_id: this.component_id,
      };
      const url = this.$BASE_URL + "/api/add_org/";
      axios.post(url, data, { headers }).then((response) => {
        if (!response.data[0].auth_fail) {
          this.getOrgs();
        } else {
          this.header = "Ошибка безопасности"
          this.errorMessage = "Доступ запрещен"
          this.showError = true;
        }
      });
      e.preventDefault();
    },
    getOrgs() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const data = {
        deposit_id: this.deposit_id,
        mine_area_id: this.mine_area_id,
        component_id: this.component_id,
      };
      const url = this.$BASE_URL + "/api/get_orgs/";
      axios.post(url, data, { headers }).then((response) => {
        if (!response.data[0].auth_fail) {
          this.orgs = response.data[0].result;
          this.committed++;
          if (this.committed == this.expected) {
            this.showSpinner = false;
          }
        } else {
          this.header = "Ошибка безопасности"
          this.errorMessage = "Доступ запрещен"
          this.showError = true;
        }
      });
    },
    onOrgRemoved() {
      this.getOrgs();
    },
    onOrgUpdated() {
      this.getOrgs();
    },
    onStudyRemoved() {
      this.getStudies();
    },
    addStudy(e) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const data = {
        deposit_id: this.deposit_id,
        mine_area_id: this.mine_area_id,
        component_id: this.component_id,
      };
      const url = this.$BASE_URL + "/api/add_study/";
      axios.post(url, data, { headers }).then((response) => {
        if (!response.data[0].auth_fail) {
          this.getStudies();
        } else {
          this.header = "Ошибка безопасности"
          this.errorMessage = "Доступ запрещен"
          this.showError = true;
        }
      });
      e.preventDefault();
    },
    getStudies() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const data = {
        deposit_id: this.deposit_id,
        mine_area_id: this.mine_area_id,
        component_id: this.component_id,
      };
      const url = this.$BASE_URL + "/api/get_studies/";
      axios.post(url, data, { headers }).then((response) => {
        if (!response.data[0].auth_fail) {
          this.studies = response.data[0].result;
          this.committed++;
          if (this.committed == this.expected) {
            this.showSpinner = false;
          }
        } else {
          this.header = "Ошибка безопасности"
          this.errorMessage = "Доступ запрещен"
          this.showError = true;
        }
      });
      
    },
    /*getOrgs() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_dictionary_contents/";
      axios
        .post(
          url,
          { category: "SPR_VED", limit: 10000, offset: 0 },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.organizations = response.data[0].result;
            this.committed++;
            if (this.committed == this.expected) {
              this.showSpinner = false;
            }
          }
        });
    },*/
    getNumbers() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_dictionary_contents/";
      axios
        .post(
          url,
          { category: "SPR_NOMENKLATURA", limit: 10000, offset: 0 },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.numbers = response.data[0].result;
            this.committed++;
            if (this.committed == this.expected) {
              this.showSpinner = false;
            }
          }
        });
    },
    getPaths() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_dictionary_contents/";
      axios
        .post(
          url,
          { category: "SPR_PUT_SOOBSHENIYA", limit: 10000, offset: 0 },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.train_paths = this.inh_paths = response.data[0].result;
            this.committed++;
            if (this.committed == this.expected) {
              this.showSpinner = false;
            }
          }
        });
    },
    getReliefs() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_dictionary_contents/";
      axios
        .post(
          url,
          { category: "SPR_TIP_RELEFA", limit: 10000, offset: 0 },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.relief = response.data[0].result;
            this.committed++;
            if (this.committed == this.expected) {
              this.showSpinner = false;
            }
          }
        });
    },
    getDroughts() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_dictionary_contents/";
      axios
        .post(
          url,
          { category: "SPR_SELEOPASNOST", limit: 10000, offset: 0 },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.drought = response.data[0].result;
            this.committed++;
            if (this.committed == this.expected) {
              this.showSpinner = false;
            }
          }
        });
    },
    getAvalanch() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_dictionary_contents/";
      axios
        .post(
          url,
          { category: "SPR_OPOLZNEOPASNOST", limit: 10000, offset: 0 },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.avalanch = response.data[0].result;
            this.committed++;
            if (this.committed == this.expected) {
              this.showSpinner = false;
            }
          }
        });
    },
    getLandTypes() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_dictionary_contents/";
      axios
        .post(
          url,
          { category: "SPR_TIP_ZEMEL", limit: 10000, offset: 0 },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.land_type = response.data[0].result;
            this.committed++;
            if (this.committed == this.expected) {
              this.showSpinner = false;
            }
          }
        });
    },
    selectTab(id) {
      this.selected_H01_inner1 = false;
      this.selected_H01_inner2 = false;
      this.selected_H01_inner3 = false;
      this.selected_H01_inner4 = false;
      this.selected_H01_inner5 = false;
      this.selected_H01_inner6 = false;
      switch (id) {
        case 1:
          this.selected_H01_inner1 = true;
          break;
        case 2:
          this.selected_H01_inner2 = true;
          break;
        case 3:
          this.selected_H01_inner3 = true;
          break;
        case 4:
          this.selected_H01_inner4 = true;
          break;
        case 5:
          this.selected_H01_inner5 = true;
          break;
        case 6:
          this.selected_H01_inner6 = true;
          break;
      }
    },
  },
  mounted() {
    this.description = this.object_name;
    this.component = this.mineral_resource;
    this.mineArea = this.mine_area;
    this.district  = this.district_name;
    this.area_name_c = this.area_name;
    this.getGeneralInfo();
    this.showSpinner = true;
  },
};
</script>

<style scoped>
p {
  margin-bottom: 0;
}

.active {
  background-color: black;
}
</style>
