<template>
  <tr class="tab-data">
    <td>
      <div class="drpdwn-container">
        <!-- <select class="form-select form-control-md" v-model="selectedProductTypeId" v-bind:disabled="!enableEditing">
          <option v-for="o in products" v-bind:value="o.id" v-bind:key="o.id">
            {{ o.description }}
          </option>
        </select> -->
         <input type="text" class="form-control form-control-md" v-model="productType" v-bind:disabled="!enableEditing" />
      </div>
    </td>
    <td>
      <input type="text" class="form-control form-control-md" v-model="grade" v-bind:disabled="!enableEditing" />
    </td>
    <td class="for-btn">
      <button
        @click="updateRecord"
        class="btn btn-dark btn-md btn-block btn-edit"
        title="Редактировать запись"
      >
        <span v-if="!enableEditing"><BootstrapIcon icon="pencil" /></span>
        <span v-if="enableEditing"><BootstrapIcon icon="check2" /></span>
      </button>
    </td>
    <td class="for-btn">
      <button @click="removeRecord" class="btn btn-dark btn-md btn-block btn-delete" style="float: left; width: 42px"
        title="Удалить запись">
        <BootstrapIcon icon="trash" />
      </button>
      <OkModal v-if="showError" v-bind:message="errorMessage" v-bind:header="header" v-on:confirm="hideErrorMessage" />
    </td>
  </tr>
</template>

<script>
import axios from "axios";

import BootstrapIcon from "@dvuckovic/vue3-bootstrap-icons";
import OkModal from "../../components/OkModal.vue";

export default {
  name: "ProductControlRecordRow",
  components: {
    BootstrapIcon,
    OkModal
  },
  props: ["deposit_id", "mine_area_id", "component_id", "product_id"],
  data() {
    return {
      grade: "",
      productType: null,
      products: [],
      enableEditing: false,
      errorMessage: "",
      header: "",
      showError: false,
    };
  },
  methods: {
    hideErrorMessage() {
      this.showError = false;
    },
    updateRecord(e) {
      if (this.enableEditing) {
        this.enableEditing = false;
        const token = sessionStorage.getItem("token");
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        };
        const data = {
          product_id: this.product_id,
          product_type: this.productType,
          grade: this.grade
        };
        const url = this.$BASE_URL + "/api/update_product/";
        axios.post(url, data, { headers }).then((response) => {
          if (!response.data[0].auth_fail) {
            this.$emit("product-updated");
          } else {
            this.header = "Ошибка безопасности"
            this.errorMessage = "Доступ запрещен"
            this.showError = true;
          }
        });
      } else {
        this.enableEditing = true;
      }
      e.preventDefault();
    },
    getProduct() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const data = {
        product_id: this.product_id
      };
      const url = this.$BASE_URL + "/api/get_product/";
      axios.post(url, data, { headers }).then((response) => {
        if (!response.data[0].auth_fail) {
          this.productType = response.data[0].result.product_type;
          this.grade = response.data[0].result.grade;
        }
      });
    },
    removeRecord(e) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const data = {
        product_id: this.product_id
      };
      const url = this.$BASE_URL + "/api/delete_product/";
      axios.post(url, data, { headers }).then((response) => {
        if (!response.data[0].auth_fail) {
          this.$emit("product-removed");
        } else {
          this.header = "Ошибка безопасности"
          this.errorMessage = "Доступ запрещен"
          this.showError = true;
        }
      });
      e.preventDefault();
    },
    getProductTypes() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const data = {
        category: "SPR_TIP_PRODUKTA",
        offset: 0,
        limit: 100000,
      };
      const url = this.$BASE_URL + "/api/get_dictionary_contents/";
      axios.post(url, data, { headers }).then((response) => {
        if (!response.data[0].auth_fail) {
          this.products = response.data[0].result;
          this.getProduct();
        }
      });
    },
  },
  mounted() {
    this.getProductTypes();
  },
};
</script>

<style scoped>
.for-btn {
  border: none !important;
}
</style>
