<template>
  <tr class="tab-data">
    <td>
      <input
        type="text"
        class="form-control form-control-md"
        v-model="rock"
        v-bind:disabled="!enableEditing"
      />
    </td>
    <td>
      <input
        type="number"
        class="form-control form-control-md"
        v-model="valueFrom"
        v-bind:disabled="!enableEditing"
      />
    </td>
    <td>
      <input
        type="number"
        class="form-control form-control-md"
        v-model="valueTo"
        v-bind:disabled="!enableEditing"
      />
    </td>
    <td>
      <input
        type="number"
        class="form-control form-control-md"
        v-model="valueAverage"
        v-bind:disabled="!enableEditing"
      />
    </td>
    <td class="for-btn">
      <button
        @click="updateRecord"
        class="btn btn-dark btn-md btn-block btn-edit"
        title="Редактировать запись"
      >
        <span v-if="!enableEditing"><BootstrapIcon icon="pencil" /></span>
        <span v-if="enableEditing"><BootstrapIcon icon="check2" /></span>
      </button>
    </td>
    <td class="for-btn">
      <button
        @click="removeRecord"
        class="btn btn-dark btn-md btn-block btn-delete"
        style="float: left; width: 42px"
        title="Удалить запись"
      >
        <BootstrapIcon icon="trash" />
      </button>
      <OkModal
        v-if="showError"
        v-bind:message="errorMessage"
        v-bind:header="header"
        v-on:confirm="hideErrorMessage"
      />
    </td>
  </tr>
</template>

<script>
import axios from "axios";

import BootstrapIcon from "@dvuckovic/vue3-bootstrap-icons";
import OkModal from "../../components/OkModal.vue";

export default {
  name: "EmptyBodyFillControlRecordRow",
  components: {
    BootstrapIcon,
    OkModal,
  },
  props: ["deposit_id", "mine_area_id", "component_id", "empty_body_id"],
  data() {
    return {
      valueFrom: 0,
      valueTo: 0,
      valueAverage: 0,
      property: "",
      rock: "",
      enableEditing: false,
      errorMessage: "",
      header: "",
      showError: false,
    };
  },
  methods: {
    hideErrorMessage() {
      this.showError = false;
    },
    updateRecord(e) {
      if (this.enableEditing) {
        this.enableEditing = false;
        const token = sessionStorage.getItem("token");
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        };
        const data = {
          empty_body_id: this.empty_body_id,
          rock: this.rock,
          value_from: this.valueFrom,
          value_to: this.valueTo,
          value_avg: this.valueAverage,
        };
        const url = this.$BASE_URL + "/api/update_empty_rock/";
        axios.post(url, data, { headers }).then((response) => {
          if (!response.data[0].auth_fail) {
            this.$emit("body-updated");
          } else {
            this.header = "Ошибка безопасности";
            this.errorMessage = "Доступ запрещен";
            this.showError = true;
          }
        });
      } else {
        this.enableEditing = true;
      }
      e.preventDefault();
    },
    getEmptyBody() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const data = {
        empty_body_id: this.empty_body_id,
      };
      const url = this.$BASE_URL + "/api/get_empty_rock/";
      axios.post(url, data, { headers }).then((response) => {
        if (!response.data[0].auth_fail) {
          this.valueFrom = response.data[0].result.value_from;
          this.valueTo = response.data[0].result.value_to;
          this.valueAverage = response.data[0].result.value_average;
          this.rock = response.data[0].result.rock;
        }
      });
    },
    removeRecord(e) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const data = {
        empty_body_id: this.empty_body_id,
      };
      const url = this.$BASE_URL + "/api/delete_empty_rock/";
      axios.post(url, data, { headers }).then((response) => {
        if (!response.data[0].auth_fail) {
          this.$emit("body-removed");
        } else {
          this.header = "Ошибка безопасности";
          this.errorMessage = "Доступ запрещен";
          this.showError = true;
        }
      });
      e.preventDefault();
    },
  },
  mounted() {
    this.getEmptyBody();
  },
};
</script>

<style></style>
