<template>
  <div class="inner-body inner">
    <Spinner v-if="showSpinner" />
    <OkModal
      v-if="showError"
      v-bind:message="errorMessage"
      v-bind:header="header"
      v-on:confirm="hideErrorMessage"
    />
    <nav class="inner-nav">
      <ul class="inner-nav-tab">
        <li class="inner-nav-item">
          <a
            class="inner-nav-link"
            v-bind:class="selected_H03_inner1 ? 'active' : ''"
            @click="selectTab('inner-H03-1')"
            href="#"
            style="display: none"
            >#</a
          >
        </li>
      </ul>
    </nav>
    <div class="caption-table">
      <table>
        <tr class="tab-data">
          <th>Объект (название):</th>
          <td>
            <input
              type="text"
              class="form-control form-control-md"
              v-model="component"
              placeholder="Месторождение"
              disabled
            />
          </td>
        </tr>
        <tr class="tab-data">
          <th>Полезное ископаемое:</th>
          <td>
            <input
              type="text"
              class="form-control form-control-md"
              v-model="description"
              placeholder="Полезное ископаемое"
              disabled
            />
          </td>
        </tr>
      </table>
    </div>
    <div class="title" style="text-align: center">
      13. ПЕРЕЧЕНЬ ИСТОЧНИКОВ ИНФОРМАЦИИ ОБ ОБЪЕКТЕ
    </div>

    <form class="form-inline" style="width: 100%; margin-bottom: 10px">
      <table style="display: table; width: 100%">
        <thead>
          <tr class="tab-data">
            <th style="width: 20%">Инф. № Госгеолфонда</th>
            <th style="width: 32%">Автор</th>
            <th style="width: 30%">
              Наименование работы, организация, выпустившая работу, год выпуска
            </th>
            <th style="border: none !important; width: 10%"></th>
          </tr>
        </thead>
        <tbody>
          <tr class="tab-data">
            <td>01</td>
            <td>02</td>
            <td>03</td>
            <td style="border: none !important; width: 10%"></td>
          </tr>
          <AuthorsControlRecordRow
            v-for="author in authorsDocs"
            v-bind:key="author.id"
            v-bind:author_doc_id="author.id"
            v-bind:deposit_id="deposit_id"
            v-bind:mine_area_id="mine_area_id"
            v-bind:component_id="component_id"
            v-on:author-doc-removed="onAuthorRemoved"
            v-on:author-doc-updated="onAuthorUpdated"
          />
        </tbody>
      </table>

      <button
        @click="addAuthorDoc"
        class="btn btn-dark btn-md btn-block btn-add add-field"
      >
        <BootstrapIcon icon="plus-square-fill" />
        Добавить
      </button>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import Spinner from "../../components/Spinner.vue";
import BootstrapIcon from "@dvuckovic/vue3-bootstrap-icons";
import AuthorsControlRecordRow from "../form_components/AuthorsControlRecordRow.vue";
import OkModal from "../../components/OkModal.vue";

export default {
  name: "FormH013",
  components: {
    BootstrapIcon,
    Spinner,
    AuthorsControlRecordRow,
    OkModal
  },
  props: [
    "object_name",
    "mineral_resource",
    "deposit_id",
    "mine_area_id",
    "component_id",
  ],
  data() {
    return {
      description: "",
      component: "",
      showSpinner: false,
      authorsDocs: [],
      errorMessage: "",
      header: "",
      showError: false,
    };
  },
  methods: {
    hideErrorMessage() {
      this.showError = false;
    },
    getAuthorsDocs() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_author_docs/";
      axios
        .post(
          url,
          {
            deposit_id: this.deposit_id,
            mine_area_id: this.mine_area_id,
            component_id: this.component_id,
          },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.authorsDocs = response.data[0].result;
            this.showSpinner = false;
          } else {
            this.header = "Ошибка безопасности"
            this.errorMessage = "Доступ запрещен"
            this.showError = true;
          }
        });
    },
    addAuthorDoc(e) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/add_author_doc/";
      axios
        .post(
          url,
          {
            deposit_id: this.deposit_id,
            mine_area_id: this.mine_area_id,
            component_id: this.component_id,
          },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.getAuthorsDocs();
          } else {
            this.header = "Ошибка безопасности"
            this.errorMessage = "Доступ запрещен"
            this.showError = true;
          }
        });
      e.preventDefault();
    },
    onAuthorRemoved() {
      this.getAuthorsDocs();
    },
    onAuthorUpdated() {
      this.getAuthorsDocs();
    },
  },
  mounted() {
    this.description = this.object_name;
    this.component = this.mineral_resource;
    this.getAuthorsDocs();
    this.showSpinner = true;
  },
};
</script>

<style scoped></style>
