<template>
  <tr class="tab-data">
    <td style="width: 80%; padding: 0 0 3px 0; border: none !important;">
      <select
        class="form-select form-control-sm"
        v-model="selectedOrgId"
        :disabled="!enableEditing"
      >
        <option
          v-for="org in orgs"
          v-bind:key="org.id"
          v-bind:value="org.id"
        >
          {{ org.description }}
        </option>
      </select>
    </td>
    <td style="width: 113px; border: none !important">
      <button
        class="btn btn-dark btn-sm btn-block btn-edit"
        v-on:click="updateRecord"
      >
        <span v-if="!enableEditing"><BootstrapIcon icon="pencil" /></span>
        <span v-if="enableEditing"><BootstrapIcon icon="check2" /></span>
      </button>
      <button
        class="btn btn-dark btn-sm btn-block btn-delete"
        v-on:click="removeRecord"
      >
        <BootstrapIcon icon="trash" />
      </button>
      <OkModal
        v-if="showError"
        v-bind:message="errorMessage"
        v-bind:header="header"
        v-on:confirm="hideErrorMessage"
      />
    </td>
  </tr>
</template>

<script>
import axios from "axios";

import BootstrapIcon from "@dvuckovic/vue3-bootstrap-icons";
import OkModal from "../../components/OkModal.vue";

export default {
  name: "OrganizationsControlRecordRow",
  components: {
    BootstrapIcon,
    OkModal
  },
  props: ["deposit_id", "mine_area_id", "component_id", "org_id"],
  data() {
    return {
      orgs: [],
      selectedOrgId: null,
      enableEditing: false,
      errorMessage: "",
      header: "",
      showError: false,
    };
  },
  methods: {
    updateRecord(e) {
      if (this.enableEditing) {
        this.enableEditing = false;
        const token = sessionStorage.getItem("token");
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        };
        const data = {
          org_id: this.org_id,
          dict_org_id: this.selectedOrgId,
        };
        const url = this.$BASE_URL + "/api/update_orgs/";
        axios.post(url, data, { headers }).then((response) => {
          if (!response.data[0].auth_fail) {
            this.$emit("org-updated");
          } else {
            this.header = "Ошибка безопасности"
            this.errorMessage = "Доступ запрещен"
            this.showError = true;
          }
        });
      } else {
        this.enableEditing = true;
      }
      e.preventDefault();
    },
    getOrg() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const data = {
        org_id: this.org_id,
      };
      const url = this.$BASE_URL + "/api/get_org/";
      axios.post(url, data, { headers }).then((response) => {
        if (!response.data[0].auth_fail) {
          this.selectedOrgId = response.data[0].result.org_id;
        }
      });
    },
    removeRecord(e) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const data = {
        org_id: this.org_id,
      };
      const url = this.$BASE_URL + "/api/delete_org/";
      axios.post(url, data, { headers }).then((response) => {
        if (!response.data[0].auth_fail) {
          this.$emit("org-removed");
        } else {
          this.header = "Ошибка безопасности"
          this.errorMessage = "Доступ запрещен"
          this.showError = true;
        }
      });
      e.preventDefault();
    },
    getOrgs() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_dictionary_contents/";
      axios
        .post(
          url,
          { category: "SPR_VED", limit: 10000, offset: 0 },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.orgs = response.data[0].result;
          }
        });
    },
  },
  mounted() {
    this.getOrgs();
    this.getOrg();
  },
};
</script>

<style></style>
