<template>
  <tr class="tab-data">
    <td>
      <!-- <select
        class="form-select form-control-md"
        v-model="selectedSolidId"
        :disabled="!enableEditing"
      >
        <option
          v-for="solid in solids"
          v-bind:key="solid.id"
          v-bind:value="solid.id"
        >
          {{ solid.description }}
        </option>
      </select> -->
      <input type="text" class="form-control form-control-md" v-model="selectedSolid" v-bind:disabled="!enableEditing">
    </td>
    <td>
      <input
        type="text"
        class="form-control form-control-md"
        v-model="radioactivity"
        :disabled="!enableEditing"
      />
    </td>
    <td>
      <select
        class="form-select form-control-md"
        v-model="selectedRadioactClassId"
        :disabled="!enableEditing"
      >
        <option
          v-for="rclass in classes"
          v-bind:key="rclass.id"
          v-bind:value="rclass.id"
        >
          {{ rclass.description }}
        </option>
      </select>
    </td>
    <td style="width: 10%; border: none !important">
      <button
        @click="updateRecord"
        class="btn btn-dark btn-md btn-block btn-edit"
        style="float: left; width: 42px; margin: 0px"
        title="Редактировать запись"
      >
        <span v-if="!enableEditing">
          <BootstrapIcon icon="pencil" />
        </span>
        <span v-if="enableEditing">
          <BootstrapIcon icon="check2" />
        </span>
      </button>
      <button
        @click="removeRecord"
        class="btn btn-dark btn-md btn-block btn-delete"
        style="float: left; width: 42px; margin-left: 5px"
        title="Удалить запись"
      >
        <BootstrapIcon icon="trash" />
      </button>
      <OkModal
        v-if="showError"
        v-bind:message="errorMessage"
        v-bind:header="header"
        v-on:confirm="hideErrorMessage"
      />
    </td>
  </tr>
</template>

<script>
import axios from "axios";
import BootstrapIcon from "@dvuckovic/vue3-bootstrap-icons";
import OkModal from "../../components/OkModal.vue";

export default {
  name: "RadioActivityCharacteristicsControlRecordRow",
  components: {
    BootstrapIcon,
    OkModal
  },
  props: ["deposit_id", "mine_area_id", "component_id", "radioactivity_id"],
  data() {
    return {
      class: "",
      radioactivity: "",
      radioCharacteristic: "",
      selectedSolid: null,
      selectedRadioactClassId: null,
      solids: [],
      classes: [],
      enableEditing: false,
      errorMessage: "",
      header: "",
      showError: false,
    };
  },
  methods: {
    hideErrorMessage() {
      this.showError = false;
    },
    updateRecord(e) {
      if (this.enableEditing) {
        this.enableEditing = false;
        const token = sessionStorage.getItem("token");
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        };
        const data = {
          radioactivity_id: this.radioactivity_id,
          class_id: this.selectedRadioactClassId,
          solid: this.selectedSolid,
          radioactivity: this.radioactivity,
        };
        const url = this.$BASE_URL + "/api/update_radioactivity/";
        axios.post(url, data, { headers }).then((response) => {
          if (!response.data[0].auth_fail) {
            this.$emit("radioactivity-updated");
          } else {
            this.header = "Ошибка безопасности"
            this.errorMessage = "Доступ запрещен"
            this.showError = true;
          }
        });
      } else {
        this.enableEditing = true;
      }
      e.preventDefault();
    },
    getRadioactivity() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const data = {
        radioactivity_id: this.radioactivity_id,
      };
      const url = this.$BASE_URL + "/api/get_radioactivity/";
      axios.post(url, data, { headers }).then((response) => {
        if (!response.data[0].auth_fail) {
          this.selectedRadioactClassId = response.data[0].result.class_id;
          this.selectedSolid = response.data[0].result.solid;
          this.radioactivity = response.data[0].result.radioactivity;
        }
      });
    },
    removeRecord(e) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const data = {
        radioactivity_id: this.radioactivity_id,
      };
      const url = this.$BASE_URL + "/api/delete_radioactivity/";
      axios.post(url, data, { headers }).then((response) => {
        if (!response.data[0].auth_fail) {
          this.$emit("radioactivity-removed");
        } else {
          this.header = "Ошибка безопасности"
          this.errorMessage = "Доступ запрещен"
          this.showError = true;
        }
      });
      e.preventDefault();
    },
    getSolids() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const data = {
        category: "SPR_PORODA",
        offset: 0,
        limit: 100000,
      };
      const url = this.$BASE_URL + "/api/get_dictionary_contents/";
      axios.post(url, data, { headers }).then((response) => {
        if (!response.data[0].auth_fail) {
          this.solids = response.data[0].result;
          this.getClasses();
        }
      });
    },
    getClasses() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const data = {
        category: "SPR_RADIOCIONNIY_KLASS",
        offset: 0,
        limit: 100000,
      };
      const url = this.$BASE_URL + "/api/get_dictionary_contents/";
      axios.post(url, data, { headers }).then((response) => {
        if (!response.data[0].auth_fail) {
          this.classes = response.data[0].result;
          this.getRadioactivity();
        }
      });
    },
  },
  mounted() {
    this.getSolids();
  },
};
</script>
