<template>
  <tr class="tab-data">
    <td style="width: 368px">
      <input
        type="text"
        class="form-control form-control-md"
        v-model="characteristic"
        v-bind:disabled="!enableEditing"
      />
    </td>
    <td style="width: 140px">
      <select
        class="form-select form-control-md"
        v-model="selectedMeasureId"
        :disabled="!enableEditing"
      >
        <option
          v-for="measure in measures"
          v-bind:key="measure.id"
          v-bind:value="measure.id"
        >
          {{ measure.description }}
        </option>
      </select>
    </td>
    <td style="width: 90px">
      <input
        type="number"
        class="form-control form-control-md"
        v-model="valueFrom"
        v-bind:disabled="!enableEditing"
      />
    </td>
    <td style="width: 90px">
      <input
        type="number"
        class="form-control form-control-md"
        v-model="valueTo"
        v-bind:disabled="!enableEditing"
      />
    </td>
    <td style="width: 90px">
      <input
        type="number"
        class="form-control form-control-md"
        v-model="valueAverage"
        v-bind:disabled="!enableEditing"
      />
    </td>
    <td style="width: 113px; border: none !important">
      <button
        class="btn btn-dark btn-md btn-block btn-edit"
        v-on:click="updateRecord"
      >
        <span v-if="!enableEditing"><BootstrapIcon icon="pencil" /></span>
        <span v-if="enableEditing"><BootstrapIcon icon="check2" /></span>
      </button>
      <button
        class="btn btn-dark btn-md btn-block btn-delete"
        v-on:click="removeRecord"
      >
        <BootstrapIcon icon="trash" />
      </button>
      <OkModal
        v-if="showError"
        v-bind:message="errorMessage"
        v-bind:header="header"
        v-on:confirm="hideErrorMessage"
      />
    </td>
  </tr>
</template>

<script>
import axios from "axios";

import BootstrapIcon from "@dvuckovic/vue3-bootstrap-icons";
import OkModal from "../../components/OkModal.vue";

export default {
  name: "QualityControlRecordRow",
  components: {
    BootstrapIcon,
    OkModal
  },
  props: ["deposit_id", "mine_area_id", "component_id", "characteristic_id"],
  data() {
    return {
      valueFrom: 0,
      valueTo: 0,
      valueAverage: 0,
      characteristic: "",
      selectedMeasureId: null,
      measures: [],
      enableEditing: false,
      errorMessage: "",
      header: "",
      showError: false,
    };
  },
  methods: {
    hideErrorMessage() {
      this.showError = false;
    },
    updateRecord(e) {
      if (this.enableEditing) {
        this.enableEditing = false;
        const token = sessionStorage.getItem("token");
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        };
        const data = {
          quality_id: this.characteristic_id,
          quality: this.characteristic,
          maesure_id: this.selectedMeasureId,
          value_from: this.valueFrom,
          value_to: this.valueTo,
          value_avg: this.valueAverage,
        };
        const url = this.$BASE_URL + "/api/update_quality/";
        axios.post(url, data, { headers }).then((response) => {
          if (!response.data[0].auth_fail) {
            this.$emit("quality-updated");
          } else {
            this.header = "Ошибка безопасности"
            this.errorMessage = "Доступ запрещен"
            this.showError = true;
          }
        });
      } else {
        this.enableEditing = true;
      }
      e.preventDefault();
    },
    getQuality() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const data = {
        quality_id: this.characteristic_id,
      };
      const url = this.$BASE_URL + "/api/get_quality/";
      axios.post(url, data, { headers }).then((response) => {
        if (!response.data[0].auth_fail) {
          this.valueFrom = response.data[0].result.value_from;
          this.valueTo = response.data[0].result.value_to;
          this.valueAverage = response.data[0].result.value_average;
          this.characteristic = response.data[0].result.characteristic;
          this.selectedMeasureId = response.data[0].result.measure_id;
        }
      });
    },
    removeRecord(e) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const data = {
        quality_id: this.characteristic_id,
      };
      const url = this.$BASE_URL + "/api/delete_quality/";
      axios.post(url, data, { headers }).then((response) => {
        if (!response.data[0].auth_fail) {
          this.$emit("quality-removed");
        } else {
          this.header = "Ошибка безопасности"
          this.errorMessage = "Доступ запрещен"
          this.showError = true;
        }
      });
      e.preventDefault();
    },
    getMeasures() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const data = {
        category: "SPR_ED_IZM",
        offset: 0,
        limit: 100000,
      };
      const url = this.$BASE_URL + "/api/get_dictionary_contents/";
      axios.post(url, data, { headers }).then((response) => {
        if (!response.data[0].auth_fail) {
          this.measures = response.data[0].result;
          this.getQuality();
        }
      });
    },
  },
  mounted() {
    this.getMeasures();
  },
};
</script>

<style></style>
