<template>
  <div class="modal-mask">
    <div class="modal-window">
      <div class="header">Управление файлами</div>
      <div class="body">
        <OkModal
          v-if="showAuthError"
          v-bind:message="authMessage"
          v-bind:header="authHeader"
          v-on:confirm="hideAuthMessage"
        />
        <Spinner v-if="showSpinner" />
        <FileItem
          v-for="item in files"
          v-bind:key="item.file"
          v-bind:version="item.version"
          v-bind:file="item.file"
          v-bind:hash="item.hash_file"
          v-bind:date_modified="item.date_modified"
          v-bind:who_modified="item.who_modified"
          v-bind:deposit_id="depositId"
          v-bind:mine_area_id="mineAreaId"
          v-bind:description="item.file + ', версия файла: ' + item.version"
        />
        <!-- button
          @click="uploadFile"
          class="btn btn-dark btn-lg btn-block btn-upload"
          style="float: left; width: 300px"
        >
          <BootstrapIcon icon="plus-square-fill" />
          Добавить новый файл
        </button -->
        <div style="margin: 20px"></div>
        <input
          class="form-control form-control-lg"
          style="float: left; width: 100%"
          type="file"
          id="file"
          ref="file"
          v-on:change="handleFileUpload()"
        />
        <div class="form-group" style="margin-top: 10px">
          <button @click="close" class="btn btn-dark btn-lg btn-block close">
            Закрыть
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import FileItem from "../components/FileItem.vue";
import Spinner from "../components/Spinner.vue";
import OkModal from "../components/OkModal.vue";
//import BootstrapIcon from "@dvuckovic/vue3-bootstrap-icons";

export default {
  name: "FileManager",
  props: ["deposit_id", "mine_area_id"],
  data() {
    return {
      files: [],
      failed: false,
      depositId: this.deposit_id,
      mineAreaId: this.mine_area_id,
      file: null,
      showSpinner: false,
    };
  },
  methods: {
    handleFileUpload() {
      this.showSpinner = true;
      this.file = this.$refs.file.files[0];
      this.uploadFile();
    },
    getFiles() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_latest_file_list/";
      axios
        .post(
          url,
          { deposit_id: this.depositId, mine_area_id: this.mineAreaId },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.files = response.data[0].result;
          }
          this.showSpinner = false;
        });
    },
    close() {
      this.$emit("file-manager-close");
    },
    uploadFile() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + token,
      };

      const formData = new FormData();
      formData.append("file", this.file);
      const url =
        this.$BASE_URL +
        "/api/upload_file/" +
        this.depositId +
        "/" +
        this.mineAreaId +
        "/";
      axios.post(url, formData, { headers }).then((response) => {
        if (!response.data[0].auth_fail) {
          this.getFiles();
        }
        this.$refs.file.value = null;
      });
    },
  },
  components: {
    FileItem,
    //BootstrapIcon,
    Spinner,
    OkModal,
  },
  mounted() {
    this.showSpinner = true;
    this.getFiles();
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  display: flex;
  z-index: 9997;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}

.modal-window {
  border: rgb(169, 255, 202);
  background-color: white;
  position: fixed;
  width: 1040px;
  height: 600px;
  z-index: 100;
  top: 20%;
}

.body {
  font-weight: bolder;
  color: black;
  text-align: center;
}

.close {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.save {
  position: absolute;
  bottom: 10px;
  left: 10px;
}
</style>
