<template>
  <div class="item">
    <FileVersions
      v-bind:file="file"
      v-bind:deposit_id="deposit_id"
      v-bind:mine_area_id="mine_area_id"
      v-if="showFileVersions"
      v-on:close="closeFileVersion"
    />
    <table style="width: 100%">
      <tr>
        <td style="width: 50px">
          <button
            @click="getVersions"
            class="btn btn-dark btn-lg btn-block btn-add"
            style="float: left; width: 55px"
            title="Версии файла"
          >
            <BootstrapIcon icon="bar-chart-steps" />
            <!-- span class="tiptext"
            >
              Просмотреть версии файла
            </span -->
          </button>
        </td>
        <td style="width: 60px">
          <button
            @click="getFile"
            class="btn btn-dark btn-lg btn-block btn-add"
            style="float: left; width: 55px"
            title="Скачать файл"
          >
            <BootstrapIcon icon="cloud-arrow-down" />
          </button>
        </td>
        <td style="width: calc(50%); text-align: left; padding-left: 10px">
          {{ file }}
        </td>
        <td>
          <span style="color: #372d69"
            >Последняя версия:
            <span style="color: red">{{ version }}</span></span
          >&nbsp;
          <!-- span style="color: #372d69"
            >Hash: {{ hash.substring(0, 10) }}... </span
          -->&nbsp;
          <span style="color: #372d69"
            >Дата: {{ new Date(date_modified).toLocaleString() }}</span
          >
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import axios from "axios";
import BootstrapIcon from "@dvuckovic/vue3-bootstrap-icons";
import FileVersions from "../components/FileVersions.vue";

export default {
  name: "FileItem",
  props: [
    "version",
    "file",
    "date_modified",
    "who_modified",
    "description",
    "deposit_id",
    "mine_area_id",
    "hash",
  ],
  data() {
    return {
      header: "Сохранение данных формы",
      errorMessage: "",
      showError: false,
      confirmMessage: "",
      confirmHeader: "",
      showConfirmModal: false,
      showAuthError: false,
      authMessage: "Доступ запрещен",
      authHeader: "Ошибка безопасности",
      showFileVersions: false,
    };
  },
  methods: {
    getFile() {
      this.showSpinner = true;
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_file/";
      axios
        .post(
          url,
          {
            deposit_id: this.deposit_id,
            mine_area_id: this.mine_area_id,
            version: this.version,
            file_name: this.file,
          },
          { headers, responseType: "blob" }
        )
        .then((response) => {
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(new Blob([response.data]));
          link.setAttribute("download", this.file);
          document.body.appendChild(link);
          link.click();
        });
    },
    getVersions() {
      this.showFileVersions = true;
    },
    closeFileVersion() {
      this.showFileVersions = false;
    },
  },
  components: {
    BootstrapIcon,
    FileVersions,
  },
  mounted() {},
};
</script>

<style scoped>
img {
  height: 30px;
  width: 30px;
}

.item {
  width: calc(100%);
  height: 50px;
  font-weight: bolder;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  border-style: none !important;
}
.delete-btn {
  height: 30px;
  display: inline;
  /*margin-right: 5px;*/
}
.save-btn {
  height: 30px;
  display: inline-block;
  /*margin-right: 5px;*/
}
.inputfield {
  display: inline;
  height: 30px;
  width: 330px;
}

.tip {
  position: relative;
  display: inline-block;
}

.tip .tiptext {
  visibility: hidden;
  width: 90%;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: -12px;
  left: 115%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tip .tiptext::after {
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%; /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #555 transparent transparent;
}

.tip:hover .tiptext {
  visibility: visible;
  opacity: 1;
}
</style>
