<template>
  <div class="body">
    <OkModal
      v-if="showError"
      v-bind:message="errorMessage"
      v-bind:header="header"
      v-on:confirm="hideErrorMessage"
    />
    <Spinner v-if="showSpinner" />
    <!-- inner menu for H04 -->
    <nav class="inner-nav">
      <ul class="inner-nav-tab">
        <li class="inner-nav-item">
          <a
            class="inner-nav-link"
            v-bind:class="selected_H04_inner1 ? 'active' : ''"
            @click="selectTab('inner-H04-1')"
            href="#"
            >Лист 1</a
          >
        </li>
        <li class="inner-nav-item">
          <a
            class="inner-nav-link"
            v-bind:class="selected_H04_inner2 ? 'active' : ''"
            @click="selectTab('inner-H04-2')"
            href="#"
            >Лист 2</a
          >
        </li>
        <li class="inner-nav-item">
          <a
            class="inner-nav-link"
            v-bind:class="selected_H04_inner3 ? 'active' : ''"
            @click="selectTab('inner-H04-3')"
            href="#"
            >Лист 3</a
          >
        </li>
      </ul>
      <!-- inner tabs to show -->
      <div class="tab-H04-inner1 inner" v-if="selected_H04_inner1">
        <div class="inner-body">
          <div class="caption-table">
            <table>
              <tr class="tab-data">
                <th style="width: 330px">Объект (название):</th>
                <td>
                  <input
                    type="text"
                    class="form-control form-control-md"
                    v-model="component"
                    placeholder="Полезное ископаемое"
                    disabled
                  />
                </td>
              </tr>
              <tr class="tab-data">
                <th>Полезное ископаемое:</th>
                <td style="width: 835px">
                  <input
                    type="text"
                    class="form-control form-control-md"
                    v-model="description"
                    placeholder="Месторождение"
                    disabled
                  />
                </td>
              </tr>
            </table>
          </div>
          <div class="title">
            4. ФИЗИКО-МЕХАНИЧЕСКИЕ И ТЕХНОЛОГИЧЕСКИЕ СВОЙСТВА ПОЛЕЗНОГО
            ИСКОПАЕМОГО
          </div>
          <form class="form-inline" style="width: calc(100%)">
            <table style="display: table">
              <thead>
                <tr class="tab-data">
                  <th rowspan="2" style="width: calc(60%)">Свойства</th>
                  <th rowspan="2" style="width: calc(20%)">
                    Единица измерения
                  </th>
                  <th colspan="3" style="width: calc(20%)">Величина</th>
                </tr>
              </thead>
              <tbody>
                <tr class="tab-data">
                  <th></th>
                  <th></th>
                  <th>от</th>
                  <th>до</th>
                  <th>средняя</th>
                </tr>
                <tr class="tab-data">
                  <td>01</td>
                  <td>02</td>
                  <td>03</td>
                  <td>04</td>
                  <td>05</td>
                </tr>
                <PhysicalPropertiesControlRecordRow
                  v-for="property in properties"
                  v-bind:key="property.id"
                  v-bind:deposit_id="deposit_id"
                  v-bind:mine_area_id="mine_area_id"
                  v-bind:component_id="component_id"
                  v-bind:property_id="property.id"
                  v-on:property-removed="onPropertyRemoved"
                  v-on:property-updated="onPropertyUpdated"
                />
                <tr>
                  <td>
                    <button
                      @click="addProperty"
                      class="btn btn-dark btn-md btn-block btn-add add-field"
                    >
                      <BootstrapIcon icon="plus-square-fill" />
                      Добавить
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
      </div>
      <div class="tab-H04-inner2 inner" v-if="selected_H04_inner2">
        <div class="inner-body">
          <div class="caption-table">
            <table>
              <tr class="tab-data">
                <th style="width: 350px">Объект (название):</th>
                <td>
                  <input
                    type="text"
                    class="form-control form-control-md"
                    v-model="component"
                    placeholder="Полезное ископаемое"
                    disabled
                  />
                </td>
              </tr>
              <tr class="tab-data">
                <th>Полезное ископаемое:</th>
                <td style="width: 845px">
                  <input
                    type="text"
                    class="form-control form-control-md"
                    v-model="description"
                    placeholder="Месторождение"
                    disabled
                  />
                </td>
              </tr>
            </table>
          </div>
          <form class="form-inline" style="width: calc(100%)">
            <div class="q-ed-container">
              <label for="q-editor">4.1. Прочие сведения</label>
              <QuillEditor
                :toolbar="[
                  ['bold', 'italic', 'underline', 'strike'],
                  ['blockquote', 'code-block'],
                  [{ list: 'ordered' }, { list: 'bullet' }],
                  [{ script: 'sub' }, { script: 'super' }],
                  [{ indent: '-1' }, { indent: '+1' }],
                  [{ direction: 'rtl' }],
                  [{ color: [] }, { background: [] }],
                  [{ align: [] }],
                  ['link', 'video', 'image'],
                  ['clean'],
                ]"
                theme="snow"
                style="height: 300px"
                id="q-editor"
                content-type="html"
                v-model:content="otherPropsInfo"
              />
            </div>
            <div class="q-ed-container">
              <label for="q-editor"
                >4.2. Результаты технологических и полузаводских
                испытаний</label
              >
              <QuillEditor
                :toolbar="[
                  ['bold', 'italic', 'underline', 'strike'],
                  ['blockquote', 'code-block'],
                  [{ list: 'ordered' }, { list: 'bullet' }],
                  [{ script: 'sub' }, { script: 'super' }],
                  [{ indent: '-1' }, { indent: '+1' }],
                  [{ direction: 'rtl' }],
                  [{ color: [] }, { background: [] }],
                  [{ align: [] }],
                  ['link', 'video', 'image'],
                  ['clean'],
                ]"
                theme="snow"
                style="height: 600px"
                id="q-editor"
                content-type="html"
                v-model:content="testResultsInfo"
              />
            </div>
            <button class="btn btn-dark btn-save" v-on:click="saveInfo">
              Сохранить
            </button>
          </form>
        </div>
      </div>
      <div class="tab-H04-inner3 inner" v-if="selected_H04_inner3">
        <div class="inner-body">
          <div class="caption-table">
            <table>
              <tr class="tab-data">
                <th style="width: 350px">Объект (название):</th>
                <td>
                  <input
                    type="text"
                    class="form-control form-control-md"
                    v-model="component"
                    placeholder="Полезное ископаемое"
                    disabled
                  />
                </td>
              </tr>
              <tr class="tab-data">
                <th>Полезное ископаемое:</th>
                <td style="width: 845px">
                  <input
                    type="text"
                    class="form-control form-control-md"
                    v-model="description"
                    placeholder="Месторождение"
                    disabled
                  />
                </td>
              </tr>
            </table>
          </div>
          <div class="title">4.3. Продукция, получаемая из сырья:</div>
          <form class="form-inline" style="width: calc(100%)">
            <table style="display: table">
              <thead>
                <tr class="tab-data" style="width: calc(100%)">
                  <th style="width: calc(60%)">Вид</th>
                  <th style="width: calc(40%)">Сорт или марка</th>
                </tr>
              </thead>
              <tbody>
                <ProductControlRecordRow
                  v-for="product in products"
                  v-bind:key="product.id"
                  v-bind:product_id="product.id"
                  v-on:product-removed="onProductRemoved"
                  v-on:product-updated="onProductUpdated"
                />
                <tr>
                  <td>
                    <button
                      @click="addProduct"
                      class="btn btn-dark btn-md btn-block btn-add add-field"
                    >
                      <BootstrapIcon icon="plus-square-fill" />
                      Добавить
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
          <form class="form-inline" style="width: calc(100%)">
            <div class="q-ed-container">
              <label for="q-editor"
                >4.4. Соответствие получаемой продукции требованиям: ГОСТ, ОСТ,
                ТУ, СНиП и др.
              </label>
              <QuillEditor
                :toolbar="[
                  ['bold', 'italic', 'underline', 'strike'],
                  ['blockquote', 'code-block'],
                  [{ list: 'ordered' }, { list: 'bullet' }],
                  [{ script: 'sub' }, { script: 'super' }],
                  [{ indent: '-1' }, { indent: '+1' }],
                  [{ direction: 'rtl' }],
                  [{ color: [] }, { background: [] }],
                  [{ align: [] }],
                  ['link', 'video', 'image'],
                  ['clean'],
                ]"
                theme="snow"
                style="height: 400px"
                id="q-editor"
                content-type="html"
                v-model:content="standardsInfo"
              />
            </div>
            <button class="btn btn-dark btn-save" v-on:click="saveInfo">
              Сохранить
            </button>
          </form>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import axios from "axios";
import OkModal from "../../components/OkModal.vue";
import Spinner from "../../components/Spinner.vue";
import PhysicalPropertiesControlRecordRow from "../form_components/PhysicalPropertiesControlRecordRow.vue";
import ProductControlRecordRow from "../form_components/ProductControlRecordRow.vue";
import BootstrapIcon from "@dvuckovic/vue3-bootstrap-icons";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

export default {
  name: "FormH04",
  components: {
    BootstrapIcon,
    Spinner,
    QuillEditor,
    PhysicalPropertiesControlRecordRow,
    ProductControlRecordRow,
    OkModal,
  },
  props: [
    "object_name",
    "mineral_resource",
    "deposit_id",
    "mine_area_id",
    "component_id",
  ],
  data() {
    return {
      description: "",
      component: "",
      properties: [],
      products: [],
      showSpinner: false,
      selected_H04_inner1: true,
      selected_H04_inner2: false,
      selected_H04_inner3: false,
      otherPropsInfo: "",
      testResultsInfo: "",
      standardsInfo: "",
      errorMessage: "",
      header: "",
      showError: false,
    };
  },
  methods: {
    hideErrorMessage() {
      this.showError = false;
    },
    selectTab(id) {
      this.selected_H04_inner1 = false;
      this.selected_H04_inner2 = false;
      this.selected_H04_inner3 = false;
      switch (id) {
        case "inner-H04-1":
          this.selected_H04_inner1 = true;
          break;
        case "inner-H04-2":
          this.selected_H04_inner2 = true;
          break;
        case "inner-H04-3":
          this.selected_H04_inner3 = true;
          break;
      }
    },
    getInfo() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_phis_characteristic/";
      axios
        .post(
          url,
          {
            deposit_id: this.deposit_id,
            mine_area_id: this.mine_area_id,
            component_id: this.component_id,
          },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.otherPropsInfo = response.data[0].result.other_properties;
            this.testResultsInfo = response.data[0].result.test_results;
            this.standardsInfo = response.data[0].result.standards;
            this.showSpinner = false;
          } else {
            this.header = "Ошибка безопасности";
            this.errorMessage = "Доступ запрещен";
            this.showError = true;
          }
        });
    },
    saveInfo(e) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/add_or_update_phis_characteristic/";
      axios
        .post(
          url,
          {
            deposit_id: this.deposit_id,
            mine_area_id: this.mine_area_id,
            component_id: this.component_id,
            other_properties: this.otherPropsInfo,
            test_results: this.testResultsInfo,
            standards: this.standardsInfo,
          },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.showError = true;
            this.header = "Сохранение данных";
            this.errorMessage = "Данные были сохранены";
          } else {
            this.header = "Ошибка безопасности";
            this.errorMessage = "Доступ запрещен";
            this.showError = true;
          }
        });
      e.preventDefault();
    },
    getProperties() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_phis_properties/";
      axios
        .post(
          url,
          {
            deposit_id: this.deposit_id,
            mine_area_id: this.mine_area_id,
            component_id: this.component_id,
          },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.properties = response.data[0].result;
            this.showSpinner = false;
          }
        });
    },
    addProperty(e) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/add_phis_property/";
      axios
        .post(
          url,
          {
            deposit_id: this.deposit_id,
            mine_area_id: this.mine_area_id,
            component_id: this.component_id,
          },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.getProperties();
          } else {
            this.header = "Ошибка безопасности";
            this.errorMessage = "Доступ запрещен";
            this.showError = true;
          }
        });
      e.preventDefault();
    },
    onPropertyRemoved() {
      this.getProperties();
    },
    onPropertyUpdated() {
      this.getProperties();
    },
    getProducts() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_products/";
      axios
        .post(
          url,
          {
            deposit_id: this.deposit_id,
            mine_area_id: this.mine_area_id,
            component_id: this.component_id,
          },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.products = response.data[0].result;
            this.showSpinner = false;
          }
        });
    },
    addProduct(e) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/add_product/";
      axios
        .post(
          url,
          {
            deposit_id: this.deposit_id,
            mine_area_id: this.mine_area_id,
            component_id: this.component_id,
          },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.getProducts();
          } else {
            this.header = "Ошибка безопасности";
            this.errorMessage = "Доступ запрещен";
            this.showError = true;
          }
        });
      e.preventDefault();
    },
    onProductRemoved() {
      this.getProducts();
    },
    onProductUpdated() {
      this.getProducts();
    },
  },
  mounted() {
    this.description = this.object_name;
    this.component = this.mineral_resource;
    this.getProperties();
    this.getProducts();
    this.getInfo();
    this.showSpinner = true;
  },
};
</script>
<style></style>
