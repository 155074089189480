<template>
  <div class="inner-body inner">
    <OkModal
      v-if="showError"
      v-bind:message="errorMessage"
      v-bind:header="header"
      v-on:confirm="hideErrorMessage"
    />
    <Spinner v-if="showSpinner" />
    <nav class="inner-nav">
      <ul class="inner-nav-tab">
        <li class="inner-nav-item">
          <a
            class="inner-nav-link"
            v-bind:class="selected_H03_inner1 ? 'active' : ''"
            @click="selectTab('inner-H03-1')"
            href="#"
            style="display: none"
            >#</a
          >
        </li>
      </ul>
    </nav>
    <div class="caption-table">
      <table>
        <tr class="tab-data">
          <th>Объект (название):</th>
          <td>
            <input
              type="text"
              class="form-control form-control-md"
              v-model="component"
              placeholder="Месторождение"
              disabled
            />
          </td>
        </tr>
        <tr class="tab-data">
          <th>Полезное ископаемое:</th>
          <td>
            <input
              type="text"
              class="form-control form-control-md"
              v-model="description"
              placeholder="Полезное ископаемое"
              disabled
            />
          </td>
        </tr>
      </table>
    </div>
    <div class="title" style="margin-bottom: 16px">
      7. СВЕДЕНИЯ О ЗАПАСАХ ПО СОСТОЯНИЮ НА {{ reportDate }} г.
    </div>

    <div style="display: flex; align-items: center; margin-bottom: 8px">
      <label style="width: 40%; justify-content: flex-start; margin-bottom: 0"
        >01. Организация, выполнившая подсчет запасов:</label
      >
      <div class="drpdwn-container" style="width: 60%">
        <!-- select
          class="form-select form-control-md"
          v-model="selecteOrgId"
          aria-label="Организация, выполнившая подсчет запасов"
        >
          <option
            v-for="o in organizations"
            v-bind:value="o.id"
            v-bind:key="o.id"
          >
            {{ o.description }}
          </option>
        </select -->
        <input
          type="text"
          style="width: 60%"
          class="form-control form-control-md"
          v-model="org"
        />
      </div>
    </div>
    <div style="display: flex; align-items: center; margin-bottom: 8px">
      <label style="width: 40%; justify-content: flex-start; margin-bottom: 0"
        >02. Основной метод подсчета запасов:</label
      >
      <!-- input
        type="text"
        style="width: 60%"
        class="form-control form-control-md"
        v-model="calculationType"
      / -->
      <select
        class="form-select form-control-md"
        v-model="calculationTypeId"
        style="width: 60%"
      >
        <option
          v-for="o in calc_methods"
          v-bind:value="o.id"
          v-bind:key="o.id"
        >
          {{ o.description }}
        </option>
      </select>
      <!-- div class="drpdwn-container" style="width: 60%">
        
        <select
          class="form-select form-control-md"
          v-model="selecteOreTypeId"
          aria-label="Породы, слагающие полезное ископаемое"
        >
          <option v-for="o in oreTypes" v-bind:value="o.id" v-bind:key="o.id">
            {{ o.description }}
          </option>
        </select>
      </div -->
    </div>
    <div style="display: flex; align-items: center; margin-bottom: 16px">
      <label style="width: 40%; justify-content: flex-start; margin-bottom: 0">
        03. Глубина подсчета, м:
      </label>
      <input
        type="text"
        style="width: 60%"
        class="form-control form-control-md"
        v-model="calculationDepth"
      />
    </div>

    <div style="overflow: auto">
      <table class="form-inline" style="display: table; width: 100%">
        <thead>
          <tr class="tab-data">
            <td rowspan="3">
              <p style="width: 290px">
                Месторождение, в том числе участки, слои (тела полезных
                ископаемых)
              </p>
            </td>
            <td rowspan="3"><p style="width: 290px">Полезное ископаемое</p></td>
            <td rowspan="3"><p style="width: 90px">Единица измерения</p></td>
            <td colspan="3">
              <p>Запасы (на начало периода {{ new Date().getFullYear() }})</p>
            </td>
            <td colspan="5">
              <p style="width: 500px">
                Балансовые запасы, утвержденные ГКЗ, ТКЗ
              </p>
            </td>
          </tr>
          <tr class="tab-data">
            <td colspan="2">
              <p style="width: 100%; text-align: center">Балансовые</p>
            </td>
            <td rowspan="2">
              <p style="width: 70px; line-break: anywhere">Забалансовые</p>
            </td>
            <td rowspan="2">
              <p style="width: 100%">Дата утверждения ГКЗ, ТКЗ и № протокола</p>
            </td>
            <td rowspan="2"><p style="width: 80px">Учет балансом</p></td>
            <td rowspan="2">
              <p style="width: 80px">А+В+С <sub>1</sub></p>
            </td>
            <td rowspan="2">
              <p style="width: 80px">C<sub>2</sub></p>
            </td>
            <td rowspan="2">
              <p style="width: 80px">Остаток А+В+С <sub>1</sub></p>
            </td>
          </tr>
          <tr class="tab-data">
            <td>
              <p style="width: 80px">А+В+С <sub>1</sub></p>
            </td>
            <td>
              <p style="width: 80px">C<sub>2</sub></p>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr class="tab-data">
            <td><p>04</p></td>
            <td><p>05</p></td>
            <td><p>06</p></td>
            <td><p>07</p></td>
            <td><p>08</p></td>
            <td><p>09</p></td>
            <td><p>010</p></td>
            <td><p>011</p></td>
            <td><p>012</p></td>
            <td><p>013</p></td>
            <td><p>014</p></td>
          </tr>
          <tr class="tab-data" v-for="row in report" v-bind:key="row.id">
            <td>
              <p>{{ row.deposit_name_or_org }}</p>
            </td>
            <td>
              <p>{{ row.component }}</p>
            </td>
            <td>
              <p>{{ row.measurement }}</p>
            </td>
            <td>
              <p>{{ row.start_A_B_C1 }}</p>
            </td>
            <td>
              <p>{{ row.start_C2 }}</p>
            </td>
            <td>
              <p>{{ row.start_out }}</p>
            </td>
            <td>
              <p>{{ row.org + " " + row.protocol + " " + row.year }}</p>
            </td>
            <td><p>Учтены</p></td>
            <td>
              <p>{{ row.initial_A_B_C1 }}</p>
            </td>
            <td>
              <p>{{ row.initial_C2 }}</p>
            </td>
            <td>
              <p>{{ row.end_A_B_C1 }}</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <button class="btn btn-dark btn-save" v-on:click="updateMovementAddInfo">
      Сохранить
    </button>
  </div>
</template>

<script>
import axios from "axios";
import Spinner from "../../components/Spinner.vue";
import OkModal from "../../components/OkModal.vue";
//import BootstrapIcon from "@dvuckovic/vue3-bootstrap-icons";

export default {
  name: "FormH07",
  components: {
    //BootstrapIcon,
    OkModal,
    Spinner,
  },
  props: [
    "object_name",
    "mineral_resource",
    "deposit_id",
    "mine_area_id",
    "component_id",
  ],
  data() {
    return {
      description: "",
      component: "",
      errorMessage: "",
      header: "",
      showError: false,
      organizations: [],
      calc_methods: [],
      selecteOrgId: null,
      org: "",
      report: [],
      calculationTypeId: null,
      calculationDepth: "",
      showSpinner: false,
      reportDate: "",
    };
  },
  mounted() {
    this.description = this.object_name;
    this.component = this.mineral_resource;
    this.getOrganizations();
    this.getMethods();
    this.getReport();
    this.showSpinner = true;
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    this.reportDate = dd + "." + mm + "." + yyyy;
  },
  methods: {
    hideErrorMessage() {
      this.showError = false;
    },
    getReport() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_movements_info/";
      axios
        .post(
          url,
          {
            deposit_id: this.deposit_id,
            mine_area_id: this.mine_area_id,
            component_id: this.component_id,
          },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.report = response.data[0].result;
            this.showSpinner = false;
          }
        });
    },
    getOrganizations() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_dictionary_contents/";
      axios
        .post(
          url,
          { category: "SPR_VED", limit: 10000, offset: 0 },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.organizations = response.data[0].result;
            this.getMovementAddInfo();
          }
        });
    },
    getMethods() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_dictionary_contents/";
      axios
        .post(
          url,
          { category: "SPR_METOD_PODSCHETA", limit: 10000, offset: 0 },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.calc_methods = response.data[0].result;
          }
        });
    },

    getMovementAddInfo() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_movement_add_info/";
      axios
        .post(
          url,
          {
            deposit_id: this.deposit_id,
            mine_area_id: this.mine_area_id,
            component_id: this.component_id,
          },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.org = response.data[0].result.org;
            this.calculationTypeId = response.data[0].result.calc_method_id;
            this.calculationDepth = response.data[0].result.depth;
          }
        });
    },
    updateMovementAddInfo(e) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/add_or_update_movement_add_info/";
      axios
        .post(
          url,
          {
            deposit_id: this.deposit_id,
            mine_area_id: this.mine_area_id,
            component_id: this.component_id,
            org: this.org,
            calc_method_id: this.calculationTypeId,
            depth: this.calculationDepth,
          },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.showError = true;
            this.header = "Сохранение данных";
            this.errorMessage = "Данные были сохранены";
          } else {
            this.header = "Ошибка безопасности"
            this.errorMessage = "Доступ запрещен"
            this.showError = true;
          }
        });
      e.preventDefault();
    },
  },
};
</script>

<style scoped>
p {
  margin-bottom: 0;
  padding: 0 4px;
}
</style>
