<template>
  <div class="modal-mask">
    <div class="modal-window">
      <div class="header">Версии файла {{ file }}</div>
      <div class="body">
        <table style="width: 100%" class="table table-hover">
          <thead>
            <tr>
              <th scope="col">Загрузить</th>
              <th scope="col">Файл</th>
              <th scope="col">Версия</th>
              <th scope="col">Hash</th>
              <th scope="col">Дата изменения</th>
              <th scope="col">Кто изменил</th>
            </tr>
          </thead>
          <tr v-for="item in files" v-bind:key="item.id" scope="row">
            <td style="width: 60px">
              <button
                @click="
                  getFile(item.file, item.version, deposit_id, mine_area_id)
                "
                class="btn btn-dark btn-lg btn-block btn-add"
                style="float: left; width: 55px"
                title="Скачать файл"
              >
                <BootstrapIcon icon="cloud-arrow-down" />
              </button>
            </td>
            <td
              style="
                width: calc(100%-60px);
                text-align: left;
                padding-left: 10px;
              "
            >
              {{ item.file }}
            </td>
            <td>
              <span style="color: #372d69">{{ item.version }}</span>
            </td>
            <td>
              <span style="color: #372d69"
                >{{ item.file_hash.substring(0, 10) }}...</span
              >
            </td>
            <td>
              <span style="color: #372d69"
                >{{ new Date(item.date_modified).toLocaleString() }}
              </span>
            </td>
            <td>
              <span style="color: #372d69">{{ item.who_modified }} </span>
            </td>
          </tr>
        </table>
        <div class="form-group" style="margin-top: 10px">
          <button @click="close" class="btn btn-dark btn-lg btn-block close">
            Закрыть
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import BootstrapIcon from "@dvuckovic/vue3-bootstrap-icons";

export default {
  name: "FileVersions",
  props: ["file", "deposit_id", "mine_area_id"],
  data() {
    return {
      header: "Сохранение данных формы",
      errorMessage: "",
      showError: false,
      confirmMessage: "",
      confirmHeader: "",
      showConfirmModal: false,
      showAuthError: false,
      authMessage: "Доступ запрещен",
      authHeader: "Ошибка безопасности",
      files: [],
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    getFileVersion() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_file_versions/";
      axios
        .post(
          url,
          {
            deposit_id: this.deposit_id,
            mine_area_id: this.mine_area_id,
            file: this.file,
          },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.files = response.data[0].result;
            console.log(JSON.stringify(this.files));
          }
          this.showSpinner = false;
        });
    },
    getFile(file, version, deposit_id, mine_area_id) {
      this.showSpinner = true;
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_file/";
      axios
        .post(
          url,
          {
            deposit_id: deposit_id,
            mine_area_id: mine_area_id,
            version: version,
            file_name: file,
          },
          { headers, responseType: "blob" }
        )
        .then((response) => {
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(new Blob([response.data]));
          link.setAttribute("download", this.file);
          document.body.appendChild(link);
          link.click();
        });
    },
  },
  components: {
    BootstrapIcon,
  },
  mounted() {
    this.getFileVersion();
    this.showSpinner = true;
  },
};
</script>

<style scoped>
img {
  height: 30px;
  width: 30px;
}

.item {
  width: calc(100%);
  height: 50px;
  font-weight: bolder;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  border-style: none !important;
}
.delete-btn {
  height: 30px;
  display: inline;
  /*margin-right: 5px;*/
}
.save-btn {
  height: 30px;
  display: inline-block;
  /*margin-right: 5px;*/
}
.inputfield {
  display: inline;
  height: 30px;
  width: 330px;
}

.modal-mask {
  position: fixed;
  display: flex;
  z-index: 9997;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}

.modal-window {
  border: rgb(169, 255, 202);
  background-color: white;
  position: fixed;
  width: 1040px;
  height: 600px;
  z-index: 101;
  top: 20%;
}

.body {
  font-weight: bolder;
  color: black;
  text-align: center;
}

.close {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.save {
  position: absolute;
  bottom: 10px;
  left: 10px;
}
</style>
