<template>
  <div class="inner-body inner">
    <OkModal
      v-if="showError"
      v-bind:message="errorMessage"
      v-bind:header="header"
      v-on:confirm="hideErrorMessage"
    />
    <Spinner v-if="showSpinner" />
    <nav class="inner-nav">
      <ul class="inner-nav-tab">
        <li class="inner-nav-item">
          <a
            class="inner-nav-link"
            v-bind:class="selected_H03_inner1 ? 'active' : ''"
            @click="selectTab('inner-H03-1')"
            href="#"
            style="display: none"
            >#</a
          >
        </li>
      </ul>
    </nav>
    <div class="caption-table">
      <table>
        <tr class="tab-data">
          <th>Объект (название):</th>
          <td>
            <input
              type="text"
              class="form-control form-control-md"
              v-model="component"
              placeholder="Месторождение"
              disabled
            />
          </td>
        </tr>
        <tr class="tab-data">
          <th>Полезное ископаемое:</th>
          <td>
            <input
              type="text"
              class="form-control form-control-md"
              v-model="description"
              placeholder="Полезное ископаемое"
              disabled
            />
          </td>
        </tr>
      </table>
    </div>
    <div class="title" style="text-align: center; margin-bottom: 14px">
      8. ГОРНОГЕОЛОГИЧКСКАЯ ХАРАКТЕРИСТИКА ГОРНЫХ ПОРОД И ГИДРОГЕОЛОГИЧЕСКИЕ
      УСЛОВИЯ РАЗРАБОТКИ
    </div>

    <div style="display: flex; align-items: center; margin-bottom: 10px">
      <label style="width: 40%; justify-content: flex-start"
        >01. Радиационная характеристика</label
      >
      <!-- input
        type="text"
        style="width: 60%"
        class="form-control form-control-md"
        v-model="lat_ang"
      / -->
    </div>

    <form class="form-inline" style="width: 100%; margin-bottom: 10px">
      <table style="display: table; width: 100%">
        <thead>
          <tr class="tab-data">
            <th style="width: 38%">Порода</th>
            <th style="width: 32%">Радиоактивность, мкр/ч</th>
            <th style="width: 20%">Класс по НРБ</th>
            <th style="width: 10%; border: none !important"></th>
          </tr>
        </thead>
        <tbody>
          <RadioActivityCharacteristicsControlRecordRow
            v-for="radioactivity in radioactivities"
            v-bind:key="radioactivity.id"
            v-bind:radioactivity_id="radioactivity.id"
            v-on:radioactivity-removed="onRadioactivityRemoved"
            v-on:radioactivity-updated="onRadioactivityUpdated"
          />
          <tr>
            <td>
              <button
                @click="addRadioactivity"
                class="btn btn-dark btn-md btn-block btn-add add-field"
              >
                <BootstrapIcon icon="plus-square-fill" />
                Добавить
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </form>

    <div style="display: flex; align-items: center; margin-bottom: 4px">
      <label style="width: 40%; justify-content: flex-start; margin-bottom: 0"
        >02. Коэффициент крепости по шкале <br />
        проф. М.М. Протодъяконова:</label
      >
      <!-- input
        type="text"
        style="width: 60%"
        class="form-control form-control-md"
        v-model="lat_ang"
      / -->
    </div>

    <div style="display: flex; align-items: center">
      <label style="width: 40%; justify-content: flex-start; padding-left: 14px"
        >- полезного ископаемого</label
      >
      <input
        type="number"
        style="width: 60%"
        class="form-control form-control-md"
        v-model="component_strength"
      />
    </div>
    <div style="display: flex; align-items: center">
      <label style="width: 40%; justify-content: flex-start; padding-left: 14px"
        >- прослоев пустых пород</label
      >
      <input
        type="number"
        style="width: 60%"
        class="form-control form-control-md"
        v-model="empty_body_strenght"
      />
    </div>
    <div style="display: flex; align-items: center">
      <label style="width: 40%; justify-content: flex-start; padding-left: 14px"
        >- вмещающих пород</label
      >
      <input
        type="number"
        style="width: 60%"
        class="form-control form-control-md"
        v-model="host_rock_strength"
      />
    </div>

    <label style="margin-bottom: 10px; justify-content: flex-start"
      >03. Коэффициент разрыхления:</label
    >
    <div style="display: flex; align-items: center">
      <label style="width: 40%; justify-content: flex-start; padding-left: 14px"
        >- полезного ископаемого</label
      >
      <input
        type="number"
        style="width: 60%"
        class="form-control form-control-md"
        v-model="component_looseness"
      />
    </div>
    <div style="display: flex; align-items: center">
      <label style="width: 40%; justify-content: flex-start; padding-left: 14px"
        >- прослоев пустых пород</label
      >
      <input
        type="number"
        style="width: 60%"
        class="form-control form-control-md"
        v-model="empty_body_looseness"
      />
    </div>
    <div style="display: flex; align-items: center">
      <label style="width: 40%; justify-content: flex-start; padding-left: 14px"
        >- вмещающих пород</label
      >
      <input
        type="number"
        style="width: 60%"
        class="form-control form-control-md"
        v-model="host_rock_looseness"
      />
    </div>

    <div style="display: flex; align-items: center">
      <label style="width: 40%; justify-content: flex-start"
        >04. Водоносные горизонты в контуре <br />
        запасов и глубина их залегания
      </label>
      <input
        type="text"
        style="width: 60%"
        class="form-control form-control-md"
        v-model="depth"
      />
    </div>
    <div style="display: flex; align-items: center">
      <label style="width: 40%; justify-content: flex-start"
        >05. Ожидаемый водоприток, л/сек
      </label>
      <input
        type="number"
        style="width: 60%"
        class="form-control form-control-md"
        v-model="expected_flow"
      />
    </div>
    <div style="display: flex; align-items: center">
      <label style="width: 40%; justify-content: flex-start"
        >06. Химический состав подземных вод
      </label>
      <input
        type="text"
        style="width: 60%"
        class="form-control form-control-md"
        v-model="chemical_compound"
      />
    </div>
    <div style="display: flex; align-items: center">
      <label style="width: 40%; justify-content: flex-start"
        >07. Минерализация вод, г/л
      </label>
      <input
        type="number"
        style="width: 60%"
        class="form-control form-control-md"
        v-model="mineralization"
      />
    </div>

    <button
      class="btn btn-dark btn-save"
      v-on:click="updateGeologicalCharacteristic"
    >
      Сохранить
    </button>
  </div>
</template>
<script>
import axios from "axios";
import OkModal from "../../components/OkModal.vue";
import Spinner from "../../components/Spinner.vue";
import RadioActivityCharacteristicsControlRecordRow from "../form_components/RadioActivityCharacteristicsControlRecordRow.vue";
import BootstrapIcon from "@dvuckovic/vue3-bootstrap-icons";

export default {
  name: "FormH08",
  components: {
    BootstrapIcon,
    RadioActivityCharacteristicsControlRecordRow,
    Spinner,
    OkModal,
  },
  props: [
    "object_name",
    "mineral_resource",
    "deposit_id",
    "mine_area_id",
    "component_id",
  ],
  data() {
    return {
      description: "",
      component: "",
      radioactivities: [],
      showSpinner: false,
      showError: false,
      header: "",
      errorMessage: "",
    };
  },
  methods: {
    hideErrorMessage() {
      this.showError = false;
    },
    getGeologicalCharacteristic() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_geological_characteristic/";
      axios
        .post(
          url,
          {
            deposit_id: this.deposit_id,
            mine_area_id: this.mine_area_id,
            component_id: this.component_id,
          },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.component_strength =
              response.data[0].result.component_strength;
            this.host_rock_strength =
              response.data[0].result.host_rock_strength;
            this.empty_body_strenght =
              response.data[0].result.empty_body_strenght;
            this.component_looseness =
              response.data[0].result.component_looseness;
            this.empty_body_looseness =
              response.data[0].result.empty_body_looseness;
            this.host_rock_looseness =
              response.data[0].result.host_rock_looseness;
            this.depth = response.data[0].result.depth;
            this.expected_flow = response.data[0].result.expected_flow;
            this.chemical_compound = response.data[0].result.chemical_compound;
            this.mineralization = response.data[0].result.mineralization;
            this.showSpinner = false;
          }
        });
    },
    updateGeologicalCharacteristic(e) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url =
        this.$BASE_URL + "/api/add_or_update_geological_characteristic/";
      axios
        .post(
          url,
          {
            deposit_id: this.deposit_id,
            mine_area_id: this.mine_area_id,
            component_id: this.component_id,
            component_strength: this.component_strength,
            host_rock_strength: this.host_rock_strength,
            empty_body_strenght: this.empty_body_strenght,
            component_looseness: this.component_looseness,
            empty_body_looseness: this.empty_body_looseness,
            host_rock_looseness: this.host_rock_looseness,
            depth: this.depth,
            expected_flow: this.expected_flow,
            chemical_compound: this.chemical_compound,
            mineralization: this.mineralization,
          },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.showError = true;
            this.header = "Сохранение данных";
            this.errorMessage = "Данные были сохранены";
          } else {
            this.header = "Ошибка безопасности"
            this.errorMessage = "Доступ запрещен"
            this.showError = true;
          }
        });
      e.preventDefault();
    },
    getRadioactivities() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_radioactivities/";
      axios
        .post(
          url,
          {
            deposit_id: this.deposit_id,
            mine_area_id: this.mine_area_id,
            component_id: this.component_id,
          },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.radioactivities = response.data[0].result;
          }
        });
    },
    addRadioactivity(e) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/add_radioactivity/";
      axios
        .post(
          url,
          {
            deposit_id: this.deposit_id,
            mine_area_id: this.mine_area_id,
            component_id: this.component_id,
          },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.getRadioactivities();
          } else {
            this.header = "Ошибка безопасности"
            this.errorMessage = "Доступ запрещен"
            this.showError = true;
          }
        });
      e.preventDefault();
    },
    onRadioactivityRemoved() {
      this.getRadioactivities();
    },
    onRadioactivityUpdated() {
      this.getRadioactivities();
    },
  },
  mounted() {
    this.description = this.object_name;
    this.component = this.mineral_resource;
    this.getRadioactivities();
    this.getGeologicalCharacteristic();
    this.showSpinner = true;
  },
};
</script>

<style scoped></style>
