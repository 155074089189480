<template>
  <div class="modal-mask">
    <div class="modal-window">
      <Spinner v-if="showSpinner" />
      <OkModal
        v-if="showError"
        v-bind:message="errorMessage"
        v-bind:header="header"
        v-on:confirm="hideErrorMessage"
      />
      <div class="header">Параметры для запуска кадастрового запроса</div>
      <div class="body">
        <form class="form-inline">
          <table style="border: none !important; width: 100%">
            <tr>
              <td>
                <div class="form-group col-xs-12">
                  <label>Месторождение</label>
                  <select
                    class="form-select form-control-lg"
                    v-model="selectedDeposit"
                    aria-label="Местрождение"
                    @change="onDepositChanged($event)"
                  >
                    <option
                      v-for="deposit in deposits"
                      v-bind:value="deposit.deposit_id"
                      v-bind:key="deposit.deposit_name"
                    >
                      {{ deposit.deposit_name }}
                    </option>
                  </select>
                </div>
              </td>
            </tr>
            <tr v-if="mineAreas.length > 0">
              <td>
                <div class="form-group col-xs-12">
                  <label>Участок</label>
                  <select
                    class="form-select form-control-lg"
                    v-model="selectedMineArea"
                    aria-label="Участок"
                    @change="onMineAreaChanged($event)"
                  >
                    <option
                      v-for="area in mineAreas"
                      v-bind:value="area.mine_area_id"
                      v-bind:key="area.mine_area_name"
                    >
                      {{ area.mine_area_name }}
                    </option>
                  </select>
                </div>
              </td>
            </tr>
            <tr v-if="comps.length > 0">
              <td>
                <div class="form-group col-xs-12">
                  <label>Полезные ископаемые</label>
                  <select
                    class="form-select form-control-lg"
                    v-model="selectedComponent"
                    aria-label="Полезные ископаемые"
                  >
                    <option
                      v-for="component in comps"
                      v-bind:value="component.component_id"
                      v-bind:key="component.component_name"
                    >
                      {{ component.component_name }}
                    </option>
                  </select>
                </div>
              </td>
            </tr>
          </table>
          <div class="form-group" style="margin-top: 10px">
            <button
              @click="htmlExport"
              class="btn btn-dark btn-lg btn-block excel"
            >
              Экспорт
            </button>
          </div>
          <!-- div class="form-group" style="margin-top: 10px">
            <button
              @click="htmlExport"
              class="btn btn-dark btn-lg btn-block html"
            >
              Экспорт в HTML
            </button>
          </div -->
          <div class="form-group" style="margin-top: 10px">
            <button @click="close" class="btn btn-dark btn-lg btn-block close">
              Отменить
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import OkModal from "../components/OkModal.vue";
import Spinner from "../components/Spinner.vue";

export default {
  name: "CadastreReportParamsModal",
  props: [],
  data() {
    return {
      selectedComponent: null,
      selectedDeposit: null,
      selectedMineArea: null,
      comps: [],
      usages: [],
      showCadastreReportParamsModal: false,
      showError: false,
      header: "Параметры для конструктора отчетов",
      errorMessage: "",
      failed: false,
      showSpinner: false,
      deposits: [],
      mineAreas: [],
    };
  },
  methods: {
    onMineAreaChanged() {
      this.selectComponents();
    },
    onDepositChanged(e) {
      this.showSpinner = true;
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      var url = this.$BASE_URL + "/api/get_mine_areas/";
      axios
        .post(
          url,
          { deposit_id: this.selectedDeposit, limit: 10000, offset: 0 },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            if (!response.data[0].result) {
              this.showError = true;
              this.header = "Ошбка при запросе";
              this.errorMessage = response.data[0].reason;
            } else {
              this.mineAreas = response.data[0].result;
              if (this.mineAreas.length == 0) {
                this.showSpinner = false;
                this.selectedMineArea = this.selectedDeposit;
                this.selectComponents();
              } else {
                this.showSpinner = false;
              }
            }
          }
        });
      e.preventDefault();
    },
    hideModal() {},
    hideErrorMessage() {
      this.showError = false;
      this.$emit("report-done");
    },
    close(e) {
      this.showCadastreReportParamsModal = false;
      this.$emit("report-cancel");
      e.preventDefault();
    },
    getDeposits() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      var url = this.$BASE_URL + "/api/get_deposits/";
      axios
        .post(url, { limit: 10000, offset: 0 }, { headers })
        .then((response) => {
          this.showSpinner = false;
          if (!response.data[0].auth_fail) {
            if (!response.data[0].result) {
              this.showError = true;
              this.errorMessage = response.data[0].reason;
            } else {
              this.deposits = response.data[0].result;
            }
          }
        });
    },
    getMineAreas() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      var url = this.$BASE_URL + "/api/get_mine_areas/";
      axios
        .post(
          url,
          { depsosit_id: this.selectedDeposit, limit: 10000, offset: 0 },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            if (!response.data[0].result) {
              this.showError = true;
              this.errorMessage = response.data.reason;
              this.showSpinner = false;
            } else {
              this.mineAreas = response.data[0].result;
              this.showSpinner = false;
            }
          }
        });
    },
    docxExport(e) {
      this.showSpinner = true;
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };

      var url = this.$BASE_URL + "/api/cadastre_report_docx/";

      axios
        .post(
          url,
          {
            component_id: this.selectedComponent,
            deposit_id: this.selectedDeposit,
            mine_area_id: this.selectedMineArea,
          },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            if (response.data[0].result) {
              var filename = response.data[0].result.filename;

              this.showSpinner = false;
              this.header = "Результаты выполнения запроса";
              this.showError = true;
              this.errorMessage = "Отчет готов";

              var url =
                this.$BASE_URL + "/api/download_docx_report/" + filename;

              axios
                .get(url, {
                  headers: headers,
                  responseType: "blob",
                })
                .then((response) => {
                  const link = document.createElement("a");
                  link.href = window.URL.createObjectURL(
                    new Blob([response.data])
                  );
                  link.setAttribute("download", "report.docx");
                  document.body.appendChild(link);
                  link.click();
                });
            } else {
              this.showSpinner = false;
              this.header = "Ошибка при выполнение запроса";
              this.showError = true;
              this.errorMessage = response.data[0].reason;
            }
          }
        });
      e.preventDefault();
    },
    htmlExport(e) {
      this.showSpinner = true;
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };

      var url = this.$BASE_URL + "/api/cadastre_report_html/";

      axios
        .post(
          url,
          {
            component_id: this.selectedComponent,
            deposit_id: this.selectedDeposit,
            mine_area_id: this.selectedMineArea,
          },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            if (response.data[0].result) {
              var filename = response.data[0].result.filename;

              this.showSpinner = false;
              this.header = "Результаты выполнения запроса";
              this.showError = true;
              this.errorMessage = "Отчет готов";

              var url =
                this.$BASE_URL + "/api/download_docx_report/" + filename;

              axios
                .get(url, {
                  headers: headers,
                  responseType: "blob",
                })
                .then((response) => {
                  const link = document.createElement("a");
                  link.href = window.URL.createObjectURL(
                    new Blob([response.data])
                  );
                  link.setAttribute("download", "report.pdf");
                  document.body.appendChild(link);
                  link.click();
                });
            } else {
              this.showSpinner = false;
              this.header = "Ошибка при выполнение запроса";
              this.showError = true;
              this.errorMessage = response.data[0].reason;
              this.showSpinner = false;
            }
          }
        });
      e.preventDefault();
    },
    selectComponents() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      var url = this.$BASE_URL + "/api/get_available_components_for_deposit/";
      axios
        .post(
          url,
          {
            deposit_id: this.selectedDeposit,
            mine_area_id: this.selectedMineArea,
          },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            if (!response.data[0].result) {
              this.showError = true;
              this.header = "Ошибка сервера";
              this.errorMessage = response.data[0].reason;
              this.showSpinner = false;
            } else {
              this.comps = response.data[0].result;
              this.showSpinner = false;
            }
          }
        });
    },
  },
  components: {
    OkModal,
    Spinner,
  },
  mounted() {
    this.showSpinner = true;
    this.getDeposits();
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 100009;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}

.modal-window {
  border: rgb(169, 255, 202);
  background-color: white;
  position: fixed;
  width: 400px;
  height: 400px;
  top: 50%;
  left: 50%;
  margin-top: -100px;
  margin-left: -200px;
  z-index: 1000092;
}

.header {
  height: 30px;
  width: 100%;
  background-color: rgb(143, 150, 150);
  text-align: center;
  font-weight: bold;
}

.message {
  font-weight: bolder;
  color: black;
  text-align: center;
}

.close {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.html {
  position: absolute;
  bottom: 10px;
  background-color: #372d69;
  border-color: #372d69;
  width: 200px;
  left: calc(200px);
}

td {
  margin-left: 100px !important;
}
table {
  border-spacing: 20px !important;
}
</style>
