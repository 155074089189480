<template>
  <div class="body">
    <Spinner v-if="showSpinner" />
    <OkModal
      v-if="showError"
      v-bind:message="errorMessage"
      v-bind:header="header"
      v-on:confirm="hideErrorMessage"
    />
    <!-- inner menu for H03 -->
    <nav class="inner-nav">
      <ul class="inner-nav-tab">
        <li class="inner-nav-item">
          <a
            class="inner-nav-link"
            v-bind:class="selected_H03_inner1 ? 'active' : ''"
            @click="selectTab('inner-H03-1')"
            href="#"
            style="display: none"
            >#</a
          >
        </li>
      </ul>
      <!-- inner tabs to show -->
      <div class="tab-H03-inner1 inner" v-if="true">
        <div class="inner-body">
          <div class="caption-table">
            <table>
              <tr class="tab-data">
                <th>Объект (название):</th>
                <td>
                  <input
                    type="text"
                    class="form-control form-control-md"
                    v-model="component"
                    placeholder="Полезное ископаемое"
                    disabled
                  />
                </td>
              </tr>
              <tr class="tab-data">
                <th>Полезное ископаемое:</th>
                <td>
                  <input
                    type="text"
                    class="form-control form-control-md"
                    v-model="description"
                    placeholder="Месторождение"
                    disabled
                  />
                </td>
              </tr>
            </table>
          </div>
          <div class="title">
            3. КАЧЕСТВЕННАЯ ХАРАКТЕРИСТИКА ПОЛЕЗНОГО ИСКОПАЕМОГО
          </div>
          <form class="form-inline" style="width: calc(100%)">
            <table style="display: table;">
              <thead>
                <tr class="tab-data">
                  <th rowspan="2" style="width: 368px">
                    Показатели (компоненты химического, минерального и
                    петрографического состава, размер фракций грансостава,
                    размер отверстий сит, остатки на ситах, просев через сита,
                    наименование продуктов рассева)
                  </th>
                  <th rowspan="2" style="width: 140px">Единица измерения</th>
                  <th colspan="3">Значения показателей</th>
                  <th style="width: 112px; border: none !important"></th>
                </tr>
              </thead>
              <tbody>
                <tr class="tab-data">
                  <th></th>
                  <th></th>
                  <th style="width: 90px;">от</th>
                  <th style="width: 90px;">до</th>
                  <th style="width: 90px;">среднее</th>
                  <th style="border: none !important"></th>
                </tr>
                <tr class="tab-data">
                  <td>01</td>
                  <td>02</td>
                  <td>03</td>
                  <td>04</td>
                  <td>05</td>
                  <td style="border: none !important"></td>
                </tr>
                <QualityControlRecordRow
                  v-for="quality in qualities"
                  v-bind:key="quality.id"
                  v-bind:characteristic_id="quality.id"
                  v-on:quality-removed="onQualityRemoved"
                  v-on:quality-updated="onQualityUpdated"
                />
                <tr>
                  <td>
                    <button
                      @click="addQuality"
                      class="btn btn-dark btn-md btn-block btn-add add-field"
                    >
                      <BootstrapIcon icon="plus-square-fill" />
                      Добавить
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import axios from "axios";
import Spinner from "../../components/Spinner.vue";
import QualityControlRecordRow from "../form_components/QualityControlRecordRow.vue";
import BootstrapIcon from "@dvuckovic/vue3-bootstrap-icons";
import OkModal from "../../components/OkModal.vue";

export default {
  name: "FormH03",
  components: {
    BootstrapIcon,
    QualityControlRecordRow,
    Spinner,
    OkModal,
  },
  props: [
    "object_name",
    "mineral_resource",
    "deposit_id",
    "mine_area_id",
    "component_id",
  ],
  data() {
    return {
      description: "",
      component: "",
      qualities: [],
      showSpinner: false,
      errorMessage: "",
      header: "",
      showError: false,
    };
  },
  methods: {
    hideErrorMessage() {
      this.showError = false;
    },
    getQualities() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_qualities/";
      axios
        .post(
          url,
          {
            deposit_id: this.deposit_id,
            mine_area_id: this.mine_area_id,
            component_id: this.component_id,
          },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.qualities = response.data[0].result;
            this.showSpinner = false;
          }
        });
    },
    addQuality(e) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/add_quality/";
      axios
        .post(
          url,
          {
            deposit_id: this.deposit_id,
            mine_area_id: this.mine_area_id,
            component_id: this.component_id,
          },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.getQualities();
          } else {
            this.header = "Ошибка безопасности"
            this.errorMessage = "Доступ запрещен"
            this.showError = true;
          }
        });
      e.preventDefault();
    },
    onQualityRemoved() {
      this.getQualities();
    },
    onQualityUpdated() {
      this.getQualities();
    },
  },
  mounted() {
    this.description = this.object_name;
    this.component = this.mineral_resource;
    this.getQualities();
    this.showSpinner = true;
  },
};
</script>
<style></style>
