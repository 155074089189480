<template>
  <div class="modal-mask">
    <div class="modal-window">
      <OkModal
        v-if="showError"
        v-bind:message="errorMessage"
        v-bind:header="header"
        v-on:confirm="hideErrorMessage"
      />
      <OkModal
        v-if="showAuthError"
        v-bind:message="authMessage"
        v-bind:header="authHeader"
        v-on:confirm="hideAuthMessage"
      />
      <div class="header">
        <div class="header-caption">
          <div class="form-group" id="xclose">
          <button @click="selectDeposit" class="btn btn-dark btn-xxs btn-block">
            <!-- BootstrapIcon icon="journal-x" size="1x" / -->
            <span class="align-middle">X</span>
          </button>
        </div>
        <span>Протокол для месторождения: {{ description }}</span>
        </div>
        <div class="nav-menu-container">
          <ul class="nav nav-tabs">
          <li class="nav-item">
            <a
              class="nav-link"
              v-bind:class="selected_title ? 'active' : ''"
              @click="selectTab('title')"
              href="#"
              >Титул</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              v-bind:class="selected_H01 ? 'active' : ''"
              @click="selectTab('H01')"
              href="#"
              >Форма H01</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              v-bind:class="selected_H02 ? 'active' : ''"
              @click="selectTab('H02')"
              href="#"
              >Форма H02</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              v-bind:class="selected_H03 ? 'active' : ''"
              @click="selectTab('H03')"
              href="#"
              >Форма H03</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              v-bind:class="selected_H04 ? 'active' : ''"
              @click="selectTab('H04')"
              href="#"
              >Форма H04</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              v-bind:class="selected_H05 ? 'active' : ''"
              @click="selectTab('H05')"
              href="#"
              >Форма H05</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              v-bind:class="selected_H06 ? 'active' : ''"
              @click="selectTab('H06')"
              href="#"
              >Форма H06</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              v-bind:class="selected_H07 ? 'active' : ''"
              @click="selectTab('H07')"
              href="#"
              >Форма H07</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              v-bind:class="selected_H08 ? 'active' : ''"
              @click="selectTab('H08')"
              href="#"
              >Форма H08</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              v-bind:class="selected_H0910 ? 'active' : ''"
              @click="selectTab('H0910')"
              href="#"
              >Форма H09 и H10</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              v-bind:class="selected_H11 ? 'active' : ''"
              @click="selectTab('H11')"
              href="#"
              >Форма H11</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              v-bind:class="selected_H12 ? 'active' : ''"
              @click="selectTab('H12')"
              href="#"
              >Форма H12</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              v-bind:class="selected_H13 ? 'active' : ''"
              @click="selectTab('H13')"
              href="#"
              >Форма H13</a
            >
          </li>
        </ul>
        </div>  
      </div>

      <div class="body">
        <!-- TITLE -->
        <div
          class="tab-title"
          v-if="selected_title"
          style="height: 400px; width: calc(80%); margin: auto"
        >
          <!-- title -->
          <div class="body">
            <!-- inner menu for TITLE -->
            <nav class="inner-nav">
              <ul class="inner-nav-tab">
                <li class="inner-nav-item">
                  <a
                    class="inner-nav-link"
                    v-bind:class="selected_TITLE_inner ? 'active' : ''"
                    @click="selectTab('inner-TITLE')"
                    href="#"
                    style="display: none"
                    >#</a
                  >
                </li>
              </ul>
              <!-- inner tabs to show -->
              <div class="tab-TITLE-inner inner" v-if="selected_TITLE_inner">
                <div class="inner-body">
                  <div class="title" style="padding: 0;">ТИТУЛЬНЫЙ ЛИСТ</div>
                  <form class="title-form">
                    <div id="title-form1">
                      <table>
                        <tr>
                          <td>
                            <div class="form-group col-xs-3">
                              <label>Месторождение</label>
                            </div>
                          </td>
                          <td>
                            <div class="form-group col-xs-3">
                              <input
                                type="text"
                                class="form-control form-control-md"
                                v-model="deposit"
                                disabled
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="form-group col-xs-3">
                              <label>Участок</label>
                            </div>
                          </td>
                          <td>
                            <div class="form-group col-xs-3">
                              <input
                                type="text"
                                class="form-control form-control-md"
                                v-model="mineArea"
                                disabled
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="form-group col-xs-3">
                              <label>Степень освоения</label>
                            </div>
                          </td>
                          <td>
                            <div class="form-group col-xs-3">
                              <input
                                type="text"
                                class="form-control form-control-md"
                                v-model="dev"
                                disabled
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="form-group col-xs-3">
                              <label>Полезное ископаемое</label>
                            </div>
                          </td>
                          <td>
                            <div class="form-group col-xs-3">
                              <input
                                type="text"
                                class="form-control form-control-md"
                                v-model="component"
                                disabled
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="form-group col-xs-3">
                              <label>Наименование раздела</label>
                            </div>
                          </td>
                          <td>
                            <div class="form-group col-xs-3">
                              <!-- input
                                type="text"
                                class="form-control form-control-md"
                                v-model="chapter"
                              / -->
                              <!-- input
                                type="text"
                                class="form-control form-control-md"
                                v-model="balance"
                              / -->
                              <select
                                class="form-select form-control-md"
                                v-model="selectedChapterId"
                                aria-label="Наименование раздела"
                              >
                                <option
                                  v-for="o in chapters"
                                  v-bind:value="o.id"
                                  v-bind:key="o.id"
                                >
                                  {{ o.description }}
                                </option>
                              </select>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="form-group col-xs-3">
                              <label>Госбаланса запасов</label>
                            </div>
                          </td>
                          <td>
                            <div class="form-group col-xs-3">
                              <!-- input
                                type="text"
                                class="form-control form-control-md"
                                v-model="balance"
                              / -->
                              <select
                                class="form-select form-control-md"
                                v-model="selectedBalanceId"
                                aria-label="Госбаланса запасов"
                              >
                                <option
                                  v-for="o in balances"
                                  v-bind:value="o.id"
                                  v-bind:key="o.id"
                                >
                                  {{ o.description }}
                                </option>
                              </select>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div id="title-form2">
                      <table>
                        <tr>
                          <td>
                            <div class="form-group col-xs-3">
                              <label>Составил(и)</label>
                            </div>
                          </td>
                          <td>
                            <div class="form-group col-xs-3">
                              <input
                                type="text"
                                class="form-control form-control-md"
                                v-model="prepared"
                              />
                            </div>
                          </td>

                          <td>
                            <div class="form-group col-xs-3">
                              <label>дата</label>
                            </div>
                          </td>
                          <td>
                            <div class="form-group col-xs-3">
                              <input
                                type="date"
                                class="form-control form-control-md"
                                v-model="datePrepared"
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="form-group col-xs-3">
                              <label>Утвердил</label>
                            </div>
                          </td>
                          <td>
                            <div class="form-group col-xs-3">
                              <input
                                type="text"
                                class="form-control form-control-md"
                                v-model="approved"
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-group col-xs-3">
                              <label>дата</label>
                            </div>
                          </td>
                          <td>
                            <div class="form-group col-xs-3">
                              <input
                                type="date"
                                class="form-control form-control-md"
                                v-model="dateConfirmed"
                              />
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div>
                    <button
                      class="btn btn-dark btn-save"
                      v-on:click="onSaveTitle"
                    >
                      Сохранить
                    </button>
                  </form>
                </div>
              </div>
            </nav>
          </div>
        </div>
        <!-- H01 -->
        <div
          class="tab-H01"
          v-if="selected_H01"
          style="height: 400px; width: calc(80%); margin: auto"
        >
          <form-h-01
            :mineral_resource="component"
            :object_name="description"
            :mine_area="mineArea"
            :diffs_list="diffs"
            :area_name="area"
            :district_name="district"
            :organizations_list="organizations"
            :relief_list="relief"
            :drought_score_list="drought_score"
            :avalanch_score_list="avalanch_score"
            :land_type_list="land_type"
            v-bind:deposit_id="deposit_id"
            v-bind:mine_area_id="mine_area_id"
            v-bind:component_id="component_id"
          ></form-h-01>
        </div>
        <!-- H02 -->
        <div
          class="tab-H02"
          v-if="selected_H02"
          style="height: 400px; width: calc(80%); margin: auto"
        >
          <form-h-02
            :mineral_resource="description"
            :object_name="component"
            v-bind:deposit_id="deposit_id"
            v-bind:mine_area_id="mine_area_id"
            v-bind:component_id="component_id"
          ></form-h-02>
        </div>
        <!-- H03 -->
        <div
          class="tab-H03"
          v-if="selected_H03"
          style="height: 400px; width: calc(80%); margin: auto"
        >
          <form-h-03
            :mineral_resource="description"
            :object_name="component"
            v-bind:mine_area_id="mine_area_id"
            v-bind:component_id="component_id"
            v-bind:deposit_id="deposit_id"
          ></form-h-03>
        </div>
        <!-- H04 -->
        <div
          class="tab-H04"
          v-if="selected_H04"
          style="height: 400px; width: calc(80%); margin: auto"
        >
          <form-h-04
            v-bind:mineral_resource="description"
            v-bind:object_name="component"
            v-bind:mine_area_id="mine_area_id"
            v-bind:component_id="component_id"
            v-bind:deposit_id="deposit_id"
          ></form-h-04>
        </div>
        <!-- H05 -->
        <div
          class="tab-H05"
          v-if="selected_H05"
          style="height: 400px; width: calc(80%); margin: auto"
        >
          <form-h-05
            :mineral_resource="description"
            :object_name="component"
            v-bind:deposit_id="deposit_id"
            v-bind:mine_area_id="mine_area_id"
            v-bind:component_id="component_id"
          ></form-h-05>
        </div>
        <!-- H06 -->
        <div
          class="tab-H06"
          v-if="selected_H06"
          style="height: 400px; width: calc(80%); margin: auto"
        >
          <form-h-06
            :mineral_resource="description"
            :object_name="component"
            v-bind:deposit_id="deposit_id"
            v-bind:mine_area_id="mine_area_id"
            v-bind:component_id="component_id"
          ></form-h-06>
        </div>
        <!-- H07 -->
        <div
          class="tab-H07"
          v-if="selected_H07"
          style="height: 400px; width: calc(80%); margin: auto"
        >
          <form-h-07
            :mineral_resource="description"
            :object_name="component"
            v-bind:deposit_id="deposit_id"
            v-bind:mine_area_id="mine_area_id"
            v-bind:component_id="component_id"
          ></form-h-07>
        </div>
        <!-- H08 -->
        <div
          class="tab-H08"
          v-if="selected_H08"
          style="height: 400px; width: calc(80%); margin: auto"
        >
          <form-h-08
            :mineral_resource="description"
            :object_name="component"
            v-bind:deposit_id="deposit_id"
            v-bind:mine_area_id="mine_area_id"
            v-bind:component_id="component_id"
          ></form-h-08>
        </div>
        <!-- H09-H10 -->
        <div
          class="tab-H0910"
          v-if="selected_H0910"
          style="height: 400px; width: calc(80%); margin: auto"
        >
          <form-h-09-h-010
            :mineral_resource="description"
            :object_name="component"
            v-bind:deposit_id="deposit_id"
            v-bind:mine_area_id="mine_area_id"
            v-bind:component_id="component_id"
          ></form-h-09-h-010>
        </div>
        <!-- H11 -->
        <div
          class="tab-H11"
          v-if="selected_H11"
          style="height: 400px; width: calc(80%); margin: auto"
        >
          <form-h-011
            :mineral_resource="description"
            :object_name="component"
            v-bind:deposit_id="deposit_id"
            v-bind:mine_area_id="mine_area_id"
            v-bind:component_id="component_id"
          ></form-h-011>
        </div>
        <!-- H12 -->
        <div
          class="tab-H12"
          v-if="selected_H12"
          style="height: 400px; width: calc(80%); margin: auto"
        >
          <form-h-012
            :mineral_resource="description"
            :object_name="component"
            v-bind:deposit_id="deposit_id"
            v-bind:mine_area_id="mine_area_id"
            v-bind:component_id="component_id"
          ></form-h-012>
        </div>
        <!-- H13 -->
        <div
          class="tab-H13"
          v-if="selected_H13"
          style="height: 400px; width: calc(80%); margin: auto"
        >
          <form-h-013
            :mineral_resource="description"
            :object_name="component"
            v-bind:deposit_id="deposit_id"
            v-bind:mine_area_id="mine_area_id"
            v-bind:component_id="component_id"
          ></form-h-013>
        </div>

        <!-- div class="form-group">
          <button @click="saveForm" class="btn btn-dark btn-md btn-block save">
            Сохранить
          </button>
        </div>
        <div class="form-group">
          <button
            @click="selectDeposit"
            class="btn btn-dark btn-md btn-block select"
          >
            Выбраить месторождение
          </button>
        </div -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import OkModal from "../components/OkModal.vue";
import FormH01 from "./form/FormH01.vue";
import FormH02 from "./form/FormH02.vue";
import FormH03 from "./form/FormH03.vue";
import FormH04 from "./form/FormH04.vue";
import FormH05 from "./form/FormH05.vue";
import FormH06 from "./form/FormH06.vue";
import FormH07 from "./form/FormH07.vue";
import FormH08 from "./form/FormH08.vue";
import FormH09H010 from "./form/FormH09H010.vue";
import FormH011 from "./form/FormH011.vue";
import FormH012 from "./form/FormH012.vue";
import FormH013 from "./form/FormH013.vue";

export default {
  name: "ProtocolEditModal",
  props: ["deposit_id", "mine_area_id", "component_id"],
  data() {
    return {
      description: "",
      selected_H01: false,
      selected_H02: false,
      selected_H03: false,
      selected_H04: false,
      selected_H05: false,
      selected_H06: false,
      selected_H07: false,
      selected_H08: false,
      selected_H0910: false,
      selected_H11: false,
      selected_H12: false,
      selected_H13: false,
      selected_title: true,
      showAuthError: false,
      content: "<h1>Initial Content</h1>",
      showSpinner: false,
      showError: false,
      depositInfo: {},
      mineAreaInfo: {},
      dev: "",
      depositDesc: this.description,
      district: "",
      area: "",
      selected_TITLE_inner: true,
      selected_H01_inner1: true,
      selected_H01_inner2: false,
      selected_H01_inner3: false,
      selected_H01_inner4: false,
      selected_H01_inner5: false,
      selected_H01_inner6: false,
      selected_H03_inner1: true,
      qualities: [],
      balances: [],
      chapters: [],
      chapter: "",
      balance: "",
      prepared: "",
      datePrepared: null,
      approved: "",
      dateConfirmed: null,
      selectedBalanceId: null,
      selectedChapeter: null
    };
  },
  methods: {
    saveForm() {
      // Save current form
    },
    hideAuthMessage() {
      this.showAuthError = false;
    },
    selectTab(tab) {
      if (tab == "title") {
        this.selected_title = true;
        this.selected_H01 = false;
        this.selected_H02 = false;
        this.selected_H03 = false;
        this.selected_H04 = false;
        this.selected_H05 = false;
        this.selected_H06 = false;
        this.selected_H07 = false;
        this.selected_H08 = false;
        this.selected_H0910 = false;
        this.selected_H11 = false;
        this.selected_H12 = false;
        this.selected_H13 = false;
      }

      if (tab == "H01") {
        this.selected_title = false;
        this.selected_H01 = true;
        this.selected_H02 = false;
        this.selected_H03 = false;
        this.selected_H04 = false;
        this.selected_H05 = false;
        this.selected_H06 = false;
        this.selected_H07 = false;
        this.selected_H08 = false;
        this.selected_H0910 = false;
        this.selected_H11 = false;
        this.selected_H12 = false;
        this.selected_H13 = false;
      }
      if (tab == "H02") {
        this.selected_title = false;
        this.selected_H01 = false;
        this.selected_H02 = true;
        this.selected_H03 = false;
        this.selected_H04 = false;
        this.selected_H05 = false;
        this.selected_H06 = false;
        this.selected_H07 = false;
        this.selected_H08 = false;
        this.selected_H0910 = false;
        this.selected_H11 = false;
        this.selected_H12 = false;
        this.selected_H13 = false;
      }
      if (tab == "H03") {
        this.selected_title = false;
        this.selected_H01 = false;
        this.selected_H02 = false;
        this.selected_H03 = true;
        this.selected_H04 = false;
        this.selected_H05 = false;
        this.selected_H06 = false;
        this.selected_H07 = false;
        this.selected_H08 = false;
        this.selected_H0910 = false;
        this.selected_H11 = false;
        this.selected_H12 = false;
        this.selected_H13 = false;
      }
      if (tab == "H04") {
        this.selected_title = false;
        this.selected_H01 = false;
        this.selected_H02 = false;
        this.selected_H03 = false;
        this.selected_H04 = true;
        this.selected_H05 = false;
        this.selected_H06 = false;
        this.selected_H07 = false;
        this.selected_H08 = false;
        this.selected_H0910 = false;
        this.selected_H11 = false;
        this.selected_H12 = false;
        this.selected_H13 = false;
      }
      if (tab == "H05") {
        this.selected_title = false;
        this.selected_H01 = false;
        this.selected_H02 = false;
        this.selected_H03 = false;
        this.selected_H04 = false;
        this.selected_H05 = true;
        this.selected_H06 = false;
        this.selected_H07 = false;
        this.selected_H08 = false;
        this.selected_H0910 = false;
        this.selected_H11 = false;
        this.selected_H12 = false;
        this.selected_H13 = false;
      }
      if (tab == "H06") {
        this.selected_title = false;
        this.selected_H01 = false;
        this.selected_H02 = false;
        this.selected_H03 = false;
        this.selected_H04 = false;
        this.selected_H05 = false;
        this.selected_H06 = true;
        this.selected_H07 = false;
        this.selected_H08 = false;
        this.selected_H0910 = false;
        this.selected_H11 = false;
        this.selected_H12 = false;
        this.selected_H13 = false;
      }

      if (tab == "H07") {
        this.selected_title = false;
        this.selected_H01 = false;
        this.selected_H02 = false;
        this.selected_H03 = false;
        this.selected_H04 = false;
        this.selected_H05 = false;
        this.selected_H06 = false;
        this.selected_H07 = true;
        this.selected_H08 = false;
        this.selected_H0910 = false;
        this.selected_H11 = false;
        this.selected_H12 = false;
        this.selected_H13 = false;
      }

      if (tab == "H08") {
        this.selected_title = false;
        this.selected_H01 = false;
        this.selected_H02 = false;
        this.selected_H03 = false;
        this.selected_H04 = false;
        this.selected_H05 = false;
        this.selected_H06 = false;
        this.selected_H07 = false;
        this.selected_H08 = true;
        this.selected_H0910 = false;
        this.selected_H11 = false;
        this.selected_H12 = false;
        this.selected_H13 = false;
      }

      if (tab == "H0910") {
        this.selected_title = false;
        this.selected_H01 = false;
        this.selected_H02 = false;
        this.selected_H03 = false;
        this.selected_H04 = false;
        this.selected_H05 = false;
        this.selected_H06 = false;
        this.selected_H07 = false;
        this.selected_H08 = false;
        this.selected_H0910 = true;
        this.selected_H11 = false;
        this.selected_H12 = false;
        this.selected_H13 = false;
      }

      if (tab == "H11") {
        this.selected_title = false;
        this.selected_H01 = false;
        this.selected_H02 = false;
        this.selected_H03 = false;
        this.selected_H04 = false;
        this.selected_H05 = false;
        this.selected_H06 = false;
        this.selected_H07 = false;
        this.selected_H08 = false;
        this.selected_H0910 = false;
        this.selected_H11 = true;
        this.selected_H12 = false;
        this.selected_H13 = false;
      }

      if (tab == "H12") {
        this.selected_title = false;
        this.selected_H01 = false;
        this.selected_H02 = false;
        this.selected_H03 = false;
        this.selected_H04 = false;
        this.selected_H05 = false;
        this.selected_H06 = false;
        this.selected_H07 = false;
        this.selected_H08 = false;
        this.selected_H0910 = false;
        this.selected_H11 = false;
        this.selected_H12 = true;
        this.selected_H13 = false;
      }

      if (tab == "H13") {
        this.selected_title = false;
        this.selected_H01 = false;
        this.selected_H02 = false;
        this.selected_H03 = false;
        this.selected_H04 = false;
        this.selected_H05 = false;
        this.selected_H06 = false;
        this.selected_H07 = false;
        this.selected_H08 = false;
        this.selected_H0910 = false;
        this.selected_H11 = false;
        this.selected_H12 = false;
        this.selected_H13 = true;
      }

      if (tab == "inner-H01-1") {
        this.selected_H01_inner1 = true;
        this.selected_H01_inner2 = false;
        this.selected_H01_inner3 = false;
        this.selected_H01_inner4 = false;
        this.selected_H01_inner5 = false;
        this.selected_H01_inner6 = false;
      }

      if (tab == "inner-H01-2") {
        this.selected_H01_inner1 = false;
        this.selected_H01_inner2 = true;
        this.selected_H01_inner3 = false;
        this.selected_H01_inner4 = false;
        this.selected_H01_inner5 = false;
        this.selected_H01_inner6 = false;
      }

      if (tab == "inner-H01-3") {
        this.selected_H01_inner1 = false;
        this.selected_H01_inner2 = false;
        this.selected_H01_inner3 = true;
        this.selected_H01_inner4 = false;
        this.selected_H01_inner5 = false;
        this.selected_H01_inner6 = false;
      }

      if (tab == "inner-H01-4") {
        this.selected_H01_inner1 = false;
        this.selected_H01_inner2 = false;
        this.selected_H01_inner3 = false;
        this.selected_H01_inner4 = true;
        this.selected_H01_inner5 = false;
        this.selected_H01_inner6 = false;
      }

      if (tab == "inner-H01-5") {
        this.selected_H01_inner1 = false;
        this.selected_H01_inner2 = false;
        this.selected_H01_inner3 = false;
        this.selected_H01_inner4 = false;
        this.selected_H01_inner5 = true;
        this.selected_H01_inner6 = false;
      }

      if (tab == "inner-H01-6") {
        this.selected_H01_inner1 = false;
        this.selected_H01_inner2 = false;
        this.selected_H01_inner3 = false;
        this.selected_H01_inner4 = false;
        this.selected_H01_inner5 = false;
        this.selected_H01_inner6 = true;
      }

      if (tab == "inner-H04-1") {
        this.selected_H04_inner1 = true;
        this.selected_H04_inner2 = false;
        this.selected_H04_inner3 = false;
      }

      if (tab == "inner-H04-2") {
        this.selected_H04_inner1 = false;
        this.selected_H04_inner2 = true;
        this.selected_H04_inner3 = false;
      }

      if (tab == "inner-H04-3") {
        this.selected_H04_inner1 = false;
        this.selected_H04_inner2 = false;
        this.selected_H04_inner3 = true;
      }
    },
    selectDeposit() {
      this.$emit("reselect", {});
    },
    getTitle() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_title/";
      axios
        .post(
          url,
          {
            deposit_id: this.deposit_id,
            mine_area_id: this.mine_area_id,
            component_id: this.component_id,
          },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            var data = response.data[0].result;
            if (data == false) return;
            this.selectedChapeter = data.chapter_id;
            this.selectedBalanceId = data.balance_id;
            this.prepared = data.prepared;
            this.datePrepared = data.datePrepared;
            this.approved = data.approved;
            this.dateConfirmed = data.dateConfirmed;
          }
        });
    },
    onSaveTitle(e) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/add_or_update_title/";
      axios
        .post(
          url,
          {
            deposit_id: this.deposit_id,
            mine_area_id: this.mine_area_id,
            component_id: this.component_id,
            chapter_id: this.selectedChapterId,
            balance_id: this.selectedBalanceId,
            prepared: this.prepared,
            datePrepared: this.datePrepared,
            approved: this.approved,
            dateConfirmed: this.dateConfirmed,
          },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.showError = true;
            this.errorHeader = "Сохранение данных";
            this.errorMessage = "Данные были сохранены!";
          } else {
            this.errorHeader = "Ошибка безопасности"
            this.errorMessage = "Доступ запрещен"
            this.showError = true;
          }
        });
      e.preventDefault();
    },

    getArea(id) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_area/";
      axios.post(url, { id_area: id }, { headers }).then((response) => {
        if (!response.data[0].auth_fail) {
          this.area = response.data[0].result.area_name;
        }
      });
    },
    getDistrict(id_area, id_district) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_district/";
      axios
        .post(url, { id_area: id_area, id_district: id_district }, { headers })
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.district = response.data[0].result.district_name;
          }
        });
    },
    getComponent() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_dictionary_item_description/";
      axios
        .post(url, { id: this.component_id }, { headers })
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.component = response.data[0].result.description;
          }
        });
    },
    getLandTypes() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_dictionary_contents/";
      axios
        .post(
          url,
          { category: "SPR_TIP_ZEMEL", limit: 10000, offset: 0 },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.land_type = response.data[0].result;
          }
        });
    },
    getReliefType() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_dictionary_contents/";
      axios
        .post(
          url,
          { category: "SPR_TIP_RELEFA", limit: 10000, offset: 0 },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.relief = response.data[0].result;
          }
        });
    },
    getAvalanchType() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_dictionary_contents/";
      axios
        .post(
          url,
          { category: "SPR_OPOLZNEOPASNOST", limit: 10000, offset: 0 },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.avalanch_score = response.data[0].result;
          }
        });
    },
    getBalances() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_dictionary_contents/";
      axios
        .post(
          url,
          { category: "SPR_RAZDEL_GOS_BALANSA", limit: 10000, offset: 0 },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {            
            this.balances = response.data[0].result;
          }
        });
    },
    getChapters() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_dictionary_contents/";
      axios
        .post(
          url,
          { category: "SPR_RAZDEL", limit: 10000, offset: 0 },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {            
            this.chapters = response.data[0].result;
          }
        });
    },
    getMudflowType() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_dictionary_contents/";
      axios
        .post(
          url,
          { category: "SPR_SELEOPASNOST", limit: 10000, offset: 0 },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.drought_score = response.data[0].result;
          }
        });
    },
    getOrganizations() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_dictionary_contents/";
      axios
        .post(
          url,
          { category: "SPR_VED", limit: 10000, offset: 0 },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.organizations = response.data[0].result;
          }
        });
    },
    getDevStatus(id) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_dictionary_item_description/";
      axios.post(url, { id: id }, { headers }).then((response) => {
        if (!response.data[0].auth_fail) {
          this.dev = response.data[0].result.description;
        }
      });
    },
    getDepositInfo() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_deposit/";
      axios
        .post(url, { deposit_id: this.deposit_id }, { headers })
        .then((response) => {
          if (!response.data[0].auth_fail) {
            //alert(JSON.stringify(response.data[0]))
            if (this.deposit_id == this.mine_area_id) {
              this.description = response.data[0].result.deposit_name;
              this.deposit = response.data[0].result.deposit_name;
              this.mineArea = "-";
              this.depositInfo = response.data[0].result;
              this.getDevStatus(this.depositInfo.dev_id);
              this.getArea(this.depositInfo.area_id);
              this.getDistrict(
                this.depositInfo.area_id,
                this.depositInfo.district_id
              );
              this.saveOrUpdateProtocol();
            } else {
              this.description = response.data[0].result.deposit_name;
              this.deposit = response.data[0].result.deposit_name;
              this.depositInfo = response.data[0].result;
              this.getMineAreaInfo();
            }
          }
        });
    },
    getMineAreaInfo() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_mine_area/";
      axios
        .post(
          url,
          { deposit_id: this.deposit_id, mine_area_id: this.mine_area_id },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            //alert(JSON.stringify(response.data[0].result))
            this.description += ", " + response.data[0].result.mine_area_name;
            this.mineArea = response.data[0].result.mine_area_name;
            this.mineAreaInfo = response.data[0].result;
            this.getDevStatus(this.mineAreaInfo.dev_id);
            this.getDistrict(
              this.mineAreaInfo.area_id,
              this.mineAreaInfo.district_id
            );
            //this.saveOrUpdateProtocol();
          }
        });
    },
    /*saveOrUpdateProtocol() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/create_or_ignore_general_info/";
      axios
        .post(
          url,
          {
            deposit_id: this.deposit_id,
            mine_area_id: this.mine_area_id,
            component_id: this.component_id,
          },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            console.log(response.data[0].auth_fail);
          }
        });
    },*/
    hideErrorMessage() {
      this.showError = false;
    },
  },
  mounted() {
    this.getDepositInfo();
    this.getComponent();
    this.getTitle();
    this.getBalances();
    this.getChapters();
  },
  components: {
    OkModal,
    FormH01,
    FormH02,
    FormH03,
    FormH04,
    FormH05,
    FormH06,
    FormH07,
    FormH08,
    FormH09H010,
    FormH011,
    FormH012,
    FormH013,
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}

.modal-window {
  border: rgb(169, 255, 202);
  background-color: white;
  position: fixed;
  width: calc(100%);
  height: calc(100%);
  z-index: 100;
  overflow: auto;
}

.header {
  height: 30px;
  width: 100%;
  background-color: rgb(150, 143, 146);
  text-align: center;
  font-weight: bold;
  position: fixed;
  z-index: 1;
}

.body, .nav-menu-container {
  /*font-weight: bolder;*/
  color: black;
  text-align: left;
  padding: 0;
  margin-top: 22px;
  font-family: "Times New Roman", Times, serif;
}

.table > :not(caption) > * > * {
  padding: 0;
}

table {
  padding: 0px !important;
}

.btn-xxs {
  height: 25px;
  width: 25px;
  background-color: rgb(204, 204, 204);
}

.close {
  /*position: relative;*/
  background: none;
  top: 2px;
  display: inline-block;
  height: 25px !important;
  padding-right: 10px;
}

.save {
  /*position: relative;*/
  /*top: 10px;*/
  float: right;
  display: inline-block;
  margin-right: 5px;
  margin-left: 5px;
  height: 25px;
}

.select {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.nav-link {
  color: #42b883;
}

div {
  padding-bottom: 30px;
  font-weight: bold;
}

label {
  font-weight: bolder;
}

.align-middle {
  display: inline-flex;
  align-items: center;
}

.btn {
  display: inline-flex;
  justify-content: center;
  /* center the content horizontally */
  align-items: center;
  /* center the content vertically */
  --padding-x: 1.2em;
  border-color: transparent;
  /* hide button border */
}

.nav-link {
  /*color: #42b883;*/
  color: rgb(143, 150, 150);
}

.nav-menu-container {
    background: #fff;
    display: block;
    position: fixed;
    width: 100%;
    z-index: 1;
    top: 8px;
    padding-top: 3px !important;
   
}
</style>
