<template>
  <tr class="tab-data">
    <td>
      <input
        type="number"
        class="form-control form-control-md"
        v-model="authorNumber"
        v-bind:disabled="!enableEditing"
      />
    </td>
    <td>
      <input
        type="text"
        class="form-control form-control-md"
        v-model="author"
        v-bind:disabled="!enableEditing"
      />
    </td>
    <td>
      <input
        type="text"
        class="form-control form-control-md"
        v-model="documentName"
        v-bind:disabled="!enableEditing"
      />
    </td>
    <td style="width: 113px; border: none !important">
      <button
        class="btn btn-dark btn-md btn-block btn-edit"
        v-on:click="updateRecord"
        style="float: left"
      >
        <span v-if="!enableEditing"><BootstrapIcon icon="pencil" /></span>
        <span v-if="enableEditing"><BootstrapIcon icon="check2" /></span>
      </button>
      <button
        class="btn btn-dark btn-md btn-block btn-delete"
        v-on:click="removeRecord"
        style="float: left"
      >
        <BootstrapIcon icon="trash" />
      </button>
      <OkModal
        v-if="showError"
        v-bind:message="errorMessage"
        v-bind:header="header"
        v-on:confirm="hideErrorMessage"
      />
    </td>
  </tr>
</template>

<script>
import axios from "axios";

import BootstrapIcon from "@dvuckovic/vue3-bootstrap-icons";
import OkModal from "../../components/OkModal.vue";

export default {
  name: "AuthorsControlRecordRow",
  components: {
    BootstrapIcon,
    OkModal
  },
  props: ["deposit_id", "mine_area_id", "component_id", "author_doc_id"],
  data() {
    return {
      author: "",
      authorNumber: "",
      documentName: "",
      enableEditing: false,
      showSpinner: false,
      errorMessage: "",
      header: "",
      showError: false,
    };
  },
  methods: {
    hideErrorMessage() {
      this.showError = false;
    },
    updateRecord(e) {
      if (this.enableEditing) {
        this.enableEditing = false;
        const token = sessionStorage.getItem("token");
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        };
        const data = {
          author_doc_id: this.author_doc_id,
          author: this.author,
          author_number: this.authorNumber,
          document_name: this.documentName,
        };
        const url = this.$BASE_URL + "/api/update_author_doc/";
        axios.post(url, data, { headers }).then((response) => {
          if (!response.data[0].auth_fail) {
            this.$emit("author-doc-updated");
          } else {
            this.header = "Ошибка безопасности"
            this.errorMessage = "Доступ запрещен"
            this.showError = true;
          }
        });
      } else {
        this.enableEditing = true;
      }
      e.preventDefault();
    },
    getAuthorDoc() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const data = {
        author_doc_id: this.author_doc_id,
      };
      const url = this.$BASE_URL + "/api/get_author_doc/";
      axios.post(url, data, { headers }).then((response) => {
        if (!response.data[0].auth_fail) {
          this.author = response.data[0].result.author;
          this.authorNumber = response.data[0].result.author_number;
          this.documentName = response.data[0].result.document_name;
        }
      });
    },
    removeRecord(e) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const data = {
        author_doc_id: this.author_doc_id,
      };
      const url = this.$BASE_URL + "/api/delete_author_doc/";
      axios.post(url, data, { headers }).then((response) => {
        if (!response.data[0].auth_fail) {
          this.$emit("author-doc-removed");
        } else {
          this.header = "Ошибка безопасности"
          this.errorMessage = "Доступ запрещен"
          this.showError = true;
        }
      });
      e.preventDefault();
    },
  },
  mounted() {
    this.getAuthorDoc();
  },
};
</script>

<style></style>
