<template>
  <tr class="tab-data">
    <td style="width: 80%; padding: 0 0 3px 0; border: none !important;">
      <select
        class="form-select form-control-sm"
        v-model="selectedUsageId"
        :disabled="!enableEditing"
      >
        <option
          v-for="usage in usages"
          v-bind:key="usage.id"
          v-bind:value="usage.id"
        >
          {{ usage.description }}
        </option>
      </select>
    </td>
    <td style="width: 113px; border: none !important">
      <button
        class="btn btn-dark btn-sm btn-block btn-edit"
        v-on:click="updateRecord"
      >
        <span v-if="!enableEditing"><BootstrapIcon icon="pencil" /></span>
        <span v-if="enableEditing"><BootstrapIcon icon="check2" /></span>
      </button>
      <button
        class="btn btn-dark btn-sm btn-block btn-delete"
        v-on:click="removeRecord"
      >
        <BootstrapIcon icon="trash" />
      </button>
      <OkModal
        v-if="showError"
        v-bind:message="errorMessage"
        v-bind:header="header"
        v-on:confirm="hideErrorMessage"
      />
    </td>
  </tr>
</template>

<script>
import axios from "axios";

import BootstrapIcon from "@dvuckovic/vue3-bootstrap-icons";
import OkModal from "../../components/OkModal.vue";

export default {
  name: "UsageControlRecordRow",
  components: {
    BootstrapIcon,
    OkModal
  },
  props: ["deposit_id", "mine_area_id", "component_id", "usage_id"],
  data() {
    return {
      usages: [],
      selectedUsageId: null,
      enableEditing: false,
      errorMessage: "",
      header: "",
      showError: false,
    };
  },
  methods: {
    updateRecord(e) {
      if (this.enableEditing) {
        this.enableEditing = false;
        const token = sessionStorage.getItem("token");
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        };
        const data = {
          id: this.usage_id,
          usage_id: this.selectedUsageId,
        };
        const url = this.$BASE_URL + "/api/update_usage/";
        axios.post(url, data, { headers }).then((response) => {
          if (!response.data[0].auth_fail) {
            this.$emit("usage-updated");
          } else {
            this.header = "Ошибка безопасности"
            this.errorMessage = "Доступ запрещен"
            this.showError = true;
          }
        });
      } else {
        this.enableEditing = true;
      }
      e.preventDefault();
    },
    getUsage() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const data = {
        usage_id: this.usage_id,
      };
      const url = this.$BASE_URL + "/api/get_usage/";
      axios.post(url, data, { headers }).then((response) => {
        if (!response.data[0].auth_fail) {
          this.selectedUsageId = response.data[0].result.usage_id;
        }
      });
    },
    removeRecord(e) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const data = {
        usage_id: this.usage_id,
      };
      const url = this.$BASE_URL + "/api/delete_usage/";
      axios.post(url, data, { headers }).then((response) => {
        if (!response.data[0].auth_fail) {
          this.$emit("usage-removed");
        } else {
          this.header = "Ошибка безопасности"
          this.errorMessage = "Доступ запрещен"
          this.showError = true;
        }
      });
      e.preventDefault();
    },
    getUsages() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_dictionary_contents/";
      axios
        .post(
          url,
          { category: "SPR_PRIM", limit: 10000, offset: 0 },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.usages = response.data[0].result;
          }
        });
    },
  },
  mounted() {
    this.getUsages();
    this.getUsage();
  },
};
</script>

<style></style>
